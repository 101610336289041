<template>
  <div class="text-center mt-4 mb-6">
    <v-btn depressed outlined small color="secondary" @click="previousPage" v-if="pagePrevLink">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-btn depressed outlined small color="secondary" @click="nextPage" v-if="pageNextLink" class="ml-2">
      <v-icon>mdi-arrow-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'DrupalJsonApiPager',
  props: {
    pagePrevLink: {
      type: String,
      default: "",
    },
    pageNextLink: {
      type: String,
      default: "",
    },
  },
  // mounted () {
  //   this.updateList()
  // },
  // watch: {
  //   '$route' () {
  //     this.updateList()
  //   }
  // },
  methods: {
    previousPage() {
      this.$emit('page-change', this.pagePrevLink)
    },
    nextPage() {
      this.$emit('page-change', this.pageNextLink)
    },
  }
}
</script>

<style scoped>

</style>