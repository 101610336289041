<template>
  <div>
    <v-btn
        type="submit"
        depressed
        color="primary"
        class="ma-md-8 mt-md-2 ma-4"
        :to="{name: 'folder_create'}"
    >
      <v-icon left>mdi-plus</v-icon>
      Map aanmaken
    </v-btn>

    <v-data-table
        :headers="headers"
        :items="items"
        mobile-breakpoint="0"
        class="mx-md-8 rounded-lg"
        :sort-by="['form_name']"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
    >
      <template #item.folder_name="{item}">

          <template v-if="item.folder_id === editedIndex">
            <v-text-field v-model="editedItem.folder_name" :hide-details="true" dense single-line :autofocus="true" @blur="saveItem"></v-text-field>
          </template>
          <template v-else>
            {{ item.folder_name }}
            <v-icon
                small
                depressed
                role="button"
                @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>

      </template>
      <template #item.folder_hidden="{item}">
        <v-icon
            depressed
            :color="(item.folder_hidden) ? 'error' : 'grey lighten-1'"
            role="button"
            @click="hideFolder(item)"
        >
          <template v-if="item.folder_hidden">
            mdi-eye-off
          </template>
          <template v-else>
            mdi-eye
          </template>
        </v-icon>
      </template>
      <template #item.actions="{item}">
          <v-icon
              depressed
              :color="(item.folder_contains_forms) ? 'warning' : 'error'"
              role="button"
              @click="deleteFolderConfirm(item)"
              >mdi-trash-can</v-icon>

      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import axios from "axios";
import userHelpers from "@/mixins/userHelpers";
import {cloneDeep} from "lodash";

export default {
  mixins: [
      userHelpers
  ],
  data () {
    return {
      options: {
        itemsPerPage: -1
      },
      items: [],
      editedItem: {},
      editedIndex: -1,
    }
  },
  mounted () {
    this.getDataFromApi()
  },
  computed: {
    folderContainsForms(folder) {
      console.log('folder');
      console.log(folder);

      return (folder.folder_contains_forms === 'true');
    },
    headers() {
      let headers = [
        {
          text: 'Map naam',
          align: 'start',
          sortable: true,
          value: 'folder_name',
        },
      ];

      if (this.userHasRole('admin') || this.userHasRole('client_manager')) {
        // Add change visibility button
        headers.push({ value: "folder_hidden", sortable: false, align: 'end' })
      }

      // Add actions
      headers.push({ value: "actions", sortable: false, align: 'end' })

      return headers
    }
  },
  methods: {
    ...mapActions(["getFolders"]),
    async getDataFromApi() {
      this.loading = true;

      try {
        await this.getFolders();
        // Copy to 'items' because we don't want to (inline) edit the rows from the store.
        this.items = cloneDeep(this.$store.state.folders)
      } catch (error) {
        console.log(error);
      }
      finally {
        this.loading = false;
      }
    },
    async deleteFolderConfirm(folder) {
      if (folder.folder_contains_forms) {
        alert('De map "'+folder.folder_name+'" kan niet worden verwijderd omdat deze nog formulieren bevat.');
      }
      else {
        if (
            confirm('Weet je zeker dat je de map "'+folder.folder_name+'" wilt verwijderen?')
        ) {
          await this.deleteFolder(folder);
        }
      }
    },
    async deleteFolder(folder) {
      this.loading = true
      // Fetch results form the API
      try {
        let response = await axios.delete('api/folder/'+folder.folder_id);
        
        if (!response.data && (!response.status || response.status >= 300)) {
          throw new Error(response.toString());
        }

        this.$toast.success('Map "'+folder.folder_name+'" is verwijderd.');

        await this.getDataFromApi();
      } catch (error) {
        console.log(error);
        this.$toast.error("Map kon niet worden verwijderd.");

      } finally {
        this.loading = false
      }
    },
    async hideFolder(folder) {
      this.loading = true
      // Fetch results form the API
      try {
        // Change hidden flag
        let newHiddenValue = true
        if (folder.folder_hidden) {
          newHiddenValue = false
        }
        // Update folder, change the hidden value
        let response = await axios.patch('api/folder/'+folder.folder_id+'/set-hidden', {hidden: newHiddenValue});

        if (!response.data && (!response.status || response.status >= 300)) {
          throw new Error(response.toString());
        }

        if (newHiddenValue) {
          this.$toast.success('Map "'+folder.folder_name+'" is verborgen.');
        }
        else {
          this.$toast.success('Map "'+folder.folder_name+'" is zichtbaar.');
        }

        await this.getDataFromApi();
      } catch (error) {
        console.log(error);
        this.$toast.error("Map kon niet worden verborgen.");

      } finally {
        this.loading = false
      }
    },
    editItem(item) {
      this.editedIndex = item.folder_id
      this.editedItem = Object.assign({}, item);
    },
    stopEditItem() {
      this.editedIndex = -1
      this.editedItem = {}
    },
    async saveItem() {
      // Get the edited item
      let folder = this.editedItem
      try {
        await this.renameFolder(folder)
        // Find index in items
        let index = this.items.findIndex(e=> e.folder_id === folder.folder_id);
        // Apply the edit to the current row item
        this.$set(this.items, index, folder);
        // Stop editing
        this.stopEditItem()
      } catch(error) {
        console.log(error);
      }
    },
    async renameFolder(folder) {
      try {
        // Update folder
        let response = await axios.patch('api/folder/'+folder.folder_id+'/rename', {folder_name: folder.folder_name});

        if (!response.data && (!response.status || response.status >= 300)) {
          throw new Error(response.toString());
        }

      } catch (error) {
        console.log(error);
        this.$toast.error("Map kon niet worden aangepast.");
      }
    }
  },
}
</script>

<style scoped>

</style>