<template>
  <div>

    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
              Tokens
        </v-expansion-panel-header>
        <v-expansion-panel-content>

            <div
                v-for="token in tokensData"
                :key="token.token"
                class="d-block py-1 text-caption"
            >

              <div class="d-inline-block">
                <eform-token :token="token"></eform-token>
              </div>

              <v-btn
                  small
                  depressed
                  rounded
                  outlined
                  color="primary"
                  v-if="token.children"
                  @click="toggleChildren(token)"
                  class="ml-3"
                  :disabled="token.isLoading"
              >
                <template v-if="token.isShown">Verberg tokens</template>
                <template v-else>Toon tokens</template>
              </v-btn>


              <!-- Render children tokens -->
              <div v-if="token.children && token.isShown">

                <v-progress-linear
                    indeterminate
                    v-if="token.isLoading"
                    class="mt-3"
                ></v-progress-linear>

                <p class="text-caption text--secondary mt-2">{{ token.description }}</p>
                <v-list-item
                    v-for="child_token in token.children"
                    :key="child_token.token"
                    dense
                >
                  <eform-token :token="child_token"></eform-token>

                  <v-list class="ml-3">
                    <p
                        v-for="child_child_token in child_token.children"
                        :key="child_child_token.token"
                        class="mb-0"
                    >
                      <eform-token :token="child_child_token"></eform-token>
                    </p>
                  </v-list>

                </v-list-item>
              </div>


              <!-- Render subform tokens -->
              <div v-if="token.schema">
                <eform-tokens
                    :subform_schema="token.schema"
                    :subform_parent="token"
                    :subform="true"
                    :show_subform_index="true"
                >
                </eform-tokens>
              </div>


              <v-divider class="mt-2"></v-divider>

            </div>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <p v-if="!subform_parent" class="text-caption text--secondary mt-1 mb-6">Tokens worden automatisch vervangen door de ingevulde waarde van een registratie.</p>

  </div>
</template>
<script>
import EformToken from "@/components/EformToken";
import axios from "axios";
import eformHelpers from "@/mixins/eformHelpers";
import {isEmpty} from "lodash";

export default {
  name: 'EformTokens',
  components: {
    EformToken,
  },
  mixins: [
    eformHelpers
  ],
  props: {
    tokens: Array, // Either 'subform_schema' or 'tokens' prop must be set, but not both. Use 'tokens' prop when rendering tokens in an eform form.
    subform_schema: Array, // Either 'subform_schema' or 'tokens' prop must be set, but not both. Use 'subform_schema' prop when rendering tokens in a subform.
    subform_parent: { // Is required when rendering subforms tokens.
      type: Object,
      default: function () {
        return {}
      }
    },
    show_subform_index: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modal: false,
      tokensData: [],
    }
  },
  created() {
    // Make sure either schema or tokens is set, but not both.
    if (this.subform_schema && this.tokens) {
      console.error('Either schema or tokens must be set. But not both.');
    }
  },
  mounted () {
    // Build tokens data
    this.buildTokensData()
  },
  watch: {
    'tokens': {
      deep: true,
      handler() {
        // Rebuild tokens data when prop changes
        this.buildTokensData()
      }
    },
  },
  methods: {
    showModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
    toggleChildren(token) {
      if (token.isShown) {
        token.isShown = false
      }
      else {
        token.isShown = true

        if (
            token.elementType === 'map' ||
            token.elementType === 'search'
        ) {
          // Load tokens from the server
          this.getDatasourceTokensFromApi(token)
        }

      }
    },
    getDatasourceTokensFromApi(token) {
      token.isLoading = true

      // Fetch results form the API
      axios
          .get('api/datasource/'+token.datasource_id+'/tokens')
          .then(response => {
            // Loop over results and format in into a options array
            let tokens = []
            // Format datasource list into select options
            for (let sub_token of response.data) {
              tokens.push({
                token: token.token+'.'+sub_token,
                label: sub_token,
                elementType: 'text',
              })
            }
            // Add tokens to token.children
            token.children = tokens
            token.isLoading = false
          })
          .catch(error => {
            console.log(error)
          })
    },
    buildTokensData() {
      // Create a computed tokens because this.schema is loaded async and can be set after creating this component.
      if (this.tokens) {
        this.tokensData = this.tokens
      }
      else {
        // Add all eform schema tokens
        this.tokensData = this.getTokensFromSchemaFilterExclude(this.subform_schema);
      }
      // Prefix tokens with 'subform_parent' if it's set
      if (!isEmpty(this.subform_parent)) {
        for (let token of this.tokensData) {
          if (this.subform_parent.subformTokenType === 'tablerow') {
            // Add tabelrow subform prefix
            token.token = this.subform_parent.elementName+'.tabelrij.'+token.token;
          }
          else {
            // Add normal subform prefix
            token.token = this.subform_parent.elementName+'.'+token.token
          }

          // Add subform prefix to all images
          if (token.elementType === 'image') {
            // Add image subform prefix to image children
            token.children = token.children.map(child => {
              if (this.subform_parent.subformTokenType === 'tablerow') {
                child.token = this.subform_parent.elementName + '.tabelrij.' + child.token
              }
              else {
                child.token = this.subform_parent.elementName + '.' + child.token
              }
              return child
            })
          }

        }
      }
      if (!this.show_subform_index) {
        // Remove the subform_index token, this is only used for subforms
        this.tokensData = this.tokensData.filter(token => token.token !== 'subform_index')
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
