<template>
  <div>

    <FormulateForm :debounce="100">

      <FormulateInput
          type="range"
          label="Minimum nauwkeurigheid voor locatie"
          help="Nauwkeurigheid wordt gemeten in meters. Bij een nauwkeurigheid van 25 kan de echte positie tot 25 meter in elke richting afwijken."
          name="minAccuracy"
          min="0"
          max="200"
          value="25"
          show-value="true"
          v-model="element.mapSettings.minAccuracy"
          validation="required"
      />

      <FormulateInput
          type="group"
          label="Kaart weergave"
          help="Geef aan op welke locatie de kaart geopend moet worden.">
        <v-row>
          <v-col>
            <FormulateInput
                type="text"
                name="lat"
                label="Latitude"
                placeholder="52.155227"
                :validation="[['bail'], ['required'], ['matches', /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,18})?))$/]]"
                error-behavior="live"
                v-model="element.mapSettings.center.lat"
            />
          </v-col>
          <v-col>
            <FormulateInput
                type="text"
                name="lon"
                label="Longitude"
                placeholder="5.387289"
                :validation="[['bail'], ['required'], ['matches', /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,18})?))$/]]"
                error-behavior="live"
                v-model="element.mapSettings.center.lon"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <FormulateInput
                :options="{1: '1 - Wereld', 2: '2', 3: '3', 4: '4 - Continent', 5: '5', 6: '6', 7: '7 - Land', 8: '8', 9: '9 - Provincie', 10: '10', 11: '11 - Gemeente', 12: '12', 13: '13 - Stad', 14: '14 - Wijk/Dorp', 15: '15', 16: '16 - Straat', 17: '17', 18: '18', 19: '19 - Huis'}"
                type="select"
                label="Zoomlevel"
                validation="required"
                v-model="element.mapSettings.zoomlevel"
            />
          </v-col>
        </v-row>
      </FormulateInput>

    </FormulateForm>
  </div>
</template>

<script>
import axios from "axios";
import eformHelpers from "@/mixins/eformHelpers";

export default {
  name: 'EformElementEditGps',
  mixins: [
    eformHelpers
  ],
  props: {
    form: Object,
    element: Object,
  },
  data: () => ({
    fag: null,
    cg: null,
    datasources: [],
  }),
  computed: {
    datanames() {
      // Get all datanames in the form
      return this.getDatanamesAsSelectList(this.form, ['checkbox_multiple', 'radio', 'select'])
    }
  },
  mounted () {
    // Get items
    this.getDatasourcesFromApi()
  },
  methods: {
    getStatusOptions() {
      let statusOptions = []
      for(let status of this.element.mapSettings.featureStyles) {
        statusOptions.push({
          value: status.status,
          label: status.status
        })
      }
      return statusOptions
    },
    getElementValues(dataName) {
      // Find element in form schem and return the options
      for (let i in this.form.schema) {
        if (this.form.schema[i].name === dataName && this.form.schema[i].options) {
          return this.form.schema[i].options;
        }
      }
      return [];
    },
    getDatasourcesFromApi () {
      let client_id = this.$store.getters.CurrentClient;
      // Fetch results form the API
      axios
          .get('api/datasource/list/map_features/'+client_id)
          .then(response => {
            // Loop over results and format in into a options array
            let options = []
            // Format datasource list into select options
            for (let item of response.data) {
              options.push({
                value: item.id,
                label: item.name
              })
            }
            // Set datasources
            this.datasources = options
          })
          .catch(error => {
            console.log(error)
          })
    },
  }
}
</script>

<style lang="scss">
// Make the Vue Formulate repeatable group wider.
.formulate-input .formulate-input-element--group {
  max-width: 100% !important;
}
</style>
