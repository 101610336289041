<template>
  <div>
    <FormulateInput
        type="number"
        v-model="min" label="Min"
        v-on:blur-context="updateMinValidation" />
    <FormulateInput
        type="number"
        v-model="max" label="Max"
        v-on:blur-context="updateMaxValidation"/>
  </div>
</template>

<script>
export default {
  name: 'EformElementEditTextarea',
  props: {
    element: Object,
  },
  data: () => ({
    min: '',
    max: '',
  }),
  methods: {
    updateMinValidation() {
      if (this.min === '') {
        // Remove validation rule
        this.$emit('removeValidationRule', 'min');
      }
      else {
        // Add or update validation rule
        this.$emit('addOrUpdateValidationRule', {ruleName: 'min', value: this.min});
      }
    },
    updateMaxValidation() {
      if (this.max === '') {
        // Remove validation rule
        this.$emit('removeValidationRule', 'max');
      }
      else {
        // Add or update validation rule
        this.$emit('addOrUpdateValidationRule', {ruleName: 'max', value: this.max});
      }
    },
    setDefaultValues() {
      let ruleMin = this.getValidationRule('min');
      let ruleMax = this.getValidationRule('max');
      if (ruleMin !== false) {
        this.min = ruleMin[1];
      }
      if (ruleMax !== false) {
        this.max = ruleMax[1];
      }
    },
    getValidationRule(ruleName) {
      // Returns the validation index when it exists. Returns FALSE when the ruleName does not exist.
      let length = this.element.validation.length;
      for (let i = 0; i < length; i++) {
        if (this.element.validation[i][0] === ruleName) {
          return this.element.validation[i];
        }
      }
      return false;
    },
  },
  mounted() {
    this.setDefaultValues();
  },
}
</script>

<style lang="scss" scoped>

</style>
