<template>
  <div :key="$route.params.formId">

    <template v-if="loading">
      <v-skeleton-loader type="heading"></v-skeleton-loader>
      <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
    </template>
    <template v-if="!loading">

      <!-- Draggable Formulate -->
      <FormulateForm v-model="values" class="formulate-style-compact form-max-width">
        <draggable v-model="schema" @start="drag=true" @end="drag=false" handle=".handle" :scroll-sensitivity="200" :force-fallback="true">

          <template v-for="(item, index) in schema">
            <div :key="item.uuid">
              <eform-element-edit
                  :disabled-prop="setDisabled(item)"
                  :values="values"
                  :schema="schema"
                  :item="item"
                  :form="myForm"
                  @formRemoveElement="formRemoveElement($event)"
              />
              <eform-element-add
                  :shown="index === (schema.length-1) ? true : false"
                  :form_type="myForm.form_type"
                  :form-schema="schema"
                  :index="index"
                  @formAddElement="formAddElement($event, index)"/>
            </div>
          </template>

        </draggable>

        <v-spacer></v-spacer>

        <eform-element-add
            v-if="schema.length === 0"
            :form_type="myForm.form_type"
            :shown="true"
            :form-schema="schema"
            @formAddElement="formAddElement($event)"/>

        <v-spacer></v-spacer>

        <v-footer padless fixed inset app class="eform-footer-transparant py-2">
          <v-container class="form-max-width">

            <v-btn
                depressed
                color="primary"
                class="white--text"
                @click="submitForm"
                :disabled="isSaving"
            >
              {{ isSaving ? 'Laden...' : 'Formulier opslaan' }}
            </v-btn>

          </v-container>
        </v-footer>

      </FormulateForm>

    </template>

  </div>

</template>

<script>
import EformElementEdit from '../../components/EformElementEdit';
import EformElementAdd from '../../components/EformElementAdd';
import draggable from "vuedraggable";
import axios from "axios";
import eformHelpers from "@/mixins/eformHelpers";
import clientHelpers from "@/mixins/clientHelpers";

export default {
  name: "Form",
  components: {
    EformElementEdit,
    EformElementAdd,
    draggable,
  },
  mixins: [
    eformHelpers,
    clientHelpers
  ],
  async created () {
    // Load client data. If we load it here, it will be already cached for the EformElementAdd.vue component
    await this.loadClient(this.$store.getters.CurrentClient)
    // Get form from API
    this.getDataFromApi()
  },
  data() {
    return {
      myForm: null,
      loading: true,
      form_id: this.$route.params.form_id,
      isSaving: false,
      unsavedChanges: false,
    }
  },
  watch: {
    schema: {
      deep: true,
      handler() {
        // Mark form as unsaved
        this.unsavedChanges = true
      }
    },
  },
  computed: {
    values: {
      get() {
        return this.myForm.values
      },
      set(values) {
        this.myForm.values = values;
      }
    },
    schema: {
      get() {
        if (this.myForm && this.myForm.schema) {
          return this.myForm.schema;
        }
        return [];
      },
      set(values) {
        this.myForm.schema = values;
      }
    },
    rules: {
      get() {
        if (this.myForm && this.myForm.rules) {
          return this.myForm.rules;
        }
        return [];
      },
      set(values) {
        this.myForm.rules = values;
      }
    },
  },
  methods: {
    getDataFromApi () {
      this.loading = true
      // Fetch results form the API
      axios
          .get('api/form/'+this.form_id)
          .then(response => {
            this.myForm = response.data
            this.loading = false;
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => this.loading = false)
    },
    setDisabled(item) {
      return (item.elementType === 'file' || item.elementType === 'image' || item.elementType === 'tracking');
    },
    formRemoveElement(element) {
      let elementIndex = this.myForm.schema.findIndex(e=> e.uuid === element.uuid);
      this.myForm.schema.splice(elementIndex, 1);
    },
    formAddElement(element, index) {
      this.myForm.schema.splice(++index, 0, element);
      // this.myForm.schema.push(element);
    },
    // Send the form to the API endpoint
    async submitForm() {
      try {
        this.isSaving = true;

        // Post form to API
        axios.patch('api/form/'+this.form_id, this.myForm)
            .then(response => {
              this.myForm = response.data
              // Set flag
              this.isSaving = false
              // Show toast message
              this.$toast.success("Formulier '" + this.myForm.form_name + "' is aangepast.");
              // Allow user to leave page without a confirm box
              this.unsavedChanges = false
              // Go to router page
              this.$router.push({name: 'form_view', params: {form_id: this.form_id}});
            })
            .finally(() => this.isSaving = false)

      } catch (error) {
        this.$toast.error("Het formulier kon niet worden aangepast.");
        this.isSaving = false
        console.log(error);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      const answer = window.confirm('Wilt u deze pagina verlaten? Indien u wijzigingen heeft aangebracht gaan deze verloren.')
      if (answer) {
        next()
        return
      } else {
        next(false)
        return
      }
    }
    next()
  }
}
</script>

<style scoped>
  .form-max-width {
    max-width: 900px;
    margin: 0 auto;
  }
  .sortable-chosen .form-add-element {
    display:none !important;
  }
</style>