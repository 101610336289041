<template>
  <div>

    <v-row v-for="element in schema" :key="element.uuid">
      <v-col>
        <!-- If element.component is set, then create a component of that type. Most likely this will be h1, h2, h3 -->
        <template v-if="element.component">
          <component :is="element.component">
            {{ element.label }}
          </component>
        </template>
        <!-- Subform components -->
        <template v-else-if="element.subform">
          <h4>{{ element.label }}</h4>

          <v-expansion-panels>
            <v-expansion-panel
                v-for="(subformRegistration, index) in registrationValues[element.name]" :key="element.uuid+'_'+index"
            >
              <v-expansion-panel-header>
                <span v-if="element.summaryText" v-html="getSummary(subformRegistration, element)"></span>
                <template v-else-if="element.registrationLabel">
                  {{ element.registrationLabel }}
                </template>
                <template v-else>
                  Registratie
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <v-row v-if="subformRegistration.subform_index">
                  <v-col>
                    <p class="ma-0 text-caption">Subformulier nummer: {{ subformRegistration.subform_index }}</p>
                  </v-col>
                </v-row>

                <eform-registration-formatter
                    :schema="element.subform.schema"
                    :registration-values="subformRegistration">
                </eform-registration-formatter>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </template>
        <!-- Show Vue Formulate component -->
        <template v-else-if="element.elementType !== 'linkedForm'">
          <h4>{{ element.label }}</h4>
          <div class="value" v-html="formattedValue(element, registrationValues[element.name])">
          </div>
        </template>

      </v-col>
    </v-row>

  </div>
</template>
<script>
import eformHelpers from "@/mixins/eformHelpers";
import eformElementFormatter from "@/mixins/eformElementFormatter";

export default {
  name: 'EformRegistrationFormatter',
  props: {
    schema: [Object, Array],
    registrationValues: Object,
    showImagePreviews: {
      // We need this because image preview cannot be loaded when the user fills in a form, because temp files are restricted.
      type: Boolean,
      default: true
    },
  },
  mixins: [
    eformHelpers,
    eformElementFormatter,
  ],
  methods: {
    getSummary(subformRegistration, element) {
      let tokens = this.getTokensFromSchema(element.subform.schema)
      return this.getRegistrationSummary(element, tokens, subformRegistration)
    },
  }
}
</script>
<style lang="scss">
.eform-results-formatted-table {
  margin-bottom: 20px;

  &:last-child {
   margin-bottom: 0;
  }

  td {
    border: 1px solid #eee;
    padding: 5px;
  }
}
</style>
