import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import nl from 'vuetify/lib/locale/nl'

import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {nl},
        current: 'nl',
    },
    theme: {
        dark: false,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                background: '#f2f2f6',
                primary: '#f28e00',
                success: '#41b883',
                error: '#ef1200',
                warning: '#f28e00',
                secondary: colors.blueGrey.base,
            },
            // dark: {
            //     background: colors.blueGrey.darken4,
            // }
        },
    },
});
