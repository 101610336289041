<template>
  <div>

    <!-- If element.component is set, then create a component of that type. Most likely this will be h1, h2, h3 -->
    <template v-if="element.elementType === 'header'">
      <component :is="element.component" data-type="header">
        {{ element.label }}
      </component>
    </template>
    <template v-else-if="element.elementType === 'staticText'">
      <div v-html="sanitizeHtmlCkeditorSimple(element.staticText)"></div>
    </template>
    <template v-else-if="element.elementType === 'imageTag'">
      <img v-if="element.image && element.image.length" :src="element.image[0].url" :width="element.width" :height="element.height" alt="">
    </template>
    <template v-else-if="element.elementType === 'calculation'">
      <FormulateInput
          :values="values"
          :schema="schema"
          :key="element.uuid"
          v-bind="element"
      />
    </template>
    <template v-else-if="element.elementType === 'image'">
      <FormulateInput
          :upload-url="formSettings && formSettings.bad_internet_mode ? 'filetobase64' : false"
          :capture="element.useOnlyCamera === true ? 'capture' : false"
          :disabled="disabledProp"
          :is-admin-mode="isAdminMode"
          :key="element.uuid"
          v-bind="element"
      />
    </template>
    <template v-else-if="element.elementType === 'file'">
      <FormulateInput
          :upload-url="formSettings && formSettings.bad_internet_mode ? 'filetobase64' : false"
          :disabled="disabledProp"
          :is-admin-mode="isAdminMode"
          :key="element.uuid"
          v-bind="element"
      />
    </template>
    <template v-else-if="element.elementType === 'subform'">
      <FormulateInput
          :disabled="disabledProp"
          :is-admin-mode="isAdminMode"
          :form-settings="formSettings"
          :key="element.uuid"
          v-bind="element"
          v-on="$listeners"
      />
      <!-- The code v-on="$listeners" is sending all events like '@subform-closed' and '@subform-opened' also to the parent of this component. -->
    </template>
    <!-- Show Vue Formulate component -->
    <template v-else>
      <FormulateInput
          :disabled="disabledProp"
          :is-admin-mode="isAdminMode"
          :form-settings="formSettings"
          :debounce="50"
          :key="element.uuid"
          v-bind="element"
      />
    </template>
  </div>
</template>


<script>
import EformSanitzeHtmlHelpers from "@/mixins/eformSanitzeHtmlHelpers";

export default {
  name: 'EformElement',
  mixins: [
    EformSanitzeHtmlHelpers,
  ],
  props: {
    element: Object,
    values: Object,
    schema: Array,
    disabledProp: {
      type: Boolean,
      default: false,
    },
    isAdminMode: {
      type: Boolean,
      default: false,
    },
    formSettings: Object,
  },
}
</script>

<style lang="scss" scoped>

</style>
