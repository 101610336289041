<template>
  <div>

    <v-data-table
        :headers="headers"
        :loading="loading"
        :items="draftRegistrations"
        class="mx-sm-8 rounded-lg"
        mobile-breakpoint="0"
        sort-by="registration_date"
        :sort-desc="[true]"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
        v-if="draftRegistrations.length"
        @click:row="rowClick"
    >
      <template #item.form_name="{ item }">
        {{ item.form_name }}
      </template>
      <template #item.form_summary="{ item }">
        <div
            class="form-summary"
            v-if="item.form_summary"
            v-html="replaceTokens(item.form_summary, getTokensFromSchema(item.schema), item.values, true, item.schema)"
        ></div>
      </template>
      <template #item.registration_date="{ item }">
        {{ item.registration_date|formatDate }}
      </template>
    </v-data-table>

    <v-container fluid class="pa-md-8 pt-md-2" v-else>
      <v-row>
        <v-col>
          U heeft momenteel geen toegewezen registraties.
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import eformHelpers from "@/mixins/eformHelpers";
import axios from "axios";

export default {
  name: "DraftRegistrationList",
  mixins: [
    eformHelpers
  ],
  data () {
    return {
      draftRegistrations: [],
      loading: false,
      options: {
        itemsPerPage: -1
      },
      headers: [
        {
          text: 'Formulier naam',
          sortable: true,
          align: 'start',
          value: 'form_name',
        },
        {
          text: 'Fase',
          sortable: true,
          align: 'start',
          value: 'registration_phase',
        },
        {
          text: 'Samenvatting',
          sortable: true,
          align: 'start',
          value: 'form_summary',
        },
        {
          text: 'Laatste wijziging',
          sortable: true,
          align: 'start',
          value: 'registration_date',
        },
      ],
    }
  },
  mounted () {
    this.loadDraftRegistrations()
  },
  methods: {
    rowClick(item) {
      this.$router.push({ name: 'form_fill_workflow', params: {form_id: item.form_id, force_reload: '0', registration_id: item._id} })
    },
    loadDraftRegistrations() {
      // Fetch results form the API
      axios
          .get('api/assigned-registrations')
          .then(response => {
            this.draftRegistrations = response.data
            this.total = response.data.length
          })
          .catch(error => {
            console.log(error)
          })
    },
  },
}
</script>

<style scoped>

</style>