<template>
  <div>

    <v-sheet class="pa-4 rounded-lg mb-4">
      <h3>Toegang beheren</h3>
      <v-btn
          depressed
          color="primary"
          @click="showAddUserModal"
          class="mt-3 mr-3"
          width="150">
        <v-icon>mdi-account</v-icon>
        Gebruikers
      </v-btn>
      <v-btn
          depressed
          color="primary"
          @click="showAddUserGroupModal"
          class="mt-3"
          width="150">
        <v-icon>mdi-account-multiple</v-icon>
        Groepen
      </v-btn>
    </v-sheet>

    <v-dialog @click:outside="closeAddUserModal" v-model="addUserModal" max-width="800" scrollable>
      <v-card>

        <v-card-text>

          <v-tabs grow>
            <v-tab>
              Gebruikers beheren
            </v-tab>
            <v-tab>
              Gebruikers toevoegen
            </v-tab>
            <!-- Manage users tab item -->
            <v-tab-item>
              <v-card-text>
                <v-data-table
                    :headers="addUserTableHeaders"
                    :items="formUsers"
                >
                  <template #item.actions="{ item }">
                    <v-btn
                        depressed
                        :loading="removeLoading"
                        :disabled="removeLoading"
                        color="error"
                        @click="removeUserFromForm(item.user_id)"
                        icon>
                      <v-icon>mdi-account-remove</v-icon>
                    </v-btn>
                  </template>
                  <template slot="no-data">
                    <div>
                      Er zijn nog geen gebruikers toegevoegd
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>

            <!-- Add users tab item -->
            <v-tab-item>
              <template>
                <v-card>
                  <template>
                    <v-card>
                      <v-card-title>
                        <v-text-field
                            v-model="addUserTableSearch"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                      </v-card-title>
                      <v-data-table
                          :headers="addUserTableHeaders"
                          :items="addUserTableItems"
                          :search="addUserTableSearch"
                      >
                        <template #item.actions="{ item }">
                          <v-btn
                              depressed
                              :loading="addUserLoading"
                              :disabled="addUserLoading"

                              color="primary"
                              @click="addUserToForm(item.user_id)"
                              icon>
                            <v-icon>mdi-account-plus</v-icon>
                          </v-btn>
                        </template>
                        <template slot="no-data">
                          <div>
                            Er zijn geen gebruikers beschikbaar die kunnen worden toegevoegd
                          </div>
                        </template>
                      </v-data-table>
                    </v-card>
                  </template>
                </v-card>
              </template>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-actions class="background-color">
          <v-btn
              color="secondary"
              outlined
              @click="closeAddUserModal"
          >
            Sluit
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog @click:outside="closeAddUserGroupModal" v-model="addUserGroupModal" max-width="800" scrollable>
      <v-card>

        <v-card-text>

          <v-tabs grow>
            <v-tab>
              Groepen beheren
            </v-tab>
            <v-tab>
              Groepen toevoegen
            </v-tab>

            <!-- Manage user group tab item -->
            <v-tab-item>
              <v-card-text>
                <v-data-table
                    :headers="addUserGroupTableHeaders"
                    :items="formUserGroups"
                >
                  <template #item.actions="{ item }">
                    <v-btn
                        depressed
                        :loading="removeLoading"
                        :disabled="removeLoading"
                        color="error"
                        @click="removeUserGroupFromForm(item.group_id)"
                        icon>
                      <v-icon>mdi-account-multiple-remove</v-icon>
                    </v-btn>
                  </template>
                  <template slot="no-data">
                    <div>
                      Er zijn nog geen groepen toegevoegd
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>

            <!-- Add user group tab item -->
            <v-tab-item>
              <template>
                <v-card>
                  <template>
                    <v-card>
                      <v-card-title>
                        <v-text-field
                            v-model="addUserGroupTableSearch"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                      </v-card-title>
                      <v-data-table
                          :headers="addUserGroupTableHeaders"
                          :items="addUserGroupTableItems"
                          :search="addUserGroupTableSearch"
                      >
                        <template #item.actions="{ item }">
                          <v-btn
                              depressed
                              :loading="addUserGroupLoading"
                              :disabled="addUserGroupLoading"
                              color="primary"
                              @click="addUserGroupToForm(item.group_id)"
                              icon>
                            <v-icon>mdi-account-multiple-plus</v-icon>
                          </v-btn>

                        </template>
                        <template slot="no-data">
                          <div>
                            Er zijn geen groepen beschikbaar die kunnen worden toegevoegd
                          </div>
                        </template>
                      </v-data-table>
                    </v-card>
                  </template>
                </v-card>
              </template>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-actions class="background-color">
          <v-btn
              color="secondary"
              outlined
              @click="closeAddUserGroupModal"
          >
            Sluit
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import eformConditionals from "@/mixins/eformConditionals";
import userHelpers from "@/mixins/userHelpers";
import {mapGetters} from "vuex";
import eformHelpers from "../../mixins/eformHelpers";

export default {
  name: "FormUsers",
  components: {
  },
  mixins: [
    eformConditionals,
    userHelpers,
    eformHelpers,
  ],
  props: {
    myForm: null,
  },
  data() {
    return {
      removeLoading: false,
      addUserLoading: false,
      addUserGroupLoading: false,
      addUserTableLoading: true,
      addUserGroupTableLoading: true,
      addUserModal: false,
      addUserGroupModal: false,
      addUserTableSearch: '',
      addUserTableOptions: {},
      addUserTableHeaders: [
        {
          text: 'Email',
          align: 'start',
          sortable: true,
          value: 'email',
        },
        { value: "actions", sortable: false }
      ],
      addUserTableItems: [],
      addUserGroupTableSearch: '',
      addUserGroupTableOptions: {},
      addUserGroupTableHeaders: [
        {
          text: 'Groepnaam',
          align: 'start',
          sortable: true,
          value: 'label',
        },
        { value: "actions", sortable: false }
      ],
      addUserGroupTableItems: [],
      formUsers: [],
      formUserGroups: [],
    }
  },
  computed: {
    ...mapGetters({User: "StateUser", CurrentClient: "CurrentClient"}),
  },
  mounted () {
    // Set the form users and user groups
    this.getAssignedUsersAndUserGroups(this.myForm);
  },
  methods: {
    updateForm(form) {
      // Update the form
      this.$emit('updateForm', form)
      // Set the form users and user groups
      this.getAssignedUsersAndUserGroups(form);
    },
    /**
     * Fill in the 'this.formUsers' and 'this.formUserGroups' variables.
     * Extracts the users and user groups from the form object.
     *
     * @param form
     */
    getAssignedUsersAndUserGroups(form) {
      if (form.users) {
        let formUsers = []
        let formUserGroups = []
        // Loop over all users and groups, only add the users and not the groups
        for (let value of form.users) {
          if (value.user_id) {
            formUsers.push(value)
          }
          else if (value.group_id) {
            formUserGroups.push(value)
          }
        }
        this.formUsers = formUsers;
        this.formUserGroups = formUserGroups;
      }
    },
    getAvailableUsers() {
      this.addUserTableLoading = true;

      axios.get('api/form/'+this.myForm.form_id+'/get_users')
          .then(response => {
            this.addUserTableItems = response.data;
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.addUserTableLoading = false)
    },
    removeUserFromForm(user_id) {
      this.removeLoading = true;
      axios.post('api/form/'+this.myForm.form_id+'/remove_user/' + user_id, [user_id])
          .then(response => {
            // Update the eform with the removed user
            this.updateForm(response.data);
            // Update the list of available users
            this.getAvailableUsers();
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.removeLoading = false)
    },
    addUserToForm(user_id) {
      this.addUserLoading = true;
      axios.post('api/form/'+this.myForm.form_id+'/add_user/' + user_id, [user_id])
          .then(response => {
            // Update the eform with the newly added user
            this.updateForm(response.data);
            // Update the list of available users
            this.getAvailableUsers();
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.addUserLoading = false)
    },
    removeUserGroupFromForm(group_id) {
      this.removeLoading = true;
      axios.post('api/form/'+this.myForm.form_id+'/remove_user_group/' + group_id, [group_id])
          .then(response => {
            // Update the eform with the removed user group
            this.updateForm(response.data);
            // Update the list of available user groups
            this.getAvailableUserGroups();
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.removeLoading = false)
    },
    addUserGroupToForm(group_id) {
      this.addUserGroupLoading = true;
      axios.post('api/form/'+this.myForm.form_id+'/add_user_group/' + group_id, [group_id])
          .then(response => {
            // Update the eform with the newly added user group
            this.updateForm(response.data);
            // Update the list of available user groups
            this.getAvailableUserGroups();
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.addUserGroupLoading = false)
    },
    async getAvailableUserGroups() {
      this.addUserGroupTableLoading = true;

      axios.get('api/form/'+this.myForm.form_id+'/get_user_groups')
          .then(response => {
            this.addUserGroupTableItems = response.data;
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.addUserGroupTableLoading = false)

    },
    showAddUserModal() {
      this.addUserModal = true;
      // Get all available users
      this.getAvailableUsers();
    },
    closeAddUserModal() {
      this.addUserModal = false;
    },
    showAddUserGroupModal() {
      this.addUserGroupModal = true;
      // Get all available user groups
      this.getAvailableUserGroups();
    },
    closeAddUserGroupModal() {
      this.addUserGroupModal = false;
    },
  }
}
</script>

<style scoped>

</style>