<template>
  <div>
    <v-btn
        fab
        fixed top left
        rounded
        small
        color="primary"
        style="z-index: 10; top: 4px;"
        @click.stop="toggleDrawer">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-navigation-drawer
        v-model="drawer"
        app
        left
        :temporary="drawerTemporary"
        class="nav_drawer background-color"
        style="height: 100%;"
    > <!-- height: 100% is needed to fix iOS bug where the v-slot:append is partly hidden by the ios address bar when user has not scrolled the page yet -->

      <template v-slot:prepend>
        <v-sheet class="background-color">
          <v-img
              class="mx-2"
              src="../assets/images/e-formapp_logo_2022.svg"
              contain
              height="70"
          ></v-img>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet class="background-color">
          <v-list-item two-line>
            <v-list-item-content v-if="!isLoggedIn">
              <template>
                <router-link class="login" router :to="{ name: 'login'}">Inloggen</router-link>
              </template>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-sheet>
        <v-sheet class="text-center pb-4 background-color">
          <span class="text-caption">v{{ appVersion }}</span>
        </v-sheet>
      </template>

    </v-navigation-drawer>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import userHelpers from "@/mixins/userHelpers";

export default {
  name: 'NavBaNotLoggedIn',
  mixins: [
    userHelpers,
  ],
  data() {
    return {
      drawer: true,
      appVersion: process.env.VUE_APP_VERSION,
    }
  },
  mounted() {

  },
  computed : {
    ...mapGetters({User: "StateUser", CurrentClient: "CurrentClient", CurrentClientName: "CurrentClientName"}),
    isLoggedIn(){
      return this.$store.getters.isAuthenticated
    },
    drawerTemporary() {
      if (!this.isLoggedIn) {
        return true;
      }
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  methods: {
    toggleDrawer() {
      this.drawer = !(this.drawer && this.drawer === true);
    },
  },
}
</script>
<style lang="scss" scoped>
.hamburger_btn {
  z-index: 10;
}
.nav_drawer {
  z-index: 11;
}
.logout {
  text-decoration: none;
}
</style>
