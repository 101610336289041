<template>
  <div>

    <v-row class="mb-2">
      <v-col>
        <FormulateInput
            v-model="element.number_formatter"
            :options="{none: 'Geen', price: 'Prijs'}"
            type="select"
            label="Nummer opmaak"
            help="Bij de formatter 'Geen' wordt het nummer niet opgemaakt. Bij de formatter 'Prijs' wordt het nummer opgemaakt als een prijs."
        />
      </v-col>
    </v-row>

  </div>
</template>

<script>
import eformHelpers from "@/mixins/eformHelpers";

export default {
  mounted() {
    if (this.element.number_formatter === undefined) {
      // Set default value to 'number' if empty
      this.$set(this.element, 'number_formatter', 'none');
    }
  },
  mixins: [
    eformHelpers,
  ],
  props: {
    element: Object,
  },
}
</script>

<style lang="scss">

</style>
