<template>
  <v-dialog v-model="termsModal" max-width="950" scrollable persistent>
    <v-card>
      <v-card-title>
        Algemene voorwaarden accepteren voor {{ clientName }}
      </v-card-title>
      <v-card-text class="background-color py-6 pa-4">
        U kunt hier onze <a :href="pdfDownloadLink" target="_blank">algemene voorwaarden</a> (PDF) downloaden.
      </v-card-text>
      <v-card-actions class="actions">
        <v-checkbox hide-details class="ma-0 mb-2" v-model="termsAndConditionsApproved">
          <template v-slot:label>
            <div>
              Ik accepteer de algemene voorwaarden.
            </div>
          </template>
        </v-checkbox>
        <v-btn
            color="primary"
            outlined
            @click="termsAndConditionsAreApproved"
            class="my-3"
            v-if="termsAndConditionsApproved"
        >
          Opslaan
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'TermsAndConditionsWall',
  props: {
    client_uuid: String,
  },
  data () {
    return {
      termsModal: true,
      termsAndConditionsApproved: false,
      api_base_url: process.env.VUE_APP_API_BASEURL,
    }
  },
  mounted () {

  },
  computed: {
    ...mapGetters({User: "StateUser"}),
    clientName() {
      if (!_.isEmpty(this.User.clients)) {
        return this.User.clients.find(client => client.uuid === this.client_uuid).name;
      }
      return null;
    },
    pdfDownloadLink() {
      return this.api_base_url+'modules/custom/eform_clients/modules/eform_client_terms_and_conditions/downloads/algemene-voorwaarden-inclusief-verwerkersovereenkomst-als-addendum-e-formapp.pdf'
    }
  },
  methods: {
    hideModal() {
      this.termsModal = false;
    },
    async termsAndConditionsAreApproved() {
      // Send request to backend to save the terms and conditions
      const payload = {
        client_uuid: this.client_uuid,
      };

      let response = await axios.post('api/client/'+this.client_uuid+'/accept-terms-and-conditions', payload);

      if (response.status === 200 || response.status === 201) {
        this.hideModal()
      }
      else {
        // Show error
        this.$toast.error("Fout tijdens het accepteren van de algemene voorwaarden. Neem a.u.b. contact op met onze helpdesk.");
      }

    }
  }
}
</script>

<style scoped>
.actions {
  flex-direction: column; align-items: start;
}
</style>