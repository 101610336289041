import sanitizeHtml from "sanitize-html";

export default {
  methods: {
    sanitizeHtmlCkeditorSimple(html) {
      return sanitizeHtml(html, {
        allowedTags: ['p', 'strong', 'em', 'a', 'br', 'ul', 'li', 'ol'],
        allowedAttributes: {
          a: ['href', 'target'],
        },
        transformTags: {
          'a': sanitizeHtml.simpleTransform('a', {target: '_blank'}) // Make sure all <a> tags have target="_blank"
        }
      })
    },
  },
};