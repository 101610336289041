import { render, staticRenderFns } from "./PwaInstallPrompt.vue?vue&type=template&id=60729ed4&"
import script from "./PwaInstallPrompt.vue?vue&type=script&lang=js&"
export * from "./PwaInstallPrompt.vue?vue&type=script&lang=js&"
import style0 from "./PwaInstallPrompt.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
installComponents(component, {VBottomSheet,VBtn,VCard,VIcon,VList,VListItem,VListItemContent,VListItemIcon})
