<template>
  <div
      :class="`formulate-input-element formulate-input-element--${context.type}`"
      :data-type="context.type"
  >

    <div class="text-caption text--secondary">{{ selectedFeatures.length }} / {{ maxFeaturesSelect }} maximaal aantal features geselecteerd.</div>

    <open-layers-map
        ref="map"
        :map-settings="context.attributes.mapSettings"
        :selected-features="selectedFeatures"
        @selectFeature="selectFeature($event)"
        @deselectFeature="deselectFeature($event)"
    ></open-layers-map>

    <multiselect
        v-if="mapSearchIsEnabled"
        :placeholder="placeholder"
        open-direction="bottom"
        :multiple="isMultiple"
        :options="searchOptions"
        :disabled="searchIsLoading || maxSelectedValuesReached"
        :option-height="40"
        :options-limit="50"
        :max-height="600"
        track-by="id"
        label="label"
        :tagging="false"
        :show-no-results="true"
        @select="selectSearchOption"
        selected-label="Geselecteerd"
        select-label="[enter] om te selecteren"
        deselect-label="[enter] om te deselecteren"
    >
      <template slot="singleLabel" slot-scope="">
        <span><!-- We don't want to show any selected labels or tags, we created our own selection table --></span>
      </template>
      <template slot="tag" slot-scope="">
        <span><!-- We don't want to show any selected labels or tags, we created our own selection table --></span>
      </template>
      <template slot="option" slot-scope="props">
        <v-icon v-bind:style="{ color: featureColor(props.option) }">mdi-circle</v-icon>
         {{ props.option.label }}
      </template>
      <span slot="noOptions">Lijst met waardes is leeg.</span>
      <span slot="noResult">Geen waardes gevonden. Verander uw zoekopdracht.</span>
    </multiselect>

    <v-list two-line v-if="selectedFeatures.length">
      <template v-for="(feature, index) in selectedFeatures">

        <v-list-item
            :key="feature.id"
            class="px-0"
        >

          <v-list-item-content>
            <v-list-item-title>
              <v-icon v-bind:style="{ color: featureColor(feature) }">mdi-circle</v-icon> <strong>{{ feature.properties.name }}</strong>
              <v-btn class="ml-2" color="error" depressed small v-on:click="deselectFeature(feature.id)">Deselecteren</v-btn>
            </v-list-item-title>

            <v-card flat class="mt-1">

              <table cellpadding="0" cellspacing="0" class="feature-details-table">
                <tr>
                  <td>Status</td>
                  <td>
                    <template v-if="feature.properties.status">
                      {{ feature.properties.status }}
                    </template>
                    <template v-else>
                      (leeg)
                    </template>
                  </td>
                </tr>
                <template v-if="feature.properties.data">
                  <template v-for="(value, label) in feature.properties.data">
                    <tr :key="label" v-if="context.attributes.mapSettings.showDataColumns.includes(label)">
                      <td>{{ label }}</td>
                      <td>{{ value }}</td>
                    </tr>
                  </template>
                </template>
              </table>
            </v-card>

          </v-list-item-content>

        </v-list-item>

        <div class="mb-4" v-if="index !== selectedFeatures.length - 1" :key="feature.id"></div>

      </template>
    </v-list>

  </div>
</template>

<script>
import OpenLayersMap from "@/components/OpenLayersMap";
import GeoJSON from "ol/format/GeoJSON";
import featureStyle from "@/mixins/featureStyle";
import {isObject, cloneDeep} from "lodash";
import Multiselect from "vue-multiselect";
import axios from "axios";
import store from "@/plugins/store";

export default {
  components: {
    Multiselect,
    OpenLayersMap
  },
  mixins: [
    featureStyle
  ],
  props: {
    context: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      searchIsLoading: false,
      selectedFeatures: [],
      showDataColumns: [],
    }
  },
  static() {
    return {
      searchOptions: [], // This holds all options loaded from the datasource
    };
  },
  computed: {
    maxFeaturesSelect: function() {
      return this.context.attributes.mapSettings.featuresMaxSelect ? this.context.attributes.mapSettings.featuresMaxSelect : 1
    },
    isMultiple() {
      if (this.maxFeaturesSelect > 1) {
        return true
      }
      else {
        return false
      }
    },
    maxSelectedValuesReached() {
      if (this.isMultiple && this.selectedFeatures.length >= this.maxFeaturesSelect) {
        return true
      }
      else {
        return false
      }
    },
    placeholder() {
      if (this.searchIsLoading) {
        return "Lijst wordt geladen.."
      }
      else if (this.maxSelectedValuesReached) {
        return "Maximaal aantal geselecteerd"
      }
      return "Typ om te zoeken"
    },
    datasourceID() {
      // Get datasource ID
      if (this.context.attributes.mapSettings.mapLayers && this.context.attributes.mapSettings.mapLayers.length > 0) {
        return this.context.attributes.mapSettings.mapLayers[0].datasource_id
      }
      return false
    },
    mapSearchIsEnabled() {
      if (this.context.attributes.mapSettings.showMapSearch) {
        return true
      }
      return false
    },
    appOnline() {
      return store.getters.appOnline;
    },
  },
  watch: {
    'context.model': {
      deep: true,
      handler(values) {
        if (isObject(values) && Object.prototype.hasOwnProperty.call(values, 'features') && Array.isArray(values.features)) {
          this.selectedFeatures = values.features;
        }
        else {
          this.selectedFeatures = [];
        }
      }
    }
  },
  mounted() {
    if (this.mapSearchIsEnabled) {
      // Fetch datasource from API and set the search options
      this.getMapDatasourceForSearchFromApi()
    }
  },
  methods: {
    getMapDatasourceForSearchFromApi() {
      // Get datasource ID
      const datasourceID = this.datasourceID
      if (datasourceID) {
        this.searchIsLoading = true

        // Fetch results form the API or from cache when offline
        if (this.appOnline) {
          let config = {
            params: {
              'format': 'search',
            }
          }
          // Fetch results form the API
          axios
              .get('api/datasource/'+datasourceID, config)
              .then(response => {

                // Save result to cache
                store.dispatch('storeCache/cacheSet', {
                  type: 'datasource',
                  cid: 'datasource:'+datasourceID+':search',
                  cache: response.data,
                });

                // Set options
                this.searchOptions = response.data.json;
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() =>
                  this.searchIsLoading = false
              )
        }
        else {
          // User is offline, fetch from cache
          let cache = this.$store.getters['storeCache/cacheGet']('datasource', 'datasource:'+datasourceID+':search')
          // Set options
          this.searchOptions = cache
          this.searchIsLoading = false
        }

      }
    },
    updateModel(newSelected) {
      // Convert OpenLayers features to GeoJSON
      let writer = new GeoJSON();
      if (newSelected.length > 0) {
        // Save features to the Vue Formulate values as GeoJSON
        this.context.model = writer.writeFeaturesObject(newSelected, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857'});
      }
      else {
        this.context.model = '';
      }
    },
    selectFeature(feature) {
      let selected = cloneDeep(this.selectedFeatures);
      if (selected < this.maxFeaturesSelect) {
        selected.push(feature);
        this.updateModel(selected);
      }
    },
    deselectFeature(featureId) {
      let selected = cloneDeep(this.selectedFeatures);

      // Find feature in the list of selected features
      let length = selected.length
      for (let i = 0; i < length; i++) {
        // Check if this is the feature we want to delete
        if (selected[i].id === featureId) {
          selected.splice(i, 1);
          this.updateModel(selected);
          return
        }
      }
    },
    featureColor(feature) {
      // Get color from status
      return this.featureStyleProps(this.context.attributes.mapSettings, feature.properties.status, feature.properties.invisible)
    },
    selectSearchOption(selectedOption) {
      // TODO: This was not working, so we don't deselect feature. I suspect it was not working because the $emit selectFeature and $emit deselectFeature was triggered in the wrong order.
      // // Deselect the previous (currently selected) feature
      // if (this.selectedFeatures[0]) {
      //   this.$refs.map.deselectFeatureById(this.selectedFeatures[0].id);
      // }
      // Go to the map location
      this.$refs.map.gotoCoordinates(selectedOption.geometry.coordinates);
      // Select the feature
      this.$refs.map.selectFeatureById(selectedOption.id);
    }
  },
}
</script>

<style lang="scss" scoped>
.formulate-input-element--eformMap {
  max-width: 100%;
}
.feature-details-table {
  width: 100%;

  tr {
    td {
      border-bottom: 1px solid #eee;
      padding: 5px;
    }
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}
</style>