<template>
  <div>
    <label
        :id="`${context.id}_label`"
        :class="context.classes.label"
        :for="context.id"
    >
      {{ context.label }}
<!--      <span class="formulate-input-help" v-if="context.help">{{ context.help }}</span>-->
      <span class="required" v-if="required">*</span>
    </label>
  </div>
</template>

<script>
export default {
  computed: {
    required: function () {
      // Check if element contains a rule with 'required'
      let length = this.context.rules.length;
      for (let i = 0; i < length; i++) {
        if (this.context.rules[i].ruleName === 'required') {
          return true
        }
      }
      return false
    }
  },
  props: {
    context: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .required {
    color: #e60000;
  }
</style>
