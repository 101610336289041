/* eslint-disable no-console */

import { register } from 'register-service-worker'
import router from "@/plugins/routes";

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered(registration) {
      console.log(
          'Service worker has been registered and is now checking for updates beforeEach route change.'
      )

      // setInterval(() => {
      //   registration.update()
      // }, 60000) // every 60 seconds

      router.beforeEach( (to, from, next) => {
        // Fetch the worker's script URL and check for updates.
        // In our case the update is applied/activated immediately because we have set 'skipWaiting': true in vue.config.js
        registration.update()
        next();
      });

    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; updating app automatically because "skipWaiting": true in vue.config.js');
      document.dispatchEvent(
          new CustomEvent('swUpdated', { detail: registration })
      )
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
