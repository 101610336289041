<template>
  <div>

    <FormulateForm :debounce="100">

<!-- TODO: Multiple feature select was giving some problems when selecting/deselecting on the map AND via the search. This feature was never used by any form. -->
<!--      <FormulateInput-->
<!--          :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90]"-->
<!--          type="select"-->
<!--          label="Maximaal aantal te selecteren features"-->
<!--          help="Hoeveel features mogen er geselecteerd worden op de kaart?"-->
<!--          name="featuresMaxSelect"-->
<!--          v-model="element.mapSettings.featuresMaxSelect"-->
<!--          validation="required"-->
<!--      />-->
      <FormulateInput
          type="group"
          name="mapLayers"
          v-model="element.mapSettings.mapLayers"
          label="Kaartlagen"
          add-label="+ Kaartlaag toevoegen"
      >
        <FormulateInput
            type="hidden"
            name="type"
            value="geojson"
        /> <!-- This is here to make it more futureproof and easily extensible with other map types -->
      <template v-if="datasources && datasources.length >= 1">
          <FormulateInput
              type="select"
              name="datasource_id"
              label="Databron"
              validation="required"
              placeholder="Selecteer een databron"
              :options="datasources"

          />
      </template>
      <template v-else>
        <div class="formulate-input">
          <label class="formulate-input-label">Er zijn nog geen databronnen beschikbaar voor deze klant</label>
          <div class="formulate-input-help formulate-input-help--after">Maak eerst een nieuwe databron aan of controleer of de aangemaakte databron wel aan de huidige klant gekoppeld is.</div>
        </div>
      </template>
        <FormulateInput
            type="select"
            name="loadingStrategy"
            label="Wanneer moet de databron ingeladen worden?"
            help="[Vooraf inladen]: Geschikt voor databronnen tot ±10.000 items. Alle kaart items worden direct ingeladen. [Live]: Best optie voor databronnen groter dan 10.000 items. De kaart items worden live ingeladen die binnen het schem past, elke keer wanneer je de kaart verplaatst."
            validation="required"
            :options="{preload: 'Vooraf inladen', live: 'Live'}"
        />
      </FormulateInput>

      <FormulateInput
          type="checkbox"
          name="showMapSearch"
          label="Toon kaart zoekveld"
          help="Let op: Dit is niet geschikt voor databronnen met meer dan 10.000 items. Als deze is aangevinkt wordt er een zoekveld onder de kaart getoond waarmee u items op de kaart mee kunt zoeken."
          error-behavior="live"
          v-model="element.mapSettings.showMapSearch"
      />

      <FormulateInput
          type="group"
          label="Geselecteerde features"
          help="Hier kunt u instellen hoe een geselecteerde feature er uit ziet in de tabel onder de kaart.">
        <v-row>
          <v-col>

            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>Toon kolomnamen</v-expansion-panel-header>
                <v-expansion-panel-content>

                  <FormulateInput
                      type="checkbox"
                      name="showDataColumns"
                      v-model="element.mapSettings.showDataColumns"
                      :options="datasourcesDataProperties"
                  />

                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

          </v-col>
        </v-row>
      </FormulateInput>


      <FormulateInput
          type="group"
          label="Kaart weergave"
          help="Geef aan op welke locatie de kaart geopend moet worden.">
        <v-row>
          <v-col>
            <FormulateInput
                type="text"
                name="lat"
                label="Latitude"
                placeholder="52.155227"
                :validation="[['bail'], ['required'], ['matches', /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,18})?))$/]]"
                error-behavior="live"
                v-model="element.mapSettings.center.lat"
            />
          </v-col>
          <v-col>
            <FormulateInput
                type="text"
                name="lon"
                label="Longitude"
                placeholder="5.387289"
                :validation="[['bail'], ['required'], ['matches', /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,18})?))$/]]"
                error-behavior="live"
                v-model="element.mapSettings.center.lon"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <FormulateInput
                :options="{1: '1 - Wereld', 2: '2', 3: '3', 4: '4 - Continent', 5: '5', 6: '6', 7: '7 - Land', 8: '8', 9: '9 - Provincie', 10: '10', 11: '11 - Gemeente', 12: '12', 13: '13 - Stad', 14: '14 - Wijk/Dorp', 15: '15', 16: '16 - Straat', 17: '17', 18: '18', 19: '19 - Huis'}"
                type="select"
                label="Zoomlevel"
                validation="required"
                v-model="element.mapSettings.zoomlevel"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <FormulateInput
                type="checkbox"
                name="show_status_filters"
                label="Toon status filters"
                error-behavior="live"
                v-model="element.mapSettings.show_status_filters"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <FormulateInput
                type="checkbox"
                name="guest_map_enabled"
                label="Gast kaart ingeschakeld"
                error-behavior="live"
                v-model="element.mapSettings.guest_map_enabled"
            />
          </v-col>
        </v-row>
        <v-row v-if="element.mapSettings.guest_map_enabled && element.mapSettings.guest_map_enabled === true">
          <v-col>

            <v-alert
                class="mb-4"
                dense
                prominent
                text
                type="warning"
                v-if="guestMapUrlIsFreshlyGenerated"
            >
              U moet eerst het formulier opslaan voordat de gast kaart URL te gebruiken is.
            </v-alert>

            <FormulateInput
                type="group"
                label="Openbare link naar de gast kaart">
              <code>
                <template v-pre></template>{{ getMapGuestUrl() }}<template v-pre></template>
                <v-icon class="ml-1" small
                        v-clipboard:copy="getMapGuestUrl()"
                        v-clipboard:success="onCopySuccess"
                        v-clipboard:error="onCopyError"
                >mdi-clipboard-multiple-outline</v-icon>
              </code>
            </FormulateInput>
          </v-col>
        </v-row>
      </FormulateInput>


      <FormulateInput
          type="group"
          name="featureStyles"
          v-model="element.mapSettings.featureStyles"
          help="U kunt per status een kleur geven aan de features. Let op: De naam van de status is hoofdlettergevoelig."
          :repeatable="true"
          label="Feature stijlen"
          add-label="+ Stijl toevoegen"
      >
        <v-row>
          <v-col>
            <FormulateInput
                label="Status"
                type="text"
                name="status"
                validation="bail|required"
                help="De naam van de status"
                error-behavior="live"
            />
          </v-col>
          <v-col>
            <FormulateInput
                type="color"
                name="color"
                label="Kleur"
                placeholder="Sample color placeholder"
                validation="required"
                value="#3eaf7c"
                error-behavior="live"
            />
          </v-col>
        </v-row>

      </FormulateInput>

      <FormulateInput
          type="group"
          name="featureActions"
          v-model="element.mapSettings.featureActions"
          help="Welke status moet de feature krijgen nadat deze geregistreerd is?"
          :repeatable="true"
          label="Feature acties"
          add-label="+ Actie toevoegen"
          #default="fag"
      >

<!--        Feature action index = {{ feature_action_index }}-->

        <v-row>
          <v-col>

            <FormulateInput
                type="group"
                name="conditions"
                :repeatable="true"
                label="Als"
                add-label="+ Conditie toevoegen"
                #default="cg"
            >

              <FormulateInput
                  :options="{'always': 'altijd', 'dataValue': 'formulierveld'}"
                  type="select"
                  name="conditionType"
                  validation="required"
              />

<!--              Feature action index = {{ conditions_index }}-->

              <template v-if="
                element.mapSettings.featureActions[fag.index] &&
                element.mapSettings.featureActions[fag.index].conditions &&
                element.mapSettings.featureActions[fag.index].conditions[cg.index] &&
                element.mapSettings.featureActions[fag.index].conditions[cg.index].conditionType === 'dataValue'">
                <!--          met de datanaam-->
                <FormulateInput
                    type="select"
                    name="dataName"
                    placeholder="Datanaam"
                    :options="datanames"
                    validation="bail|required"
                />
                <!--          met de waarde-->
                <FormulateInput
                    :options="{'=': 'is gelijk aan'}"
                    type="select"
                    name="operator"
                    validation="required"
                />
                <template v-if="
                element.mapSettings.featureActions[fag.index] &&
                element.mapSettings.featureActions[fag.index].conditions &&
                element.mapSettings.featureActions[fag.index].conditions[cg.index] &&
                element.mapSettings.featureActions[fag.index].conditions[cg.index].dataName">
                  <FormulateInput
                      type="select"
                      help="waarde"
                      name="dataValue"
                      :options="getElementValues(element.mapSettings.featureActions[fag.index].conditions[cg.index].dataName)"
                      validation="bail|required"
                  />
                  <!--                <FormulateInput-->
                  <!--                    type="text"-->
                  <!--                    help="waarde"-->
                  <!--                    name="dataValue"-->
                  <!--                    placeholder="Waarde"-->
                  <!--                    validation="bail|required"-->
                  <!--                />-->
                </template>
              </template>
            </FormulateInput>

            <FormulateInput
                label="Welke condities moeten waar zijn?"
                :options="{'and': 'Alle condities (AND)', 'or': 'Één conditie (OR)'}"
                type="select"
                name="conditionMethod"
                validation="required"
            />

          </v-col>
          <v-col>

            <FormulateInput
                type="group"
                name="actions"
                label="Dan"
            >
              <FormulateInput
                  :options="{'setStatus': 'wijzig feature status naar', 'setHidden': 'verberg feature'}"
                  type="select"
                  name="actionType"
                  validation="required"
              />
              <template v-if="
              element.mapSettings.featureActions[fag.index] &&
              element.mapSettings.featureActions[fag.index].actions &&
              element.mapSettings.featureActions[fag.index].actions[0].actionType === 'setStatus'">
<!--                <FormulateInput-->
<!--                    type="text"-->
<!--                    name="status"-->
<!--                    validation="bail|required"-->
<!--                    help="Statusnaam"-->
<!--                />-->
                <FormulateInput
                    type="select"
                    name="status"
                    :options="getStatusOptions(fag.index)"
                    validation="bail|required"
                    help="Statusnaam"
                />
              </template>
            </FormulateInput>

          </v-col>
        </v-row>


      </FormulateInput>

    </FormulateForm>
  </div>
</template>

<script>
import axios from "axios";
import eformHelpers from "@/mixins/eformHelpers";
// import _ from "lodash";

export default {
  name: 'EformElementEditMap',
  mixins: [
    eformHelpers
  ],
  props: {
    form: Object,
    element: Object,
  },
  data: () => ({
    fag: null,
    cg: null,
    datasources: [],
    datasourcesDataProperties: [],
    guestMapUrlIsFreshlyGenerated: false,
  }),
  computed: {
    datanames() {
      // Get all datanames in the form
      return this.getDatanamesAsSelectList(this.form, ['checkbox_multiple', 'radio', 'select'])
    }
  },
  watch: {
    'element.mapSettings.mapLayers': {
      deep: true,
      async handler() {
        // Get new data properties
        await this.getDatasourcesDataPropertiesFromApi()
        // Check all items when switching datasources
        this.$set(this.element.mapSettings, 'showDataColumns', this.datasourcesDataProperties)
      }
    },
    'element.mapSettings.guest_map_enabled': {
      deep: true,
      handler() {
        // Set flag that the map URL is just generated
        this.guestMapUrlIsFreshlyGenerated = true
      }
    }
  },
  mounted () {
    // Get items
    this.getDatasourcesFromApi()
    // Get data properties
    this.getDatasourcesDataPropertiesFromApi()
  },
  methods: {
    getStatusOptions() {
      let statusOptions = []
      for(let status of this.element.mapSettings.featureStyles) {
        statusOptions.push({
          value: status.status,
          label: status.status
        })
      }
      return statusOptions
    },
    getElementValues(dataName) {
      // Find element in form schem and return the options
      for (let i in this.form.schema) {
        if (this.form.schema[i].name === dataName && this.form.schema[i].options) {
          return this.form.schema[i].options;
        }
      }
      return [];
    },
    getDatasourcesFromApi () {
      let client_id = this.$store.getters.CurrentClient;
      // Fetch results form the API
      axios
          .get('api/datasource/list/map_features/'+client_id)
          .then(response => {
            // Loop over results and format in into a options array
            let options = []
            // Format datasource list into select options
            for (let item of response.data) {
              options.push({
                value: item.id,
                label: item.name
              })
            }
            // Set datasources
            this.datasources = options
          })
          .catch(error => {
            console.log(error)
          })
    },
    getDatasourcesDataPropertiesFromApi () {
      // Get datasource ID
      let datasource_id = this.element.mapSettings.mapLayers[0].datasource_id
      if (datasource_id) {
        // Fetch results form the API
        return axios
            .get('api/datasource/'+datasource_id+'/data-properties')
            .then(response => {
              // Set datasource data properties
              this.datasourcesDataProperties = response.data.data_properties
            })
            .catch(error => {
              console.log(error)
            })
      }
    },
    getMapGuestUrl() {
      const resolved = this.$router.resolve({
        name: 'guest_map_form',
        params: {
          form_id: this.form.form_id,
          element_name: this.element.name,
        },
      });

      let link =process.env.VUE_APP_BASEURL;
      link += resolved.href;

      return link;
    },
    onCopySuccess() {
      let $vm = this;
      $vm.$toast.success("Gekopieerd");
    },
    onCopyError() {
      let $vm = this;
      $vm.$toast.error("Kon niet kopieren.");
    },
  }
}
</script>

<style lang="scss">
// Make the Vue Formulate repeatable group wider.
.formulate-input .formulate-input-element--group {
  max-width: 100% !important;
}
</style>
