import Vue from 'vue'
import App from './App.vue'
import './plugins/rollbar'
import vuetify from './plugins/vuetify';
import router from './plugins/routes';
import toastification from './plugins/toastification';
import './plugins/formulate';
import store from './plugins/store';
import './registerServiceWorker'
import axios from './plugins/axios';
import moment from './plugins/moment';
import numeral from './plugins/numeral';
import VueClipboard from 'vue-clipboard2'


VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

Vue.config.productionTip = false
axios.defaults.showLoader = true;

new Vue({
  vuetify,
  router,
  store,
  toastification,
  axios,
  moment,
  numeral,
  render: h => h(App)
}).$mount('#app')
