<template>
  <div>
    <span class="caption">Ligt tussen</span>
<!--    <v-text-field-->
<!--        label="Is"-->
<!--        v-model="valueData"-->
<!--        filled-->
<!--        dense-->
<!--        clearable-->
<!--        hide-details-->
<!--        @input="onInput"-->
<!--    ></v-text-field>-->
    <v-text-field
        placeholder="Van"
        v-model="dataMin"
        class="mt-1 eform-registration-text-field"
        filled
        dense
        rounded
        clearable
        hide-details
        @input="onInput"
    ></v-text-field>
    <v-text-field
        placeholder="Tot"
        v-model="dataMax"
        class="mt-1 eform-registration-text-field"
        filled
        dense
        rounded
        clearable
        hide-details
        @input="onInput"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'EformTableFilterInteger',
  data () {
    return {
      dataValue: '',
      dataMin: '',
      dataMax: '',
    }
  },
  props: {
    filterKey: String,
    value: Object,
  },
  mounted() {
    this.dataValue = this.value.value
    this.dataMin = this.value.min
    this.dataMax = this.value.max
  },
  methods: {
    onInput() {
      // Emit event
      this.$emit('value-change', this.filterKey, {
        value: this.dataValue,
        min: this.dataMin,
        max: this.dataMax
      });
    },
  }
}
</script>


<style lang="scss">
  .eform-registration-text-field {
    .v-input__slot {
      padding-left: 14px !important;
      padding-right: 14px !important;
      min-height: 34px !important;
    }
    .v-input__append-inner {
      margin-top: 6px !important;
    }
    .v-label {
      top: 13px !important;
    }
    input {
      font-size: 13px;
      margin-top: 1px !important;
    }
  }
</style>
