import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters({ClientInfo: "StateClientInfo"}),
    },
    methods: {
        userHasPermission(permission, clientInfo = null) {
            if (clientInfo === null) {
                clientInfo = this.ClientInfo;
            }

            // Admin is always allowed
            if (clientInfo.roles.includes('admin')) {
                return true;
            }

            // Check if clientInfo has permission
            if (clientInfo.permissions.includes(permission)) {
                return true
            }
            return false
        },
        userHasRole(role, clientInfo = null) {
            if (clientInfo === null) {
                clientInfo = this.ClientInfo;
            }

            if (clientInfo.roles.includes(role)) {
                return true;
            }

            return false;
        },
        userCanNavigateToPage(pageName, clientInfo = null) {
            if (clientInfo === null) {
                clientInfo = this.ClientInfo;
            }

            switch (pageName) {
                case 'form_list_admin':
                    return (this.userHasPermission('eform manage forms', clientInfo) || this.userHasPermission('eform manage form registrations', clientInfo) || this.userHasPermission('eform manage form users', clientInfo));

            }

            return false;
        },
    }
};