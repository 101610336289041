<template>
  <div>
    <v-alert
        class="my-2"
        v-if="geolocationHasError"
        dense
        prominent
        text
        type="warning"
    >
      {{ geolocationHasError }}
    </v-alert>

    <div style="height: 4px;">
      <v-progress-linear
          indeterminate
          v-if="loading"
      ></v-progress-linear>
    </div>

    <div ref="map-root" style="width: 100%; height: 50vh;" class="map-root">
      <div :id="'map_locate_' + mapID" class="ol-control ol-unselectable locate map_locate_btn">
        <button title="Ga naar mijn locatie" onclick="return false;"><svg style="width:14px;height:14px" viewBox="0 0 14 14">
          <template v-if="geolocationCenterOnLocation">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-arrow" class="svg-inline--fa fa-location-arrow fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M444.52 3.52L28.74 195.42c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 51.17 70.36 67.17 92.75 19.19l191.9-415.78c15.99-38.39-25.59-79.97-63.97-63.97z"></path></svg>
          </template>
          <template v-else>
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="location-arrow" class="svg-inline--fa fa-location-arrow fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M461.9 0c-5.73 0-11.59 1.1-17.39 3.52L28.74 195.41c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 30.01 24.21 47.93 48.74 47.93 17.3 0 34.75-8.9 44.01-28.74l191.9-415.78C522.06 34.89 494.14 0 461.9 0zM271.81 464.07V240.19h-47.97l-175.48.71c-.27-.37-.47-1.35.48-1.93L462.05 48.26c.61.41 1.28 1.07 1.69 1.68L271.81 464.07z"></path></svg>
          </template>
        </svg></button>
      </div>
      <div v-if="showStatusFilters" :id="'status_filter_' + mapID" class="ol-control ol-unselectable ol-filter-status status_filter_btn" :class="{'ol-collapsed': statusFiltersCollapsed}">
        <button title="Filter features op status" @click.prevent="toggleStatusFilters" :aria-expanded="!statusFiltersCollapsed">
          <svg style="width:14px;height:14px" viewBox="0 0 14 14">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="filter" class="svg-inline--fa fa-filter fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="currentColor" d="M3.853 54.87C10.47 40.9 24.54 32 40 32H472C487.5 32 501.5 40.9 508.1 54.87C514.8 68.84 512.7 85.37 502.1 97.33L320 320.9V448C320 460.1 313.2 471.2 302.3 476.6C291.5 482 278.5 480.9 268.8 473.6L204.8 425.6C196.7 419.6 192 410.1 192 400V320.9L9.042 97.33C-.745 85.37-2.765 68.84 3.854 54.87L3.853 54.87z"/></svg>
          </svg>
        </button>
        <div class="status-filter-content">
          <FormulateInput
              v-model="statusFilterValue"
              :options="statusFilterOptions"

              type="checkbox"
              label="Status filters"
          />

        </div>
      </div>
    </div>
    <v-alert
        class="my-2"
        v-if="!selectedFeatureExists"
        dense
        type="warning"
    >
      Het geselecteerde item kon niet worden gevonden op de kaart. De status in de tabel is de status van de oude registratie voordat deze verstuurd was.
    </v-alert>
  </div>
</template>

<style>
.map-root {
  position:relative;
}
.map-root .map_locate_btn {
  position:absolute;
  text-align: right;
  top: 0.5em;
  right: 0.5em;
  display:flex;
  flex-flow: row-reverse;
  align-items:flex-end;
  z-index: 11;
}

.map-root .status_filter_btn {
  position: absolute;
  text-align:left;
  bottom:0.5em;
  left:0.5em;
  display:flex;
  flex-flow: row;
  align-items:flex-end;
  z-index:11;
  flex-shrink: 0;
}

.ol-filter-status {
  text-align: left;
  bottom: 0.5em;
  left: 0.5em;
  max-width: calc(100% - 1.3em);
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
}

.ol-filter-status:not(.ol-collapsed) {
  background: rgba(255,255,255,0.8);
}
.ol-filter-status.ol-collapsed div.status-filter-content {
  display: none;
}
.ol-filter-status div.status-filter-content {
  margin: 0;
  padding: 1px 0.5em;
  color: #000;
  text-shadow: 0 0 2px #fff;
  font-size: 12px;
}
</style>

<script>
// importing the OpenLayers stylesheet is required for having
// good looking buttons!
import 'ol/ol.css'

import Vue from "vue";
import VueStatic from 'vue-static'
import axios from "axios";
import View from 'ol/View'
import Map from 'ol/Map'
import Geolocation from 'ol/Geolocation';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Control from 'ol/control/Control';
// import VectorSource from 'ol/source/Vector'
import GeoJSON from 'ol/format/GeoJSON'
// import OSM from 'ol/source/OSM'
// import Style from "ol/style/Style";
// import Fill from "ol/style/Fill";
// import Stroke from "ol/style/Stroke";
// import Point from 'ol/geom/Point';
// import Circle from 'ol/style/Circle'
import {fromLonLat, transformExtent} from 'ol/proj';
// import Feature from 'ol/Feature';
import {Circle as CircleStyle, Fill, Stroke, Style, Text,} from 'ol/style';
// import Cluster from 'ol/source/Cluster'
import {Cluster, OSM, Vector as VectorSource} from 'ol/source';
import RegularShape from "ol/style/RegularShape";
import {debounce} from 'lodash';
import featureStyle from "@/mixins/featureStyle";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import {Attribution, defaults as defaultControls} from 'ol/control';
import store from "@/plugins/store";
import {
  defaults as defaultInteractions,
} from 'ol/interaction';
import userAgent from "@/mixins/userAgent";
import { uuid } from 'vue-uuid';
import _ from "lodash"; // uuid object is also exported to things outside Vue instance.
import Select from "ol/interaction/Select";

Vue.use(VueStatic);

// See example from:
// https://dev.to/camptocamp-geo/integrating-an-openlayers-map-in-vue-js-a-step-by-step-guide-2n1p


export default {
  name: 'MapContainer',
  components: {},
  mixins: [
    featureStyle,
    userAgent
  ],
  props: {
    mapSettings: {
      type: Object,
    },
    selectedFeatures: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      mapID: uuid.v4(),
      loading: false,
      geolocationCenterOnLocation: false,
      geolocationHasError: false,
      selectedFeatureExists: true,
      statusFiltersCollapsed: true,
      statusFilterOptions: [],
      statusFilterValue: [],
    }
  },
  computed: {
    datasourceID() {
      // Get datasource ID
      if (this.mapSettings.mapLayers && this.mapSettings.mapLayers.length > 0) {
        return this.mapSettings.mapLayers[0].datasource_id
      }
      return false
    },
    loadingStrategy() {
      // Get loadingStrategy
      if (this.mapSettings.mapLayers && this.mapSettings.mapLayers.length > 0) {
        return this.mapSettings.mapLayers[0].loadingStrategy
      }
      return 'preload'
    },
    appOnline() {
      return store.getters.appOnline;
    },
    showStatusFilters() {
      return (_.has(this.mapSettings, 'show_status_filters') && this.mapSettings.show_status_filters === true);
    }
  },
  static() {
    return {
      source: null,
      vectorLayer: null,
      sourceCenterPin: null,
      vectorLayerCenterPin: null,
      clusterSource: null,
      clustersLayer: null,
      geolocation: null,
      olView: null,
      olMap: null,
      featureStyleCache: {}, // Feature styles
      clusterStyleCache: {}, // Cluster styles,
      // requestCancelToken: undefined
    };
  },
  watch: {
    'mapSettings.center': {
      deep: true,
      handler(value) {
        this.olView.setCenter(fromLonLat([value.lon, value.lat]))
      }
    },
    'mapSettings.zoomlevel': function(value) {
      this.olView.setZoom(value)
    },
    'mapSettings.mapLayers': function() {
      // Remove old layers and create new layers
      this.recreateMapLayers()
    },
    'mapSettings.featureStyles': function() {
      // Refresh map when mapSettings change
      this.refreshMap()
    },
    'statusFilterValue': function() {
      this.refreshMap();
    },
    'selectedFeatures': {
      deep: true,
      handler(newValue, oldValue) {
        for (let i = 0; i < oldValue.length; i++) {
          let feature = this.source.getFeatureById(oldValue[i].id);

          if (feature) {
            feature.set('selected', undefined);
          }
        }

        for (let j = 0; j < newValue.length; j++) {
          let feature = this.source.getFeatureById(newValue[j].id);

          if (feature) {
            feature.set('selected', true);
          }
        }
      }
    },
  },
  mounted() {
    let createTextStyle = function (feature, fontsize, offsetY) {
      let font = fontsize+'px "Roboto", sans-serif'
      let textColor = new Fill({color: '#222'})
      if (feature.get('selected')) {
        font = 'bold '+fontsize+'px "Roboto", sans-serif'
        textColor = new Fill({color: '#1976d2'})
      }

      return new Text({
        text: getText(feature),
        font: font,
        // stroke: new Stroke({color: [0, 0, 0, 1], width: 3}),
        fill: textColor,
        offsetY: offsetY
      })
    };

    let getText = function (feature) {
      return feature.get('name');
    };

    function styleFunction(feature, resolution) {
      if (!$vm.isFeatureVisible(feature)) {
        return null;
      }

      let strokeStyle = undefined
      if (feature.get('selected')) {
        strokeStyle = new Stroke({color: '#1976d2', width: 3})
      }

      // Calculate radius
      let radius = 6 / resolution
      if (radius < 5) {
        radius = 5
      }
      else if (radius > 16) {
        radius = 16
      }
      // Get color from status
      let fillColor = $vm.featureStyle($vm.mapSettings, feature)

      let style = {
        image: new CircleStyle({
          radius: radius,
          fill: new Fill({color: fillColor}),
          stroke: strokeStyle,
        }),
      }


      // Show feature text when zoomed in
      if (resolution < 2) {
        // Calculate fontsize
        let fontsize = radius*1.2
        if (fontsize < 10) {
          fontsize = 10
        }
        // Calculate offset
        let offsetY = radius + (fontsize/2) + 3
        // Add feature text
        style.text = createTextStyle(feature, fontsize, offsetY)
      }

      return new Style(style);
    }

    // Make Vue 'this' accessible inside other functions
    let $vm = this;

    // This hold all features
    this.source = new VectorSource();
    // a new vector layer is created with the feature
    this.vectorLayer = new VectorLayer({
      name: 'Features',
      source: this.source,
      minResolution: 0,
      maxResolution: 3,
      style: styleFunction,
    })

    // This hold the center pin when tracking the user his location
    this.sourceCenterPin = new VectorSource();
    // a new vector layer is created with the feature
    this.vectorLayerCenterPin = new VectorLayer({
      name: 'CenterPin',
      source: this.sourceCenterPin,
    })

    // Add cluster layer in between (is only used when the function setFeaturesClusters) is called)
    this.clusterSource = new Cluster({
      distance: 40,
      source: this.source,
      geometryFunction: function(feature) {
        if (!$vm.isFeatureVisible(feature)) {
          return null;
        }
        return feature.getGeometry();
      }
    });

    this.clustersLayer = new VectorLayer({
      name: 'Cluster',
      source: this.clusterSource,
      minResolution: 3,
      // maxResolution: 20000,
      style: function(feature) {
        let size = feature.get('features').length;
        let style = $vm.clusterStyleCache[size];
        if (!style) {
          style = new Style({
            image: new RegularShape({
              points: 4,
              radius: 20 / Math.SQRT2,
              radius2: 20,
              angle: 0,
              fill: new Fill({
                color: '#41b883'
              })
            }),
            text: new Text({
              text: size.toString(),
              font: '11px "Roboto", sans-serif',
              fill: new Fill({color: '#222'})
            })
          });
          $vm.clusterStyleCache[size] = style;
        }
        return style;
      }
    });


    this.olView = new View({
      zoom: this.mapSettings.zoomlevel,
      center: fromLonLat([this.mapSettings.center.lon, this.mapSettings.center.lat]),
      constrainResolution: true
    })

    const attribution = new Attribution({
      collapsible: true,
      collapsed: true
    });

    // Set interactions options
    let interactionOptions = {}
    // Disable pinchZoom and pinchRotate until this iOS 15.5 bug is fixed:
    // @see https://github.com/openlayers/openlayers/issues/13746
    // if (this.isMobile.iOS()) {
    //   interactionOptions = {
    //     pinchZoom: false,
    //     pinchRotate: false,
    //   }
    // }
    // Create interactions
    let interactions = defaultInteractions(interactionOptions)


    // this is where we create the OpenLayers map
    this.olMap = new Map({
      // the map will be created using the 'map-root' ref
      target: this.$refs['map-root'],
      layers: [
        // adding a background tiled layer
        new TileLayer({
          source: new OSM() // tiles are served by OpenStreetMap
        }),
        // the vector layer is added above the tiled OSM layer
        this.clustersLayer,
        this.vectorLayer,
        this.vectorLayerCenterPin,
      ],
      // Set map view
      view: this.olView,
      controls: defaultControls({attribution: false}).extend([attribution]),
      interactions: interactions,
    });

    // Fetch map features from API
    this.createMapLayers()

    // Bind event to button
    const locate = document.getElementById('map_locate_' + this.mapID);
    locate.addEventListener('click', this.getLocation);
    this.olMap.addControl(new Control({
      element: locate,
    }));


    if (this.showStatusFilters) {
      // Bind event to button
      const status_filter = document.getElementById('status_filter_' + this.mapID);
      locate.addEventListener('click', this.featureFilter);
      this.olMap.addControl(new Control({
        element: status_filter,
      }));
    }
    // Automatically go to location on mounted
    this.getLocation()

    // On feature click
    const selectSingleClick = new Select({multi: false, style: styleFunction, layers: function(layer) {
      return layer.get('name') === 'Features';
      }});
    this.olMap.addInteraction(selectSingleClick);
    selectSingleClick.on('select', function (e) {
      // Check if user can select more features
      let featureMaxSelect = $vm.mapSettings.featuresMaxSelect ? $vm.mapSettings.featuresMaxSelect : 1
          // Get current amount of selected features
      let featuresSelected = $vm.selectedFeatures.length

      // We clear the function so all will be in selected, not deselected
      e.selected.forEach(function(feature){
        let selIndex = $vm.selectedFeatures.findIndex(f => f.id == feature.getId());
        if (selIndex < 0) {
          if (featuresSelected < featureMaxSelect) {
            $vm.selectFeature(feature)
          }
        } else {
          $vm.deselectFeature(feature)
        }

        selectSingleClick.getFeatures().clear();
      });
    });

    this.olMap.on('pointerdrag', function () {
      // Disable center on location
      $vm.geolocationCenterOnLocation = false
    });


    this.olMap.on('moveend', function () {
      if ($vm.loadingStrategy === 'live') {
        $vm.loadFeaturesWithinExtent()
      }
    });

    // map_controls.featureFilterControl = function () {
    //
    //   var anchor = document.createElement('a');
    //   anchor.href = '#featureFilter';
    //   anchor.ariaLabel = 'Objecten filter'
    //   anchor.className = 'featureFilter-button fa far fa-filter';
    //
    //   anchor.addEventListener('click', featureFilter, false);
    //   anchor.addEventListener('touchstart', featureFilter, false);
    //
    //   var element = document.createElement('div');
    //   element.className = 'featureFilter-button-wrapper ol-unselectable';
    //   element.title = Drupal.t('Filter');
    //   element.appendChild(anchor);
    //
    //   ol.control.Control.call(this, {
    //     element: element
    //   });
    //
    // };
    // ol.inherits(map_controls.featureFilterControl, ol.control.Control);
    //
    // // Click on legend icon
    // function featureFilter(e) {
    //
    //   // Show filter sidebar
    //   $('.map-wrapper').toggleClass('map-wrapper-filter-active');
    //   // Update map size during animation and when done animating
    //   var animationTimer = setInterval(function () {
    //     map.updateSize();
    //   }, 25);
    //   setTimeout(function () {
    //     clearInterval(animationTimer);
    //   }, 300);
    //
    // }
    //
    // // Map filter listeners
    // $('.map-filter input[type=text], .map-filter select').on('keyup change', function () {
    //   // Apply filter
    //   map_functions.map_filter_features();
    // });

  },
  methods: {
    toggleStatusFilters() {
      this.statusFiltersCollapsed = !this.statusFiltersCollapsed;
    },
    getFeatureStatus(feature) {
      var status = feature.get('status');
      if (_.isEmpty(status)) {
        status = '_none';
      }

      return status;
    },
    isFeatureVisible(feature) {
      if (!this.showStatusFilters) {
        return true;
      }

      return this.statusFilterValue.includes(this.getFeatureStatus(feature));
    },
    getLocation() {
      // Toggle button
      this.geolocationCenterOnLocation = true
      // Bind geolocation when not set yet.
      if (!this.geoLocation) {
        // Sets up the location
        this.geoLocation = new Geolocation({
          trackingOptions: {
            enableHighAccuracy: true,
          },
          projection: this.olView.getProjection(),
          tracking: true,
        });
      }
      else {
        // Geolocation was already enabled. Go to current location of the map marker.
        this.centerOnLocation()
      }
      this.geoLocation.addEventListener('error', this.errorLocation);
      // If we dont have a position yet we set the event listener, else we'll just set the location
      this.geoLocation.addEventListener('change:position', this.setLocation)
      return this;
    },
    setLocation() {
      let coordinates = this.geoLocation.getPosition();
      if (coordinates) {
        const positionFeature = new Feature();
        positionFeature.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 6,
                fill: new Fill({
                  color: '#3399CC',
                }),
                stroke: new Stroke({
                  color: '#fff',
                  width: 2,
                }),
              }),
            })
        );
        positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
        this.sourceCenterPin.clear() // Remove previous center pins
        this.sourceCenterPin.addFeature(positionFeature); // Add center pin
        // Center camera on the geolocation when flag is true
        if (this.geolocationCenterOnLocation) {
          this.centerOnLocation()
        }

        // Removed this when Rutger wants automatic geolocation again.
        // this.removeEventListeners()

      }
    },
    centerOnLocation() {
      let coordinates = this.geoLocation.getPosition();
      if (coordinates) {
        this.olView.setCenter(coordinates);
        this.olView.setZoom(18);
      }
    },
    gotoCoordinates(coordinates) {
      // Go to the coordinates
      const coordinatesLonLat = fromLonLat([coordinates[0], coordinates[1]]);
      this.olView.setCenter(coordinatesLonLat);
      this.olView.setZoom(18);
    },
    selectFeatureById(feature_id) {
      // Check if user can select more features
      let featureMaxSelect = this.mapSettings.featuresMaxSelect ? this.mapSettings.featuresMaxSelect : 1
      // Get current amount of selected features
      let featuresSelected = this.selectedFeatures.length
      // Find and select the feature
      if (featuresSelected < featureMaxSelect) {
        const feature = this.source.getFeatureById(feature_id)
        this.selectFeature(feature);
      }
    },
    deselectFeatureById(feature_id) {
      // Find and deselect the feature
      const feature = this.source.getFeatureById(feature_id)
      this.deselectFeature(feature);
    },
    errorLocation() {
      this.geolocationHasError = "Locatie kon niet worden bepaald. Check of locatie in uw browser voor deze website is toegestaan.";
    },
    selectFeature(feature) {
      this.selectedFeatureExists = true; // Reset value after change in selection
      // Emit event to parent component
      feature.set('selected', true);
      this.$emit('selectFeature', feature)
    },
    deselectFeature(feature) {
      this.selectedFeatureExists = true; // Reset value after changed selection
      // Emit event to parent component
      feature.set('selected', undefined);
      this.$emit('deselectFeature', feature.getId());
    },
    refreshMap: debounce(function () {
      // Refresh map (needed when updating styles for example)
      this.source.changed()
    }, 100),
    recreateMapLayers: debounce(function () {
      // Remove map layers
      this.removeMapLayers()
      // Create new map layers
      this.createMapLayers();
    }, 1000),
    // this will parse the input data and add it to the map
    addFeaturesToSource(data) {
      let geojson;
      if (_.has(data, 'geojson') && !_.isEmpty(data.geojson)) {
        geojson = data.geojson;
      } else {
        geojson = data;
      }

      const source = this.source
      // Parse features from GeoJSON
      const features = new GeoJSON({
        featureProjection: 'EPSG:3857',
      }).readFeatures(geojson)
      // Add new features
      source.addFeatures(features);

      this.setPresetFeatures();
    },
    setPresetFeatures() {
      this.selectedFeatureExists = true;
      if (this.selectedFeatures && this.selectedFeatures.length > 0) {
        let $vm = this;
        this.selectedFeatures.forEach(function(selectedFeature) {
          let feature = $vm.source.getFeatureById(selectedFeature.id);

          // Writing a comment
          if (!feature) {
            $vm.selectedFeatureExists = false;
          }
          else if (feature && $vm.loadingStrategy === 'preload') {
            $vm.selectedFeatureExists = true;
          }
        });
      }
    },
    setStatusFilters(data) {
      if (this.showStatusFilters && _.has(data, 'status_values')) {
        this.statusFilterOptions = [];
        // Success
        data.status_values.forEach((element) => {
          if (element === '') {
            this.statusFilterOptions.push({ label: 'Geen status', value: '_none'})
            this.statusFilterValue.push('_none');

          }
          else {
            this.statusFilterOptions.push({ label: element, value: element})
            this.statusFilterValue.push(element);

          }
        });
      }

    },
    removeMapLayers() {
      // Remove features from layer
      this.source.clear();
    },
    async createMapLayers() {
      if (this.loadingStrategy === 'preload') {
        // Preload features
        this.preloadFeatures()
      }
      else if (this.loadingStrategy === 'live') {
        // Load features within the extent the first time
        this.loadFeaturesWithinExtent()
      }
    },
    preloadFeatures() {
      // Get datasource ID
      const datasourceID = this.datasourceID
      if (datasourceID) {
        this.loading = true

        // Fetch results form the API or from cache when offline
        if (this.appOnline) {
          axios
              .get('api/datasource/'+datasourceID)
              .then(response => {

                // Save result to cache
                store.dispatch('storeCache/cacheSet', {
                  type: 'datasource',
                  cid: 'datasource:'+datasourceID,
                  cache: response.data,
                });

                // Parse GeoJSON and replace source
                this.addFeaturesToSource(response.data)
                this.setStatusFilters(response.data);
                this.loading = false
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() =>
                  this.loading = false
              )
        }
        else {
          // User is offline, fetch from cache
          let cache = this.$store.getters['storeCache/cacheGet']('datasource', 'datasource:'+datasourceID)
          // Parse GeoJSON and replace source
          this.addFeaturesToSource(cache)
          this.loading = false
        }

      }
    },
    loadFeaturesWithinExtent() {
      // Make Vue 'this' accessible inside other functions
      let $vm = this;
      // Get datasource ID
      const datasourceID = $vm.datasourceID
      if (datasourceID) {
        $vm.loading = true

        let extent = $vm.olMap.getView().calculateExtent($vm.olMap.getSize())
        let extentLonLat = transformExtent(extent, 'EPSG:3857', 'EPSG:4326')
        let params = {
          left: extentLonLat[0],
          top: extentLonLat[1],
          right: extentLonLat[2],
          bottom: extentLonLat[3],
        }

        // // Check if there are any previous pending requests
        // if (typeof $vm.requestCancelToken != typeof undefined) {
        //   $vm.requestCancelToken.cancel("Operation canceled due to new request.")
        // }
        // //Save the cancel token for the current request
        // $vm.requestCancelToken = axios.CancelToken.source()

        // Fetch results form the API
        axios
            .get('api/datasource/' + datasourceID, {
              // cancelToken: $vm.requestCancelToken.token,
              params: params
            })
            .then(response => {
              if (response && response.data) {
                // Parse GeoJSON and replace source
                $vm.addFeaturesToSource(response.data)
                $vm.loading = false
              }
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() =>
                $vm.loading = false
            )
      }

    },
    removeEventListeners() {
      this.geoLocation.removeEventListener('error', this.errorLocation);
      this.geoLocation.removeEventListener('change:position', this.setLocation);
    },
  },
  beforeDestroy() {
    // Remove geolocation listener when component gets destroyed.
    this.removeEventListeners()
    // Remove other OL references
    this.source.clear()
    this.vectorLayer.setSource(null)
    this.sourceCenterPin.clear()
    this.vectorLayerCenterPin.setSource(null)
    this.clusterSource = null
    this.clustersLayer.setSource(null)
    this.geolocation = null
    this.olView = null
    this.featureStyleCache = {} // Feature styles
    this.clusterStyleCache = {} // Cluster styles,
    // Remove OL map to avoid memory leaks
    this.olMap.setTarget(null)
    this.olMap = null
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.status-filter-content {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 400px;

  .formulate-input[data-classification=box] {
    .formulate-input-element--checkbox {
      padding: 3px;
    }
    .has-label {
      label.formulate-input-label {
        margin: 0;
        padding: 0;
      }
    }
  }
}
</style>