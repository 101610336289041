export default {
    data () {
        return {
            modules_label: {
                "word": "Word",
                "map_element": "Kaart element",
                "location": "Locatie element",
                "tracking": "Track en trace element",
            },
        }
    },
};