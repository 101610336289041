<template>
  <div>
    <FormulateForm :debounce="100">
      <!-- Checkbox & radio component only (selectboxes excluded) -->
      <template v-if="(element.type === 'checkbox' || element.type === 'radio')">
        <FormulateInput
            :options="{horizontal: 'Horizontaal (knoppen)', vertical: 'Verticaal (lijst)'}"
            v-model="element.viewMode"
            type="radio"
            label="Hoe moeten de opties getoond worden?"
        />
      </template>

      <v-alert
          class="my-3"
          v-if="optionsHaveDuplicateValues"
          dense
          prominent
          type="warning"
      >
        Let op: De lijst met waardes bevat waardes die meerdere keren voorkomen met exact dezelfde waarde. Zorg ervoor dat elke waarde uniek is. <br><br> Gebruik bijvoorbeeld de waardes 0 en 0.0 en 0.00 als u vaker dan &eacute;&eacute;n keer de waarde 0 wilt gebruiken.
      </v-alert>

      <FormulateInput
          v-model="element.options"
          type="group"
          name="options"
          :repeatable="true"
          label="Lijst met waarden"
          add-label="+ Waarde toevoegen"
          validation="required"
          v-on:repeatableRemoved="updateElementOptions"
          v-on:repeatableAdded="addedElementOption"
          #default="{ index }"
      >
        <v-container>
          <v-row>
            <v-col>
              <FormulateInput
                  label="Label"
                  type="text"
                  name="label"
                  validation="bail|required"
                  error-behavior="live"
                  :disabled="element.options[index] && element.options[index].value === null"
              />
            </v-col>
            <v-col>
              <template v-if="element.options[index] && advancedEdit">
                <FormulateInput
                    v-if="advancedEdit"
                    label="Waarde"
                    help="Alleen kleine letters a-z, cijfers en underscore _ karakters zijn toegestaan."
                    type="text"
                    name="value"
                    validation="bail|required|matches:/^[a-z.0-9_]+$/"
                    error-behavior="live"
                    :disabled="element.options[index] && element.options[index].value === null"
                />
              </template>
              <template v-else>
                <!-- We need the 'value' as hidden input when 'editValueManual' is not checked. Because if it doesn't exists we lose the data in this field. -->
                <FormulateInput
                    type="hidden"
                    name="value"
                />
              </template>

            </v-col>
          </v-row>
        </v-container>

      </FormulateInput>
    </FormulateForm>
  </div>
</template>

<script>
import {cloneDeep} from 'lodash';

export default {
  name: 'EformElementEditBox',
  props: {
    element: Object,
    advancedEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    options: function () {
      // Get the options from the element
      return this.element.options
    },
    // Check if there are duplicate values in the element options
    optionsHaveDuplicateValues: function () {
      let values = this.element.options.map(option => option.value)
      return new Set(values).size !== values.length
    }
  },
  watch: {
    'element.options': {
      deep: true,
      handler(values) {
        // Loop over all options
        for (let key in values) {
          // Automatically change 'value' key when the label changes AND the element is still flagged as unsaved.
          if (values[key].unsaved && !this.advancedEdit) {
            // Skip the first empty 'null' value
            if (this.element.options[key].value === null) {
              continue;
            }
            // Skip when 'label' key is empty
            if (this.element.options[key].label === undefined) {
              continue;
            }
            // Sync the 'value' property with the 'label' property
            let value = this.element.options[key].label
                .toLowerCase()
                .replace(/\s/g, '_') // Replace spaces with underscores
                .replace(/[^\w]/g, "") // Remove all other characters that are not letters or numbers. \w is the same as [A-Za-z0-9_]
            // Get all other values without the current value
            let otherValues = cloneDeep(values)
            otherValues.splice(key,1);

            // Get a unique value
            value = this.getUniqueValue(otherValues, value)
            // Set value
            this.element.options[key].value = value
          }
        }
      }
    },
  },
  methods: {
    updateElementOptions() {

      // @TODO: Right now we only set the 'label' key with the FormulateInput[name=label], but we also need to set the 'id'?
      // { value: 'fedex_overnight', id: 'fedex_overnight', label: 'FedEx overnight' },
      // { value: 'fedex_ground', id: 'fedex_ground', label: 'FedEx ground' },
      // { value: 'usps', id: 'usps', label: 'US Postal Service' }

      // Set element options
      // this.element.options = this.formValues.list_of_values;
    },
    addedElementOption(values) {
      // Get last item in the array
      let addedItem = values.slice(-1)[0]
      // Set 'unsaved' flag
      addedItem.unsaved = true;
      addedItem.label = '';
      // Set 'unsaved' flag
      addedItem.value = ''; // Needs to be an empty string instead of 'null'. Because 'null' is reserved for the first empty item.

    },
    getUniqueValue(otherValues, value) {
      // Check if value exits in current list of values
      const isFound = this.valueExists(otherValues, value)

      if (isFound) {
        // Value already exists. Increment value number until we have a unique name.
        let valueNumber = 1
        // Check if incremented value exists in all values
        while (this.valueExists(otherValues, value+valueNumber)) {
          valueNumber++
        }
        return value+valueNumber
      }
      else {
        // Return the value unchanged.
        return value
      }
    },
    valueExists(values, value) {
      const isFound = values.some(element => {
        if (element.value === value) {
          return true;
        }
      });
      return isFound
    },
  }
}
</script>

<style lang="scss">
// Make the Vue Formulate repeatable group wider.
.formulate-input .formulate-input-element--group {
  max-width: 100% !important;
}
</style>
