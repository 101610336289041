<template>
  <div style="padding: 20px;">

    <div style="margin-top: 40px;">
      <h3><label for="cars">1. Werkt deze selectbox?</label></h3>
      <p>Het werkt goed als u een andere optie kan selecteren.</p>
      <select name="cars" id="cars">
        <option value="volvo">Volvo</option>
        <option value="saab">Saab</option>
        <option value="mercedes">Mercedes</option>
        <option value="audi">Audi</option>
      </select>
    </div>

    <div style="margin-top: 40px;">
      <h3><label for="myfile">2. Kunt u hier een bestand selecteren?</label></h3>
      <p>Het werkt goed wanneer u een bestand selectie venster krijgt.</p>
      <input type="file" id="myfile" name="myfile">
    </div>

    <div style="margin-top: 40px;">
      <h3>3. Werkt de "Selecteer bestand" knop?</h3>
      <p>Het werkt goed wanneer u een bestand selectie venster krijgt.</p>
      <input type="button" id="uploadButton" class="button" value="Selecteer bestand" />
    </div>

    <div style="margin-top: 40px;">
      <h3>4. Werkt één over meerdere punten niet? Klik op de "Reset pagina" knop en probeer het opnieuw.</h3>
      <p>U krijgt een kale pagina te zien met daarin 2 vragen. Nadat u op deze pagina komt moet u zelf de app helemaal afsluiten om terug te komen in de e-form app.</p>
      <input type="button" id="removeAllEvents" class="button button-reset" value="Reset pagina" />
    </div>

    <div style="display: none;">
      <div id="newDebuggingHTML">
        <div style="margin-top: 40px;">
          <h3><label for="cars">5. Werkt deze selectbox?</label></h3>
          <p>Het werkt goed als u een andere optie kan selecteren.</p>
          <select name="cars" id="cars">
            <option value="volvo">Volvo</option>
            <option value="saab">Saab</option>
            <option value="mercedes">Mercedes</option>
            <option value="audi">Audi</option>
          </select>
        </div>

        <div style="margin-top: 40px;">
          <h3><label for="myfile">6. Kunt u hier een bestand selecteren?</label></h3>
          <p>Het werkt goed wanneer u een bestand selectie venster krijgt.</p>
          <input type="file" id="myfile" name="myfile">
        </div>

        <div style="margin-top: 40px;">
          <h2>7. Bedankt voor uw tijd. Zou u de antwoorden op de 6 vragen willen e-mailen naar rutger@e-formapp.nl ? Dank u wel. </h2>
          <p>U moet nu de app volledig aflsluiten om terug te komen op de e-formapp</p>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  mounted() {
    const uploadButton = document.getElementById('uploadButton');
    const myfile = document.getElementById('myfile')

    uploadButton.addEventListener('click', () => {
      myfile.click();
    });

    const removeAllEventsButton = document.getElementById('removeAllEvents');
    const newHTML = document.getElementById('newDebuggingHTML').innerHTML;
    removeAllEventsButton.addEventListener('click', () => {

      document.write(newHTML);

    });

  }
}
</script>

<style scoped>
select {
  border: 1px solid black;
  -moz-appearance: revert;
  -webkit-appearance: revert;
}
.button {
  border-radius: 5px;
  background: green;
  color: white;
  padding: 10px 15px;
}
.button-reset {
  background: orange;
}
</style>