<template>
  <div
      :class="`formulate-input-element formulate-input-element--${context.class}`"
      :data-type="context.type"
  >

    <canvas v-if="renderSignature" class="signature-pad" :class="'size-'+this.signatureSize" ref="signaturePad"></canvas>

    <div>
      <v-btn v-if="signature" depressed color="secondary" outlined @click="clear" class="mt-2">Handtekening wissen</v-btn>
    </div>

  </div>
</template>

<script>
import SignaturePad from "signature_pad";
import _ from "lodash";

export default {
  props: {
    context: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      canvas: null,
      renderSignature: true,
      signature: '',
      signaturePad: {},
    }
  },
  mounted() {
    // Save canvas element
    this.canvas = this.$refs.signaturePad;
    // Create new signature pad
    this.createSignaturePad();
    // Load signature
    if (this.context.attributes.signatureLoadEnabled) {
      this.loadSignature();
    }
    else {
      this.context.model = ""; // Clear the value in the modal when we don't want to load the signature.
    }
  },
  watch: {
    signature () {
      // Save signature to the Vue Formulate values the same way as our offline Formulate Files are saved (as base64 encoded strings).
      // The backend will convert it to a real file.
      if (this.signature) {
        this.context.model = [
          [
            {
              filename: 'Handtekening.png',
              base64: this.signature
            }
          ]
        ];
      }
      else {
        this.context.model = "";
      }
    },
    'context.attributes.signatureSize': function (){
      // Remove the component from the DOM
      this.renderSignature = false;
      this.$nextTick(() => {
        this.resizeCanvas()
      });
      this.renderSignature = true;
    },
  },
  computed: {
    model () {
      return this.context.model
    },
    signatureSize () {
      if (this.context.attributes.signatureSize) {
        return this.context.attributes.signatureSize
      }
      return 'normal' // Fallback when not set in the database
    },
  },
  methods: {
    clear() {
      this.signaturePad.clear();
      this.signature = '';
    },
    resizeCanvas() {
      if (_.isEmpty(this.canvas)) {
        return;
      }
      // Load signature before resizing
      let base64 = this.signaturePad.toDataURL();
      // Resize canvas
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      this.canvas.width = this.canvas.offsetWidth * ratio;
      this.canvas.height = this.canvas.offsetHeight * ratio;
      this.canvas.getContext("2d").scale(ratio, ratio);
      this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
      // Reload the signature image
      this.signaturePad.fromDataURL(base64)
    },
    endStroke() {
      this.saveSignature();
    },
    async loadSignature() {
      if (_.isEmpty(this.context.model)) {
        return;
      }
      // Load from File URL. The value is saved as a file on send registrations
      if (_.has(this.context.model[0][0], 'url')) {
        // Convert file URL to base64
        let base64 = await this.convertImageToBase64Async(this.context.model[0][0].url)
        // Load signature
        await this.signaturePad.fromDataURL(base64);
        this.saveSignature();
      }
      // Load from base64. The value is saved as base64 on saved registrations
      else if (_.has(this.context.model[0][0], 'base64')) {
        this.signaturePad.fromDataURL(this.context.model[0][0].base64);
        this.signature = this.context.model[0][0].base64;
      }
    },
    saveSignature() {
      this.signature = this.signaturePad.toDataURL();
    },
    createSignaturePad() {
      // Init signature pad
      this.signaturePad = new SignaturePad(this.canvas);
      // Bind event listeners
      this.signaturePad.addEventListener("endStroke", this.endStroke);
      // Bind window resize listener
      window.addEventListener("resize", _.debounce(() => {
        this.resizeCanvas();
      }, 100));
      // Make sure the correct size is set
      this.resizeCanvas()
    },
    convertImageToBase64(imgUrl, callback) {
      const image = new Image();
      image.crossOrigin='anonymous';
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataUrl = canvas.toDataURL();
        callback && callback(dataUrl)
      }
      image.src = imgUrl;
    },
    convertImageToBase64Async(imgUrl) {
      return new Promise(resolve => this.convertImageToBase64(imgUrl, resolve))
   }
  },
  beforeDestroy() {
    this.signaturePad.clear();
    this.signaturePad.removeEventListener('endStroke', this.endStroke);
    window.removeEventListener('resize', this.resizeCanvas);
    this.renderSignature = false;
    this.signature = '';
    this.canvas = null;
  },
}
</script>

<style>
.signature-pad {
  background: rgb(255,255,255);
  height: 200px;
  width: 300px;
}
.size-big {
  height: 400px;
  width: 100%;
}
</style>