<template>
  <div :key="$route.params.datasource_id">

    <v-app-bar app dense height="48" color="white" elevate-on-scroll>
      <v-app-bar-title>{{ datasourceName }}</v-app-bar-title>
      <template v-slot:extension>
        <v-tabs centered grow show-arrows v-model="activeTab">
          <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route" exact>
            <v-icon left>{{ tab.icon }}</v-icon>
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <div class="pa-8">
      <div v-if="!loading">
        <router-view :datasource_id="datasource_id" :datasource_type="datasource_type"></router-view>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import userHelpers from "@/mixins/userHelpers";
import {mapGetters} from "vuex";
import normalize from "json-api-normalize";

export default {
  props: {
    datasource_type: null,
    datasource_id: null,
  },
  mixins: [
    userHelpers,
  ],
  data() {
    return {
      loading: true,
      datasource: {},
      activeTab: 1,
    };
  },
  computed: {
    ...mapGetters({User: "StateUser"}),
    tabs() {
      const tabs = [
        { id: 1, name: "Bekijk", icon: "mdi-eye", iconColor: 'green', route: {name: 'datasource_view'} }
      ];

      tabs.push({ id: 2, name: "Aanpassen", icon: "mdi-pencil", iconColor: 'deep-purple', route: {name: 'datasource_edit'} });

      tabs.push({ id: 3, name: "Verwijderen", icon: "mdi-trash-can", iconColor: 'deep-purple', route: {name: 'datasource_remove'} });

      return tabs;
    },
    datasourceName() {
      if (this.datasource) {
        return this.datasource.name ? this.datasource.name : ''
      }
      else {
        return 'Laden..'
      }
    }
  },
  mounted () {
    // Get form from API
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi () {
      this.loading = true
      // Fetch results form the API
      axios
          .get('jsonapi/eform_datasource/'+this.datasource_type+'/'+this.datasource_id)
          .then(response => {
            // Add normalized datasource
            this.datasource = normalize(response.data).get([
              'name',
            ])
            this.loading = false;
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
  }
};
</script>
