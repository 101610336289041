<template>
  <div>
    <FormulateInput
        v-model="element.signatureSize"
        :options="{normal: 'Normaal', big: 'Groot'}"
        type="radio"
        label="Handtekening grootte"
    />
    <FormulateInput
        v-model="element.signatureLoadEnabled"
        type="checkbox"
        label="Opgeslagen handtekening inladen"
        help="Aanvinken als u de handtekening opnieuw wilt inladen wanneer u verder gaat met een opgeslagen registratie, of wanneer een bestaande registratie wordt ingeladen via 'Registraties doorzoeken'."
    />
  </div>
</template>

<script>
export default {
  name: 'EformElementEditSignature',
  props: {
    element: Object,
  },
}
</script>

<style lang="scss" scoped>

</style>
