<template>
  <v-sheet class="ma-8 mt-2 pa-4 rounded-lg">
    <v-card-text>

      <v-alert
          type="error"
          v-bind:key="key"
          v-for="(errorMessage, key) in errorMessages"
      >
        {{ errorMessage.detail }}
      </v-alert>

      <FormulateForm #default="{ isLoading }" @submit="submitForm">
        <FormulateInput
            label="Databron naam"
            name="name"
            v-model="name"
            validation="required"
        />
        <FormulateInput
            v-if="datasource_type === 'map_features'"
            v-model="field_crs"
            :options="field_crs_label"
            type="radio"
            validation="required"
            label="Coordinaten systeem"
        />
        <datasource-upload-help :datasource_type="datasource_type" :field_crs="field_crs"></datasource-upload-help>
        <FormulateInput
            type="file"
            name="file"
            v-model="file"
            label="CSV bestand"
            help="Upload een .csv bestand met de databron."
            validation="required|mime:text/csv"
            upload-url="/api/file-upload/datasource_source"
            :validation-messages="{
                              'mime': 'Er kunnen alleen CSV (.csv) bestanden worden geupload'
                            }"
        />

        <FormulateInput
            input-class="v-btn v-size--default"
            type="submit"
            name="submit"
            :disabled="isLoading"
            :label="isLoading ? 'Laden...' : 'Databron aanmaken'"
        />
      </FormulateForm>

    </v-card-text>
  </v-sheet>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "@/plugins/axios";
import router from "@/plugins/routes";
import datasourceEntityHelpers from "@/mixins/datasourceEntityHelpers";
import DatasourceUploadHelp from "@/components/DatasourceUploadHelp";

export default {
  components: {
    DatasourceUploadHelp,
  },
  mixins: [
    datasourceEntityHelpers,
  ],
  props: [
    "datasource_type"
  ],
  data () {
    return {
      errorMessages: [],
      isLoading: false,
      name: '',
      field_crs: 'EPSG:4326',
      type: '',
      file: null,
    }
  },
  computed: {
    ...mapGetters({CurrentClient: "CurrentClient"}),
  },
  methods: {
    // Send the form to the API endpoint
    async submitForm(formValues) {
      // Get uploaded file
      let file_uuid = formValues.file[0][0].uuid

      this.isLoading = true;


      let config = {
        headers: {
          "Accept": "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        }
      }
      let payload = {
        data: {
          type: 'eform_datasource--'+this.datasource_type,
          attributes: {
            name: this.name,
          },
          relationships: {
            file: {
              data: {
                type: 'file--file',
                id: file_uuid
              }
            },
            client_id: {
              data: {
                type: 'client--client',
                id: this.$store.getters.CurrentClientUUID
              }
            },
          }
        },
      }

      // Add map features data
      if (this.datasource_type === 'map_features') {
        payload.data.attributes.field_crs = this.field_crs
      }

      axios.post('jsonapi/eform_datasource/'+this.datasource_type, payload, config)
      .then(response => {
        this.loading = false
        this.$toast.success("Databron " + this.name + " is aangemaakt.");
        router.push({ name: 'datasource_view', params: {datasource_id: response.data.data.id} })
      })
      .catch(error => {
        if (error.response && error.response.data) {
          // Show error message
          if (error.response.data.errors) {
            // Add error messages
            this.errorMessages = error.response.data.errors;
          }
        }
        this.errored = true
      })
      .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>