<template>
  <div
      :class="`formulate-input-element formulate-input-element--${context.type}`"
      :data-type="context.type"
  >
  <input type="text" inputmode="decimal" :name="context.name" v-model="model" :id="context.id" />
  </div>
</template>

<script>

export default {
  props: {
    context: {
      type: Object,
      required: true
    },
  },
  computed: {
    model: {
      get() {
        return this.context.model;
      },
      set(value) {
        this.context.model = value.replace(',', '.');
      },
    },
  },
}
</script>

<style lang="scss" scoped>

</style>