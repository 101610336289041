import { render, staticRenderFns } from "./ClientCreditsBuy.vue?vue&type=template&id=0812c1bb&scoped=true&"
import script from "./ClientCreditsBuy.vue?vue&type=script&lang=js&"
export * from "./ClientCreditsBuy.vue?vue&type=script&lang=js&"
import style0 from "./ClientCreditsBuy.vue?vue&type=style&index=0&id=0812c1bb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0812c1bb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VAlert,VCard,VCardText,VCol,VRow,VSheet,VSkeletonLoader})
