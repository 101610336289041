<template>
  <div
      :class="`formulate-input-element formulate-input-element--${context.type} calculation-wrapper`"
      :data-type="context.type"
  >
    <input readonly type="text" :name="context.name" v-model="model" :id="context.id" />

    <v-alert
        color="secondary"
        dense
        text
        v-if="emptyTokens.length"
        class="empty-tokens-alert ma-2">

      Berekening wordt nog niet uitgevoerd omdat niet alle velden zijn ingevuld.

    </v-alert>

  </div>
</template>

<script>
import {isNumber} from "lodash";
import eformHelpers from "@/mixins/eformHelpers";
import floatingPoint from 'js-floating-point';

export default {
  mixins: [
    eformHelpers,
  ],
  props: {
    context: {
      type: Object,
      required: true
    },
    values: {
      type: Object,
      default: () => ({}), // Default value is an empty object
    },
    schema: {
      type: Array,
      default: () => ([]), // Default value is an empty array
    },
  },
  watch: {
    values() {
      let result = ''

      if (this.emptyTokens.length) {
        result = '';
      }
      else {
        result = this.calculateResult();
        result = this.handleNaN(result);
        result = this.roundResult(result);
      }

      this.updateModel(result);
    }
  },
  computed: {
    exp() {
      // Get the user defined formula
      const formulaWithTokens = this.context.attributes.formula
      // Replace the tokens with the values (numbers)
      let formula = this.replaceTokens(formulaWithTokens, this.getTokensFromSchema(this.schema), this.values);
      // const formula = formulaWithTokens.replace('$number1$', this.values['number1'])

      // Allowed characters: 0-9 + - * / ( ) .
      const allowed = /[^\d*/+.()-]/g
      // More allowed characters: **
      const pow = /(\d+)\*\*(\d+)/g
      return formula
          .replace(',', '.') // Convert all comma's to decimal points
          .replace(allowed, ' ')
          .replace(pow, 'Math.pow($1, $2)')
    },
    model() {
      return this.context.model
    },
    emptyTokens() {
      return this.getTokensWithEmptyValues(this.context.attributes.formula)
    },
  },
  methods: {
    calculateResult() {
      let result = '';
      try {
        result = eval(this.exp);
      } catch (e) {
        console.log(e.message);
        result = 'Fout';
      }
      return result;
    },
    handleNaN(result) {
      if (isNaN(result) || isNumber(result) === false) {
        result = 'Fout';
      }
      return result;
    },
    roundResult(result) {
      if (isNumber(result)) {
        // Fix js floating rounding
        result = floatingPoint(result);
        // Round to 2 after the decimal, and ensure things like 1.005 round correctly,
        result = Math.round((result + Number.EPSILON) * 100) / 100;
      }
      return result;
    },
    updateModel(result) {
      // Update value only when it's different. Otherwise, we end up in an endless loop.
      if (result !== this.context.model) {
        // Set the value
        this.context.model = result;
      }
    },
    /**
     * Returns an array with objects containing all tokens found in a string.
     *
     * @param str
     * @returns {*|*[]}
     */
    findAllTokens(str) {
      // The regular expression /\$[\w._]+\$/g matches any word that starts and ends with a $ character
      // and includes alphanumeric characters, dots, and underscores in between.
      const regex = /\$[\w._]+\$/g;
      // The match() method is used to get all matches in the string.
      const matches = str.match(regex);
      return matches || [];
    },
    /**
     * Returns an array with objects containing all tokens found in a string,
     * with their corresponding values replaced.
     *
     * @param str - The string to find tokens in.
     * @returns {Array} - An array of objects, where each object represents a token.
     *
     * @example
     * Input: "$token1$ some text $token2$"
     * Output: [
     *   { token: "$token1$", value: "replaced_value_for_token1" },
     *   { token: "$token2$", value: "" }
     * ]
     */
    findAllTokensWithReplacedValues(str) {
      const tokens = this.findAllTokens(str)
      // Check if there are any tokens
      if (!tokens) {
        return [];
      }
      else {
        // Replace all tokens with their values
        return tokens.map(token => {
          return {
            token: token,
            value: this.replaceTokens(token, this.getTokensFromSchema(this.schema), this.values),
          };
        });
      }
    },
    /**
     * Finds all tokens in a string that have empty values after the tokens have been replaced.
     *
     * @param str
     * @returns {*[]}
     */
    getTokensWithEmptyValues(str) {
      const tokens = this.findAllTokensWithReplacedValues(str);
      // Check if there are any tokens
      if (!tokens) {
        return [];
      }
      else {
        // Find all tokens that have an empty value
        return tokens.filter(token => {
          return token.value === '';
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.calculation-wrapper {
  overflow: hidden;
}
.empty-tokens-alert {
  font-size: 0.8em;

  ul {
    display: inline;
    margin: 0;
    padding: 0;

    li {
      display: inline;
    }
  }
}
</style>