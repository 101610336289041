<template>
  <div>
    <page-title :page-title="'Inhoud van ' + folder_name"></page-title>

    <div class="text-center text-lg-left">
      <div class="pl-md-8 mb-3" @click="$router.back()">
        <v-btn small outlined color="secondary">
          <v-icon left>mdi-arrow-left</v-icon> Terug
        </v-btn>
      </div>
    </div>

    <v-btn
        type="submit"
        depressed
        color="primary"
        class="ma-md-8 mt-md-2 ma-4"
        :to="{name: 'form_create'}"
        v-if="userHasPermission('eform manage forms')"
    >
      <v-icon left>mdi-plus</v-icon>
      Formulier toevoegen
    </v-btn>

    <v-data-table
        :headers="headers"
        :items="this.forms"
        mobile-breakpoint="0"
        class="mx-md-8 rounded-lg"
        :sort-by="['form_name']"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
        @click:row="rowClick"
    >
      <template #item.form_name="{item}">
        <v-icon left>mdi-text-box-outline</v-icon>
        {{ item.form_name }}
        <v-chip v-if="item.published_at" x-small color="primary">Gepubliceerd</v-chip>
        <v-chip v-else x-small outlined color="secondary">Niet gepubliceerd</v-chip>
      </template>
      <template #item.actions>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "@/plugins/axios";
import PageTitle from "@/components/Pagetitle";
import userHelpers from "@/mixins/userHelpers";

export default {
  name: "ListFormsAdmin",
  components: {
    PageTitle,
  },
  mixins: [
    userHelpers,
  ],
  computed: {
    ...mapGetters({getFolderByID: "getFolderByID"}),
  },
  data () {
    return {
      folder_id: this.$route.params.folder_id,
      folder_name: 'Mapje',
      options: {
        itemsPerPage: -1,
      },
      forms: [],
      headers: [
        // { text: 'ID', value: 'form_id' },
        {
          text: 'Formulier naam',
          align: 'start',
          sortable: true,
          value: 'form_name',
        },
        { value: 'form_published', sortable: true},
        { value: "actions", sortable: false, align: 'end' }
      ],
    }
  },
  mounted () {
    let folder = this.getFolderByID(this.folder_id);
    if (folder) {
      this.folder_name = folder.folder_name;
    }

    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {

      try {
        // https://eform-drupal.local/api/folder/fc15de01-66ea-4119-9f7c-bbd9610e0797/forms/user
        let response = await axios.get('api/folder/'+ this.folder_id + '/forms');
        let forms = response.data;
        let formattedForms = [];

        for(var i = 0; i < forms.length; i++) {
          formattedForms[i] = forms[i].form;
          if (Array.isArray(forms[i].form.values)) {
            formattedForms[i].values = Object.fromEntries(forms[i].form.values);
          }
        }
        this.forms = formattedForms;
      } catch (error) {
        console.log(error);
      }
    },
    rowClick(item) {
      this.$router.push({ name: 'form_view', params: {form_id: item.form_id} })
    },
  },
}
</script>

<style scoped>

</style>