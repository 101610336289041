export default {
    methods: {
        convertComplexFileData(fileValues) {
            // Check if file value is complex data.
            if (typeof fileValues === 'object' && fileValues !== null && fileValues.files && fileValues.files.length && fileValues.files[0].path) {
                // Convert the complex data to simple array format
                let fileObject = {}
                fileObject.name = fileValues.files[0].path[0].name
                fileObject.url = fileValues.files[0].path[0].url
                if (fileValues.files[0].path[0].id) {
                    fileObject.id = fileValues.files[0].path[0].id
                }
                // Create simple data array
                let fileValuesSimple = [];
                fileValuesSimple.push([fileObject])
                // Return simple data
                return fileValuesSimple
            }
            else {
                // When this bug is fixed we can probably just return the simple data to make it work as intended.
                return fileValues
            }
        },
    }
};