<template>
  <div>

    <v-row class="mb-2">
      <v-col>
        <FormulateInput
            type="text"
            label="Formule"
            validation="required"
            help="Vul hier uw formule in. U kunt tokens, nummers en wiskundige symbolen gebruiken. Voor en na de wiskundige symbolen zet u een spatie. Voor decimale getallen kunt u een '.' gebruiken."
            v-model="formulaSanitized" />
      </v-col>

      <v-col>
        <p>Lijst met wiskundige symbolen die kunt u gebruiken:</p>

        <v-chip small class="ma-1">
          <v-icon small left>mdi-plus</v-icon>
          optellen
        </v-chip>

        <v-chip small class="ma-1">
          <v-icon small left>mdi-minus</v-icon>
          aftrekken
        </v-chip>

        <v-chip small class="ma-1">
          <v-icon small left>mdi-asterisk</v-icon>
          vermenigvulden
        </v-chip>

        <v-chip small class="ma-1">
          <v-icon small left>mdi-slash-forward</v-icon>
          delen
        </v-chip>

        <v-chip small class="ma-1">
          <v-icon small left>mdi-asterisk</v-icon>
          <v-icon small left>mdi-asterisk</v-icon>
          machtsverheffen
        </v-chip>

        <v-chip small class="ma-1">
          <v-icon small style="font-style: normal;">(</v-icon> &nbsp;
          <v-icon small style="font-style: normal;">)</v-icon>&nbsp;groeperen
        </v-chip>

      </v-col>
    </v-row>

    <eform-tokens :tokens="allowedTokens"></eform-tokens>

  </div>
</template>

<script>
import eformHelpers from "@/mixins/eformHelpers";
import EformTokens from "@/components/EformTokens";

export default {
  components: {
    EformTokens,
  },
  mixins: [
    eformHelpers,
  ],
  props: {
    element: Object,
    schema: Array,
  },
  computed: {
    formulaSanitized: {
      get() {
        return this.element.formula;
      },
      set(value) {
        this.element.formula = value.replace(',', '.');
      },
    },
    allowedTokens() {
      // Get tokens from the schema
      let tokens = this.getTokensFromSchemaFilterInclude(this.schema, ['number', 'radio', 'select', 'subform_metadata', 'calculation']);
      // Filter out the current calculation element
      tokens = tokens.filter(token => token.token !== this.element.name);
      return tokens;
    },
  },
}
</script>

<style lang="scss">

</style>
