<template>
  <div>

    <FormulateForm :debounce="100">
      <template v-if="datasources && datasources.length >= 1">
        <FormulateInput
            type="select"
            name="datasourceId"
            v-model="element.searchSettings.datasourceId"
            label="Databron"
            validation="required"
            :options="datasources"
        />
      </template>
      <template v-else>
        <div class="formulate-input">
          <label class="formulate-input-label">Er zijn nog geen databronnen beschikbaar voor deze klant</label>
          <div class="formulate-input-help formulate-input-help--after">Maak eerst een nieuwe databron aan of controleer of de aangemaakte databron wel aan de huidige klant gekoppeld is.</div>
        </div>
      </template>


      <FormulateInput
          type="select"
          name="maxValues"
          v-model="element.searchSettings.maxValues"
          :options="{1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6', 7: '7', 8: '8', 9: '9', 10: '10'}"
          label="Maximaal aantal te selecteren waardes"
      />

      <FormulateInput
          type="group"
          name="itemStyles"
          v-model="element.searchSettings.itemStyles"
          help="Hiermee kunt u een kleur geven aan de items."
          :repeatable="true"
          label="Item stijlen"
          add-label="+ Stijl toevoegen"
      >
        <v-row>
          <v-col>
            <FormulateInput
                label="Bevat tekst"
                type="text"
                name="text"
                validation="bail|required"
                help="Geef het item deze kleur wanneer deze tekst gevonden is in één van de kolommen van de databron. De tekst is niet hoofdlettergevoelig."
                error-behavior="live"
            />
          </v-col>
          <v-col>
            <FormulateInput
                type="color"
                name="color"
                label="Kleur"
                validation="required"
                value="#3eaf7c"
                error-behavior="live"
            />
          </v-col>
        </v-row>

      </FormulateInput>

      <FormulateInput
          type="radio"
          v-model="element.searchSettings.itemAction"
          :options="{'doNothing': 'Behoud item', 'setHidden': 'Verberg item'}"
          label="Actie uitvoeren op geselecteerde items"
          help="Welke actie moet er uitgevoerd op het geselecteerde item nadat deze geregistreerd is?"
      />

    </FormulateForm>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'EformElementEditSearch',
  props: {
    element: Object,
  },
  data: () => ({
    datasources: [],
  }),
  mounted () {
    // Get items
    this.getDatasourcesFromApi()
  },
  methods: {
    getDatasourcesFromApi () {
      let client_id = this.$store.getters.CurrentClient;
      // Fetch results form the API
      axios
          .get('api/datasource/list/search/'+ client_id)
          .then(response => {
            // Loop over results and format in into a options array
            let options = []
            // Format datasource list into select options
            for (let item of response.data) {
              options.push({
                value: item.id,
                label: item.name
              })
            }
            // Set datasources
            this.datasources = options
          })
          .catch(error => {
            console.log(error)
          })
    },
  }
}
</script>

<style lang="scss">
// Make the Vue Formulate repeatable group wider.
.formulate-input .formulate-input-element--group {
  max-width: 100% !important;
}
</style>
