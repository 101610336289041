var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"pa-4 rounded-lg mb-4"},[_c('h3',[_vm._v("Toegang beheren")]),_c('v-btn',{staticClass:"mt-3 mr-3",attrs:{"depressed":"","color":"primary","width":"150"},on:{"click":_vm.showAddUserModal}},[_c('v-icon',[_vm._v("mdi-account")]),_vm._v(" Gebruikers ")],1),_c('v-btn',{staticClass:"mt-3",attrs:{"depressed":"","color":"primary","width":"150"},on:{"click":_vm.showAddUserGroupModal}},[_c('v-icon',[_vm._v("mdi-account-multiple")]),_vm._v(" Groepen ")],1)],1),_c('v-dialog',{attrs:{"max-width":"800","scrollable":""},on:{"click:outside":_vm.closeAddUserModal},model:{value:(_vm.addUserModal),callback:function ($$v) {_vm.addUserModal=$$v},expression:"addUserModal"}},[_c('v-card',[_c('v-card-text',[_c('v-tabs',{attrs:{"grow":""}},[_c('v-tab',[_vm._v(" Gebruikers beheren ")]),_c('v-tab',[_vm._v(" Gebruikers toevoegen ")]),_c('v-tab-item',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.addUserTableHeaders,"items":_vm.formUsers},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","loading":_vm.removeLoading,"disabled":_vm.removeLoading,"color":"error","icon":""},on:{"click":function($event){return _vm.removeUserFromForm(item.user_id)}}},[_c('v-icon',[_vm._v("mdi-account-remove")])],1)]}}])},[_c('template',{slot:"no-data"},[_c('div',[_vm._v(" Er zijn nog geen gebruikers toegevoegd ")])])],2)],1)],1),_c('v-tab-item',[[_c('v-card',[[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.addUserTableSearch),callback:function ($$v) {_vm.addUserTableSearch=$$v},expression:"addUserTableSearch"}})],1),_c('v-data-table',{attrs:{"headers":_vm.addUserTableHeaders,"items":_vm.addUserTableItems,"search":_vm.addUserTableSearch},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","loading":_vm.addUserLoading,"disabled":_vm.addUserLoading,"color":"primary","icon":""},on:{"click":function($event){return _vm.addUserToForm(item.user_id)}}},[_c('v-icon',[_vm._v("mdi-account-plus")])],1)]}}])},[_c('template',{slot:"no-data"},[_c('div',[_vm._v(" Er zijn geen gebruikers beschikbaar die kunnen worden toegevoegd ")])])],2)],1)]],2)]],2)],1)],1),_c('v-card-actions',{staticClass:"background-color"},[_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":_vm.closeAddUserModal}},[_vm._v(" Sluit ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"800","scrollable":""},on:{"click:outside":_vm.closeAddUserGroupModal},model:{value:(_vm.addUserGroupModal),callback:function ($$v) {_vm.addUserGroupModal=$$v},expression:"addUserGroupModal"}},[_c('v-card',[_c('v-card-text',[_c('v-tabs',{attrs:{"grow":""}},[_c('v-tab',[_vm._v(" Groepen beheren ")]),_c('v-tab',[_vm._v(" Groepen toevoegen ")]),_c('v-tab-item',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.addUserGroupTableHeaders,"items":_vm.formUserGroups},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","loading":_vm.removeLoading,"disabled":_vm.removeLoading,"color":"error","icon":""},on:{"click":function($event){return _vm.removeUserGroupFromForm(item.group_id)}}},[_c('v-icon',[_vm._v("mdi-account-multiple-remove")])],1)]}}])},[_c('template',{slot:"no-data"},[_c('div',[_vm._v(" Er zijn nog geen groepen toegevoegd ")])])],2)],1)],1),_c('v-tab-item',[[_c('v-card',[[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.addUserGroupTableSearch),callback:function ($$v) {_vm.addUserGroupTableSearch=$$v},expression:"addUserGroupTableSearch"}})],1),_c('v-data-table',{attrs:{"headers":_vm.addUserGroupTableHeaders,"items":_vm.addUserGroupTableItems,"search":_vm.addUserGroupTableSearch},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","loading":_vm.addUserGroupLoading,"disabled":_vm.addUserGroupLoading,"color":"primary","icon":""},on:{"click":function($event){return _vm.addUserGroupToForm(item.group_id)}}},[_c('v-icon',[_vm._v("mdi-account-multiple-plus")])],1)]}}])},[_c('template',{slot:"no-data"},[_c('div',[_vm._v(" Er zijn geen groepen beschikbaar die kunnen worden toegevoegd ")])])],2)],1)]],2)]],2)],1)],1),_c('v-card-actions',{staticClass:"background-color"},[_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":_vm.closeAddUserGroupModal}},[_vm._v(" Sluit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }