<template>
  <div :key="$route.params.form_id">

    <v-app-bar app dense height="48" color="white" elevate-on-scroll>
      <v-spacer v-if="$vuetify.breakpoint.mobile"></v-spacer>
      <v-app-bar-title>{{ formName }}</v-app-bar-title>
      <v-spacer v-if="$vuetify.breakpoint.mobile"></v-spacer>
      <template v-slot:extension>
        <v-tabs centered grow show-arrows v-model="activeTab">
          <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route" exact>
            <v-icon left>{{ tab.icon }}</v-icon>
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <div class="pa-8">
      <div v-if="!loading">

        <v-alert
            class="mb-8"
            v-if="wordIsProcessing"
            dense
            prominent
            color="secondary"
            type="warning"
        >
          Uw Word document wordt verwerkt. <v-progress-circular indeterminate color="primary"
        ></v-progress-circular>
          <br>
          <span class="caption">Het formulier kan niet worden ingevuld totdat dit klaar is.</span>
        </v-alert>

        <router-view :myForm="myForm" @updateForm="updateForm($event)" @updateMetadata="getFormMetadata"></router-view>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import userHelpers from "@/mixins/userHelpers";
import _ from "lodash";

export default {
  props: ["form_id"],
  mixins: [
    userHelpers,
  ],
  data() {
    return {
      loading: true,
      myForm: null,
      myFormMetadata: null,
      myFormMetadataPolling: null,
      activeTab: 1,
    };
  },
  computed: {
    tabs() {
      const tabs = [
        { id: 1, name: "Bekijk", icon: "mdi-eye", iconColor: 'green', route: {name: 'form_view'} }
      ];


      if (this.userHasPermission('eform manage form edit')) {
        tabs.push({ id: 2, name: "Aanpassen", icon: "mdi-pencil", iconColor: 'deep-purple', route: {name: 'form_edit'} });
      }
      if (this.userHasPermission('eform manage form emails')) {
        tabs.push({ id: 3, name: "E-mails", icon: "mdi-email-outline", iconColor: 'light-blue', route: {name: 'form_emails'} });

      }
      if (this.userHasPermission('eform manage form conditions')) {
        tabs.push({ id: 4, name: "Regels", icon: "mdi-toggle-switch", iconColor: 'amber', route: {name: 'form_rules'} });
      }
      if(this.userHasPermission('eform manage form integrations')) {
        tabs.push({ id: 5, name: "Integraties", icon: "mdi-webhook", iconColor: 'grey', route: {name: 'form_integrations'} });
      }
      if(this.userHasPermission('eform manage form registrations')) {
        tabs.push({ id: 6, name: "Registraties", icon: "mdi-table", iconColor: 'pink', route: {name: 'form_registrations'} });
      }

      return tabs;
    },
    formName() {
      if (this.myForm) {
        return this.myForm.form_name ? this.myForm.form_name : ''
      }
      else {
        return 'Laden..'
      }
    },
    /**
     * Checks if there is any Word file in the "email_settings" that is not processed yet.
     *
     * @returns {boolean}
     */
    wordIsProcessing() {
      if (this.myFormMetadata) {
        if (_.isObject(this.myFormMetadata.email_word_files_processed)) {
          for (let property in this.myFormMetadata.email_word_files_processed) {
            if (this.myFormMetadata.email_word_files_processed[property] === false) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
  mounted () {
    // Get form from API
    this.getDataFromApi()
    // Load form metadata
    this.getFormMetadata()
  },
  created: function() {
    this.pollFormMetadata()
  },
  beforeDestroy () {
    clearInterval(this.myFormMetadataPolling)
  },
  methods: {
    getDataFromApi () {
      this.loading = true
      // Fetch results form the API
      axios
          .get('api/form/'+this.form_id)
          .then(response => {
            this.updateForm(response.data);
            this.loading = false;
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    getFormMetadata () {
      // Fetch results form the API
      axios
          .get('api/form/'+this.form_id+'/metadata')
          .then(response => {
            this.myFormMetadata = response.data;
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
    },
    updateForm(form) {
      this.myForm = this.formSetDefaultValues(form);
    },
    formSetDefaultValues(form) {
      if (Array.isArray(form.values)) {
        form.values = Object.fromEntries(form.values);
      }

      for (let i in form.schema) {
        if (form.schema[i].elementType === 'subform') {
          let subformDefaults = this.formSetDefaultValues(form.schema[i].subform, true);
          subformDefaults.defaultValues = subformDefaults.values;
          form.schema[i].subform = subformDefaults;
          form.defaultValues = form.values;
          continue;
        }

        let dateTypeArray = ['date', 'time', 'datetime'];
        if (dateTypeArray.includes(form.schema[i].elementType) && form.schema[i].defaultDateNow) {
          switch (form.schema[i].elementType) {
            case 'date':
              form.values[form.schema[i].name] = moment().format("YYYY-MM-DD");
              break;
            case 'time':
              form.values[form.schema[i].name] = moment().format("HH:mm");

              break;
            case 'datetime':
              form.values[form.schema[i].name] = moment().format("yyyy-MM-DDTHH:mm");

              break;
          }
        }
      }
      return form;
    },
    pollFormMetadata () {
      this.myFormMetadataPolling = setInterval(() => {
        if (this.wordIsProcessing) {
          // Reload form metadata
          this.getFormMetadata()
        }
      }, 10000)
    }
  }
};
</script>
