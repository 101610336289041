<template>
  <div>

    <v-btn
        type="submit"
        depressed
        color="primary"
        class="mb-6"
        router :to="{ name: 'client_user_group_add'}"
        v-if="userHasPermission('create user group')"
    >
      <v-icon left>mdi-plus</v-icon>
      Groep toevoegen
    </v-btn>

    <v-data-table
        :headers="headers"
        :items="this.groups"
        mobile-breakpoint="0"
        class="rounded-lg"
        :loading="isLoading"
        :sort-by.sync="currentSort"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
        @click:row="rowClick"
    >
      <template #item.actions="{item}">
        <v-btn class="ml-2" outlined small color="red" @click.stop="removeGroupConfirm(item)" :disabled="isSaving">
          <v-icon
              depressed
              role="button"
              left
          >mdi-trash-can</v-icon>
          Verwijderen
        </v-btn>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import axios from "@/plugins/axios";
import normalize from "json-api-normalize";
import userHelpers from "@/mixins/userHelpers";
import loader from "@/mixins/loader";

export default {
  mixins: [
    userHelpers,
    loader,
  ],
  props: {
    client_id: null,
  },
  data () {
    return {
      folderTableOptions: {
        itemsPerPage: -1,
      },
      currentSort: ['label'],
      options: {
        itemsPerPage: -1,
      },
      groups: [],
      headers: [
        {
          text: 'Groep',
          align: 'start',
          sortable: true,
          value: 'label',
        },
        { value: "actions", sortable: false, align: 'end' }
      ],
    }
  },
  async mounted () {
    await this.getDataFromApi()
    this.hideLoader()
  },
  methods: {
    async getDataFromApi() {
      this.showLoader()
      try {
        // Get all user groups that belong to this client
        let config = {
          params: {
            'filter[client-filter][path]': 'client.id',
            'filter[client-filter][operator]': '=',
            'filter[client-filter][value]': this.client_id,
          }
        }
        // Fetch user groups
        let response = await axios.get('jsonapi/user_group/user_group', config);

        // Normalize the entities
        this.groups = normalize(response.data).get([
          'label',
          'id',
        ])

      } catch (error) {
        console.log(error);
      }
    },
    rowClick(item) {
      // Get bundle
      this.$router.push({ name: 'client_user_group_edit', params: {client_id: this.client_id, group_id: item.id} })
    },
    async removeGroupConfirm(group) {
      if (
          confirm('Weet je zeker dat je de groep "'+group.label+'" wilt verwijderen?')
      ) {
        await this.removeGroup(group);
      }
    },
    async removeGroup(group) {
      this.showSaving()
      // Delete the user group
      try {
        await axios.delete('jsonapi/user_group/user_group/'+group.id)
        this.$toast.success("Groep is verwijderd.");
        await this.getDataFromApi(); // Reload data in the table
        this.hideLoader()
      }
      catch (error) {
        console.log(error)
        this.$toast.error("Groep kon niet worden verwijderd.");
      }
      this.hideSaving()
    },
  },
}
</script>

<style scoped>

</style>