export default {
    methods: {
        featureStyle(mapSettings, feature) {
            return this.featureStyleProps(mapSettings, feature.get('status'), feature.get('invisible'));
        },
        featureStyleProps(mapSettings, featureStatus = undefined, featureInvisible = undefined) {
            let style = '#41b883'; // The default style

            // Status is not set, return default color
            if (!featureStatus) {
                return style;
            }
            // Find the status in the mapSettings feature styles
            let featureStyle = mapSettings.featureStyles.find(obj => {
                return obj.status === featureStatus
            })
            if (featureStyle) {
                // Return feature color as found in the mapSettings
                style = featureStyle.color
            }

            if (!featureInvisible) {
                // Return feature style
                return style;
            }
        }
    }
};