<template>
  <div
      :class="`formulate-input-element formulate-input-element--${context.type}`"
      :data-type="context.type"
  >

    <gps-tracking-map :map-style="'width: 100%; height: 50vh;'" :tracking-enabled="trackingActive" :tracked-locations="context.model" :map-settings="context.attributes.mapSettings" @changedLocation="changedLocation($event)"></gps-tracking-map>


    <v-switch :disabled="isAdminMode" v-model="trackingEnabled">
      <template v-slot:label>
        <template v-if="trackingEnabled">Actief</template>
        <template v-else>Niet actief</template>
      </template>
    </v-switch>
  </div>
</template>

<script>
import GpsTrackingMap from "@/components/maps/GpsTrackingMap";
import featureStyle from "@/mixins/featureStyle";
import {toLonLat} from "ol/proj";
import _ from "lodash";

export default {
  components: {
    GpsTrackingMap
  },
  mixins: [
    featureStyle
  ],
  props: {
    isAdminMode: Boolean,
    context: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      trackingEnabled: false,
      trackedLocations: [],
      location: {
        lat: 0,
        lon: 0,
        accuracy: -1,
      },
    }
  },
  mounted() {
    // Added to fix issue where trackedLocation isn't shown when loading in a previously saved form
    if (this.context.model && this.context.model.length > 0) {
      this.trackedLocations = _.cloneDeep(this.context.model);
    }

    if (!this.isAdminMode) {
      this.trackingEnabled = true;
    }
  },
  computed: {
    trackingActive() {
      return (!this.isAdminMode && this.trackingEnabled)
    }
  },
  watch: {
    trackedLocations(values) {
      this.context.model = values;
    }
  },
  methods: {
    changedLocation(loc) {
      if (this.trackingEnabled) {
        let timestamp = new Date().getTime();
        let coords = toLonLat(loc.coordinate);
        let accuracy = loc.accuracy;
        let newLoc = {
          timestamp: timestamp,
          lon: coords[0],
          lat: coords[1],
          accuracy: accuracy,
          // geoLoc: loc,
        }
        console.log(newLoc);
        this.trackedLocations.push(newLoc);
      }
    },
    featureColor(feature) {
      // Get color from status
      return this.featureStyle(this.context.attributes.mapSettings, feature)
    },
    locationEmpty() {
      return (this.location.lat === 0 && this.location.lon === 0);
    },
    locationAccurate() {
      return (!this.locationEmpty() && this.location.accuracy <= this.context.attributes.mapSettings.minAccuracy);
    }
  }
}
</script>

<style lang="scss" scoped>
.formulate-input-element--eformMap {
  max-width: 100%;
}
.feature-details-table {
  width: 100%;

  td {
    border: 1px solid #eee;
    padding: 5px;
  }
}
</style>