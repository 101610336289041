<template>
  <div :key="$route.params.datasource_id">
    <v-card outlined class="mb-5">
      <v-card-title>Upload hulp</v-card-title>
      <v-card-text>

        <template v-if="datasource_type === 'search'">
          <p><strong>Template:</strong> <a :href="csvExampleURL">Download CSV template</a> met voorbeeld kolommen. Hierin kunt u zien in welk formaat de CSV hoort te zijn.</p>
          <p>
            <strong>Kolommen:</strong><br>
            Kolom <strong>"object.name"</strong> is de naam van object.<br>
            Alle overige kolomnamen (die niet met object. beginnen) worden automatisch toegevoegd als velden op het object.
          </p>
        </template>

        <template v-else-if="datasource_type === 'map_features'">
          <p><strong>Template:</strong> <a :href="csvExampleURL">Download CSV template</a> met voorbeeld kolommen. Hierin kunt u zien in welk formaat de CSV hoort te zijn.</p>
          <p>
            <strong>Kolommen:</strong><br>
            <template v-if="field_crs === 'EPSG:4326'">
              Kolom <strong>"object.latitude"</strong> en <strong>"object.longitude"</strong> is verplicht.<br>
            </template>
            <template v-else-if="field_crs === 'EPSG:28992'">
              Kolom <strong>"object.x"</strong> en <strong>"object.y"</strong> is verplicht.<br>
            </template>
            Kolom <strong>"object.name"</strong> is de naam van object. Deze naam wordt getoond op de kaart.<br>
            Kolom <strong>"object.status"</strong> kunt u de status van het object invullen. Deze is hoofdlettergevoelig.<br>
            Alle overige kolomnamen (die niet met object. beginnen) worden automatisch toegevoegd als velden op het object.
          </p>
        </template>

        <p>
          <strong>Alle kolommen moeten worden gescheiden door een ';'</strong><br>
          <strong>UTF-8 encoding verplicht: Het CSV document moet in UTF-8 encoding opgeslagen zijn.</strong>
        </p>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    datasource_type: null,
    field_crs: null,
  },
  computed: {
    csvExampleURL() {
      if (this.datasource_type === 'map_features') {
        if (this.field_crs === 'EPSG:4326') {
          // GPS
          return this.api_base_url+'modules/custom/eform_datasource/examples/example_map-features_gps.csv'
        }
        else if (this.field_crs === 'EPSG:28992') {
          // RD
          return this.api_base_url+'modules/custom/eform_datasource/examples/example_map-features_rd.csv'
        }
      }
      else if (this.datasource_type === 'search') {
        return this.api_base_url+'modules/custom/eform_datasource/examples/example_search.csv'
      }
      return '';
    }
  },
  data() {
    return {
      api_base_url: process.env.VUE_APP_API_BASEURL,
    };
  },
};
</script>
