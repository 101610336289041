<template>
  <div
      :class="`formulate-input-element formulate-input-element--${context.type}`"
      :data-type="context.type"
  >

    <p class="text-caption text--secondary">{{ optionsSelected.length }} / {{ maxValues }} maximaal aantal items geselecteerd.</p>

    <multiselect
        v-model="optionsSelected"
        :placeholder="placeholder"
        open-direction="bottom"
        :multiple="isMultiple"
        :options="options"
        :disabled="isLoading || maxSelectedValuesReached"
        :option-height="40"
        :options-limit="50"
        :max-height="600"
        track-by="_id"
        label="label"
        :tagging="false"
        :show-no-results="true"
        selected-label="Geselecteerd"
        select-label="[enter] om te selecteren"
        deselect-label="[enter] om te deselecteren"
    >
      <template slot="singleLabel" slot-scope="">
        <span><!-- We don't want to show any selected labels or tags, we created our own selection table --></span>
      </template>
      <template slot="tag" slot-scope="">
        <span><!-- We don't want to show any selected labels or tags, we created our own selection table --></span>
      </template>
      <template slot="option" slot-scope="props">
        <v-icon v-bind:style="{ color: itemColor(props.option) }">mdi-circle</v-icon> {{ props.option.label }}
      </template>
      <span slot="noOptions">Lijst met waardes is leeg.</span>
      <span slot="noResult">Geen waardes gevonden. Verander uw zoekopdracht.</span>
    </multiselect>

    <div style="height: 4px;">
      <v-progress-linear
          indeterminate
          v-if="isLoading"
      ></v-progress-linear>
    </div>

    <v-list two-line v-if="optionsSelected.length">

      <template v-for="(item, index) in optionsSelected">

        <v-list-item
            v-if="item"
            :key="item.value"
            class="px-0"
        >

          <v-list-item-content>
            <v-list-item-title>
              <v-icon v-bind:style="{ color: itemColor(item) }">mdi-circle</v-icon> <strong>{{ item.name }}</strong>
              <v-btn class="ml-2" color="error" depressed small v-on:click="deselectItem(item)">Deselecteren</v-btn>
            </v-list-item-title>

            <v-card flat class="mt-1">

              <table cellpadding="0" cellspacing="0" class="item-details-table">
                <template v-if="item.data">
                  <tr :key="label" v-for="(value, label) in item.data">
                    <td>{{ label }}</td>
                    <td>{{ value }}</td>
                  </tr>
                </template>
              </table>
            </v-card>

          </v-list-item-content>

        </v-list-item>

        <div class="mb-4" v-if="index !== optionsSelected.length - 1" :key="item.value"></div>

      </template>
    </v-list>

  </div>
</template>

<script>
import Vue from "vue"
import VueStatic from 'vue-static'
import Multiselect from 'vue-multiselect'
import axios from "axios";
import store from "@/plugins/store";

Vue.use(VueStatic);

export default {
  components: { Multiselect },
  props: {
    context: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      isLoading: false,
    }
  },
  static() {
    return {
      options: [], // This holds all options loaded from the datasource
    };
  },
  computed: {
    maxValues() {
      return this.context.attributes.searchSettings.maxValues
    },
    isMultiple() {
      if (this.maxValues > 1) {
        return true
      }
      else {
        return false
      }
    },
    maxSelectedValuesReached() {
      if (this.isMultiple && this.optionsSelected.length >= this.maxValues) {
        return true
      }
      else {
        return false
      }
    },
    placeholder() {
      if (this.isLoading) {
        return "Lijst wordt geladen.."
      }
      else if (this.maxSelectedValuesReached) {
        return "Maximaal aantal geselecteerd"
      }
      return "Typ om te zoeken"
    },
    optionsSelected: {
      get() {
        if (this.context.model.length) {
          return this.context.model
        }
        else {
          return []
        }
      },
      set(value) {
        if (Array.isArray(value)) {
          // When is multiple, then it's always an array
          this.context.model = value
        }
        else {
          // Single values
          if (value) {
            // Single label, add object to array
            this.context.model = [value]
          }
          else {
            // Single value is empty, set as empty array
            this.context.model = []
          }
        }
      }
    },
    datasourceID() {
      // Get datasource ID
      if (this.context.attributes.searchSettings.datasourceId) {
        return this.context.attributes.searchSettings.datasourceId
      }
      return false
    },
    appOnline() {
      return store.getters.appOnline;
    },
  },
  watch: {
    'context.attributes.searchSettings.datasourceId': function() {
      // Remove old layers and create new layers
      this.getDatasourceFromApi()
    },
  },
  mounted() {
    // Fetch datasource from API and set the options
    this.getDatasourceFromApi()
  },
  methods: {
    getDatasourceFromApi() {
      // Get datasource ID
      const datasourceID = this.datasourceID
      if (datasourceID) {
        this.isLoading = true

        // Fetch results form the API or from cache when offline
        if (this.appOnline) {
          // Fetch results form the API
          axios
              .get('api/datasource/'+datasourceID)
              .then(response => {

                // Save result to cache
                store.dispatch('storeCache/cacheSet', {
                  type: 'datasource',
                  cid: 'datasource:'+datasourceID,
                  cache: response.data,
                });

                // Set options
                this.options = response.data
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() =>
                  this.isLoading = false
              )
        }
        else {
          // User is offline, fetch from cache
          let cache = this.$store.getters['storeCache/cacheGet']('datasource', 'datasource:'+datasourceID)
          // Set options
          this.options = cache
          this.isLoading = false
        }

      }
    },
    deselectItem(item) {
      if (this.isMultiple) {
        // Find the item in the list of selected items
        let length = this.optionsSelected.length
        for (let i = 0; i < length; i++) {
          // Check if this is the item we want to delete
          if (this.optionsSelected[i]._id === item._id) {
            this.optionsSelected.splice(i, 1);
            return
          }
        }
      }
      else {
        // this.optionsSelected is an object instead of a array when it's a single value.
        // Deselect the (single) object.
        this.optionsSelected = []
      }
    },
    itemColor(item) {
      let itemStyles = []
      if (Array.isArray(this.context.attributes.searchSettings.itemStyles)) {
        itemStyles = this.context.attributes.searchSettings.itemStyles
      }
      let defaultStyle = '#41b883'; // The default style
      let label = item.label.toLowerCase() // Search within the label

      // Loop over all item styles
      for (let i=0; i<itemStyles.length; i++) {
        let itemStyleText = ''
        if (itemStyles[i].text) {
          itemStyleText = itemStyles[i].text.toLowerCase()
        }
        let itemStyleColor = itemStyles[i].color

        // Check if label contains the text
        if (label.includes(itemStyleText)) {
          return itemStyleColor
        }
      }

      return defaultStyle
    }
  },
  beforeDestroy() {
    this.options = [] // Clean up to prevent extreme memory leaks, this is still not fixing the problem but it is making the probem a lot less worse.
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.formulate-input-element--eformSearch {
  max-width: 100%;
}
.item-details-table {
  width: 100%;

  tr {
    td {
      border-bottom: 1px solid #eee;
      padding: 5px;
    }
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}
</style>