<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="this.$store.state.folders"
        mobile-breakpoint="0"
        class="ma-md-8 mt-md-2 rounded-lg"
        :loading="loading"
        :sort-by="['folder_name']"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
        @click:row="rowClick"
    >
      <template slot="no-data">
        Er zijn geen mappen gevonden voor de huidige klant
      </template>
      <template v-slot:item.folder_name="{ item }">
        <v-icon>mdi-folder-outline</v-icon> {{ item.folder_name}}
      </template>
      <template #item.actions>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-data-table>

    <pwa-install-prompt></pwa-install-prompt>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PwaInstallPrompt from "@/components/PwaInstallPrompt";

export default {
  components: {
    PwaInstallPrompt,
  },
  data () {
    return {
      loading: true,
      options: {
        itemsPerPage: -1
      },
      headers: [
        // { text: 'ID', value: 'form_id' },
        {
          text: 'Map',
          align: 'start',
          sortable: true,
          value: 'folder_name',
        },
        { value: "actions", sortable: false, align: 'end' }
      ],
    }
  },
  mounted () {
    // Get registration from API
    if (this.appOnline) {
      this.getDataFromApi();
    }

    this.loading = false;
  },
  computed: {
    ...mapGetters(["appOnline"]),
  },
  methods: {
    ...mapActions(["getFoldersUser", "getFoldersFillerUser"]),
    async getDataFromApi() {
      this.loading = true;

      try {
        if (this.$store.getters.userIsFiller) {
          await this.getFoldersFillerUser();
        }
        else {
          await this.getFoldersUser();
        }
      } catch (error) {
        console.log(error);
      }
      finally {
        this.loading = false;
      }
    },
    rowClick(item) {
      this.$router.push({ name: 'folder_form_list', params: {folder_id: item.folder_id} })
    },
  },
}
</script>

<style scoped>

</style>