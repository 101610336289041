<template>
  <div
      :class="`formulate-input-element formulate-input-element--subform`"
      :data-type="context.type"
  >

    <v-expansion-panels>
      <v-expansion-panel
          v-for="(item,i) in model"
          :key="i"
      >
        <v-expansion-panel-header>
          <span v-if="hasSummary" v-html="getSummary(i)"></span>
          <span v-else>{{ registrationLabel }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>

          <v-row v-if="item.subform_index">
            <v-col>
              <p class="ma-0 text-caption">Subformulier nummer: {{ item.subform_index }}</p>
            </v-col>
          </v-row>

          <eform-registration-formatter :schema="schema" :registration-values="item" :show-image-previews="true"></eform-registration-formatter>
          <v-btn
              depressed
              color="primary"
              outlined
              class="my-1 white--text mx-1"
              @click="editRegistration(i)"
          >
            Bewerk {{ registrationLabel }}
          </v-btn>

          <v-btn
              depressed
              color="error"
              class="my-1 white--text mx-1"
              @click="deleteRegistration(i)"
          >
            Verwijder {{ registrationLabel }}
          </v-btn>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>


    <template v-if="isAdminMode">

      <v-btn
          color="primary"
          outlined
          @click="openModal"
          class="mt-2"
      >
        Subformulier bewerken
      </v-btn>

      <v-dialog v-model="modal" max-width="950" scrollable>
        <v-card>
          <v-card-title class="headline">
            Subformulier bewerken
          </v-card-title>
          <v-card-text class="background-color pt-6">

              <!-- Draggable Formulate -->
              <FormulateForm v-model="values" class="formulate-style-compact">
                <draggable v-model="schema" @start="drag=true" @end="drag=false" handle=".handle">
                  <template v-for="(item, index) in schema">
                    <div :key="item.uuid">

                      <eform-element-edit
                          :disabled-prop="setDisabled(item)"
                          :values="values"
                          :item="item"
                          :form="subform"
                          @formRemoveElement="formRemoveElement($event)"
                      />
                      <eform-element-add
                          :shown="index === (schema.length-1) ? true : false"
                          :form-schema="schema"
                          :index="index"
                          :disabled-element-types="['signature','map','subform','gps','tracking']"
                          @formAddElement="formAddElement($event, index)"
                      />

                    </div>
                  </template>
                </draggable>

                <eform-element-add
                    v-if="schema.length === 0"
                    :shown="true"
                    :form-schema="schema"
                    :disabled-element-types="['signature','map','subform','gps','tracking']"
                    @formAddElement="formAddElement($event)"/>

              </FormulateForm>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="white">
            <v-btn
                color="secondary"
                outlined
                @click="closeModal"
            >
              Sluit
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>

    </template>
    <template v-else>

      <v-btn
          color="primary"
          outlined
          @click="addRegistration"
          class="mt-2"
      >
        {{ registrationLabel }} toevoegen
      </v-btn>

      <v-dialog v-model="modal" max-width="800" scrollable :fullscreen="$vuetify.breakpoint.mobile">
        <v-card>
          <v-card-title class="headline">
            {{ registrationLabel }} toevoegen
          </v-card-title>
          <v-card-text class="pt-4 text--primary background-color">

              <FormulateForm class="formulate-style-compact" v-model="values" @submit="submit" @failed-validation="onValidationErrors" :name="subformFormulateName">
                <v-container>
                  <v-row v-if="values.subform_index">
                    <v-col>
                      <p class="ma-0 text-caption">Subformulier nummer: {{ values.subform_index }}</p>
                    </v-col>
                  </v-row>
                  <v-row v-for="item in conditionalSchema" :key="item.uuid">
                    <v-col class="py-0 px-0">
                      <eform-element
                          :element="item"
                          v-show="isVisuallyShown(item)"
                          :values="values"
                          :schema="schema"
                          :form-settings="formSettings"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </FormulateForm>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="formulate-style-compact">
            <v-btn
                color="primary"
                depressed
                @click="triggerSubmitForm()"
            >
              {{ registrationLabel }} opslaan
            </v-btn>
            <v-btn
                color="secondary"
                outlined
                @click="closeModal"
                class="ml-3"
            >
              Annuleren
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>


    </template>

  </div>
</template>

<script>
import EformElement from "@/components/EformElement";
import EformElementEdit from '@/components/EformElementEdit';
import EformElementAdd from '@/components/EformElementAdd';
import EformRegistrationFormatter from "@/components/EformRegistrationFormatter";
import eformHelpers from "@/mixins/eformHelpers";
import eformConditionals from "@/mixins/eformConditionals";
import draggable from "vuedraggable";
import _ from "lodash";

export default {
  name: "Subform",
  components: {
    EformElement,
    EformElementEdit,
    EformElementAdd,
    EformRegistrationFormatter,
    draggable,
  },
  mixins: [
    eformHelpers,
      eformConditionals
  ],
  props: {
    isAdminMode: Boolean,
    formSettings: Object,
    context: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modal: false,
      editModalKey: null,
      isSaving: false,
    };
  },
  computed: {
    subformFormulateName: function() {
      return 'addSubformRegistration-'+this.context.attributes.uuid
    },
    subform: function() {
      return this.context.attributes.subform
    },
    registrationLabel: function() {
      return this.context.attributes.registrationLabel ? this.context.attributes.registrationLabel : 'registratie';
    },
    model: function() {
      let model = this.context.model;
      // Loop over all subform values in the model
      for (let i = 0; i < model.length; i++) {
        // Add the index to the subform values
        model[i].subform_index = i+1;
      }
      return model;
    },
    values: {
      get() {
        if (Array.isArray(this.subform.values)) {
          // Convert array to object to prevent notice errors from Vue Formulate
          return Object.assign({}, this.subform.values);
        }
        else {
          return this.subform.values
        }
      },
      set(values) {
        this.subform.values = values;
      }
    },
    schema: {
      get() {
        return this.subform.schema;
      },
      set(values) {
        this.subform.schema = values;
      }
    },
    rules: {
      get() {
        return this.subform.rules;
      },
      set(values) {
        this.subform.rules = values;
      }
    },
    hasSummary: function() {
      return !_.isEmpty(this.context.attributes.summaryText);
    },
  },
  methods: {
    getSummary(i) {
      let element = this.context.attributes
      let tokens = this.getTokensFromSchema(this.schema)
      let values = this.context.model[i]
      return this.getRegistrationSummary(element, tokens, values)
    },
    openModal() {
      this.modal = true
      // Send event to parent
      this.$emit('subform-opened');
    },
    closeModal() {
      this.modal = false
      this.editModalKey = null;
      this.resetForm();
      // Send event to parent
      this.$emit('subform-closed');
    },
    triggerSubmitForm() {
      // Trigger submit of named Vue Formulate form
      this.$formulate.submit(this.subformFormulateName)
    },
    formAddElement(element, index) {
      this.schema.splice(++index, 0, element);
    },
    formRemoveElement(element) {
      let elementIndex = this.schema.findIndex(e=> e.uuid === element.uuid);
      this.schema.splice(elementIndex, 1);
    },
    setDisabled(item) {
      return item.type === 'file' || item.type === 'image';
    },
    isVisuallyShown(item) {
      if (item.visuallyHidden) {
        return false;
      }
      return true;
    },
    addRegistration() {
      this.editModalKey = null;
      this.values = this.subform.defaultValues;
      // this.$formulate is nonexistent?
      // subform doesn't get value from this.values???
      // Weird behaviour
      this.openModal();
    },
    editRegistration(key) {
      this.editModalKey = key;
      let registration = _.clone(this.context.model[key]);
      // Reset form but only if it's been created in the past. (fixes an issue with loading values from old registrations where the form hasn't been used/set yet but there are registrations to edit)
      this.resetForm();
      // Set values
      this.values = registration;

      this.openModal();
    },
    deleteRegistration(key) {
      // Remove registration
      this.context.model.splice(key, 1);
    },
    resetForm() {
      if (this.$formulate.registry.has(this.subformFormulateName)) {
        this.$formulate.reset(this.subformFormulateName);
      }
    },
    submit(values) {
      console.log('User has submit an subform registration.')
      // Add values to the subform registrations
      if (this.editModalKey !== null) {
        this.$set(this.context.model, this.editModalKey, values);
      }
      else {
        let modelAsArray = [];
        // Change object to array so we have proper indexes (if registrations exist already)
        if (_.isObject(this.context.model)) {
          modelAsArray = Object.values(this.context.model);
        }
        // Add new values to array
        modelAsArray.push(values);
        // Convert back to object for vueFormulate
        this.$set(this.context, 'model', modelAsArray);
        // Changed line below into whats above, no clue why this was needed but it works now.
        // Object.assign(this.context.model, modelAsArray);
      }

      // Reset form values
      this.$formulate.reset(this.subformFormulateName)
      // Close modal when submit was success
      this.closeModal()
    },
    async onValidationErrors() {
      this.$toast.error("Controleer het formulier. Sommige velden zijn niet of verkeerd ingevuld.");
    },
  }
}
</script>


<style lang="scss" scoped>
.formulate-input-element--subform {
  max-width: 100%;
}
.v-card__actions .formulate-input {
  margin-bottom: 0;
}
</style>