import { render, staticRenderFns } from "./EformElementEditNumber.vue?vue&type=template&id=9eb89b92&scoped=true&"
import script from "./EformElementEditNumber.vue?vue&type=script&lang=js&"
export * from "./EformElementEditNumber.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9eb89b92",
  null
  
)

export default component.exports