<template>
  <div>

    <template>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              type="submit"
              depressed
              color="primary"
              class="ma-md-8 mt-md-2 ma-4"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon left>mdi-plus</v-icon>
            Databron toevoegen
          </v-btn>
        </template>
        <v-list>

          <v-list-item v-if="clientModuleMapElementEnabled" :to="{name: 'datasource_create', params: {datasource_type: 'map_features'}}">
            Kaart items
          </v-list-item>
          <v-list-item v-if="!clientModuleMapElementEnabled" @click="premiumModalShown = true">
            Kaart items <v-chip v-if="!clientModuleMapElementEnabled" small color="primary" class="ml-2"><v-icon small>mdi-lock</v-icon>
          </v-chip>
          </v-list-item>

          <v-list-item :to="{name: 'datasource_create', params: {datasource_type: 'search'}}">
            Zoeken
          </v-list-item>

        </v-list>
      </v-menu>
    </template>

    <v-data-table
        :headers="headers"
        :items="this.datasources"
        mobile-breakpoint="0"
        class="mx-md-8 rounded-lg"
        :sort-by.sync="currentSort"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
        @click:row="rowClick"
    >
      <template #item.type="{item}">
        <template v-if="item.type === 'eform_datasource--search'">Zoeken</template>
        <template v-else>Kaart items</template>
      </template>
    </v-data-table>

    <premium-wall :client_uuid="currentClientUUID" module="map_element" :shown="premiumModalShown" @close="premiumModalShown = false"></premium-wall>

  </div>
</template>

<script>
import axios from "@/plugins/axios";
import {drupalJsonApiResourceTypeToBundle as ResourceTypeToBundle} from "@/plugins/drupalJsonApiHelper";
import clientHelpers from "@/mixins/clientHelpers";
import premiumWall from "@/components/PremiumWall";

export default {
  components: {
    premiumWall,
  },
  mixins: [
    clientHelpers
  ],
  data () {
    return {
      folderTableOptions: {
        itemsPerPage: -1,
      },
      currentSort: ['attributes.name'],
      options: {
        itemsPerPage: -1,
      },
      datasources: [],
      headers: [
        // { text: 'ID', value: 'form_id' },
        {
          text: 'Databron naam',
          align: 'start',
          sortable: true,
          value: 'attributes.name',
        },
        {
          text: 'Type',
          value: 'type',
          sortable: true
        },
        // { value: "actions", sortable: false, align: 'end' }
      ],
      premiumModalShown: false,
    }
  },
  computed: {
    currentClientUUID() {
      return this.$store.getters.CurrentClientUUID || "" // Fallback to empty string if no set
    },
  },
  async mounted () {
    await this.getDataFromApi()
    await this.loadClient(this.$store.getters.CurrentClient)
  },
  methods: {
    async getDataFromApi() {
      let client_id = this.$store.getters.CurrentClient;
      let config = {
        params: {
          'filter[client_id.drupal_internal__id]': client_id,
          'page[limit]': 300,
        }
      }

      try {
        // Fetch datasource search bundles
        let responseSearch = await axios.get('jsonapi/eform_datasource/search', config);
        let responseMapFeatures = await axios.get('jsonapi/eform_datasource/map_features', config);

        this.datasources = [...responseSearch.data.data, ...responseMapFeatures.data.data];

      } catch (error) {
        console.log(error);
      }
    },
    rowClick(item) {
      // Get bundle
      let bundle = ResourceTypeToBundle(item.type)
      this.$router.push({ name: 'datasource_view', params: {datasource_id: item.id, datasource_type: bundle} })
    },
  },
}
</script>

<style scoped>

</style>