<template>
  <v-sheet class="pa-4 rounded-lg">

    <v-row v-if="isLoading">
      <v-col cols="8">
        <v-sheet class="pa-4 rounded-lg">
          <v-skeleton-loader type="sentences, text@3, image"></v-skeleton-loader>
        </v-sheet>
      </v-col>
    </v-row>

    <v-card-text v-if="!isLoading">

      <v-alert
          type="error"
          v-bind:key="key"
          v-for="(errorMessage, key) in errorMessages"
          dismissible
      >
        {{ errorMessage }}
      </v-alert>

      <FormulateForm @submit="submitForm">

        <p class="font-weight-bold mb-0">E-mailadres</p>
        <p>{{ user.display_name }}</p>

        <template v-if="user.field_user_groups && user.field_user_groups.length">
          <p class="font-weight-bold mb-0">Groepen</p>
          <ul class="mb-6">
            <li
                v-for="(group, key) in user.field_user_groups"
                v-bind:key="'group-'+key">
              <router-link text router :to="{name: 'client_user_group_edit', params: {group_id: group.id}}">
                {{ group.label }}
              </router-link>
            </li>
          </ul>
        </template>

        <client-user-input-roles :roles="roles" :user_roles="user_roles" @roles-changed="updateRoles"></client-user-input-roles>

        <template v-if="userHasRole('admin') && user.field_require_terms_and_cond">
          <p class="font-weight-bold mt-5 mb-0">Gebruiker moet algemene voorwaarden accepteren</p>
          <p>Ja</p>
        </template>

        <FormulateInput
            input-class="v-btn v-size--default success"
            type="submit"
            name="submit"
            :disabled="isSaving"
            :label="isSaving ? 'Laden...' : 'Gebruiker opslaan'"
            class="mt-5"
        />
      </FormulateForm>

    </v-card-text>
  </v-sheet>
</template>

<script>
import axios from "@/plugins/axios";
import router from "@/plugins/routes";
import normalize from "json-api-normalize";
import userEntityHelpers from "@/mixins/userEntityHelpers";
import ClientUserInputRoles from "@/views/clients/components/ClientUserInputRoles";

export default {
  components: {
    ClientUserInputRoles,
  },
  mixins: [
    userEntityHelpers
  ],
  props: {
    client_id: null,
    user_id: null,
  },
  data () {
    return {
      user: {},
      user_permission: {},
      errorMessages: [],
      isLoading: true,
      isSaving: false,
      email: '',
      roles: [],
    }
  },
  async mounted () {
    // Load user roles
    await this.getUserRoles()
    // Load user
    await this.getUserDataFromApi();
    await this.getUserPermissionFromApi();
    // Loading is done
    this.isLoading = false
  },
  methods: {
    async getUserDataFromApi() {
      try {
        let config = {
          params: {
            'include': 'field_user_groups.client,roles',
          }
        }
        // Fetch user
        let response = await axios.get('jsonapi/user/user/'+this.user_id, config);

        // Normalize the entities
        this.user = normalize(response.data).get([
          'display_name',
          'roles.label',
          'roles.drupal_internal__id',
          'field_user_groups.label',
          'field_user_groups.id',
          'field_user_groups.client.id',
        ])

        // Set default selected roles
        // if (this.user.roles) {
        //   for (let i = 0; i < this.user.roles.length; i++) {
        //     this.roles.push(this.user.roles[i].drupal_internal__id);
        //   }
        // }

        // Filter out user groups that don't belong to this client
        if (this.user.field_user_groups) {
          this.user.field_user_groups = this.user.field_user_groups.filter(group => group.client.id === this.client_id);
        }

      } catch (error) {
        console.log(error);
      }
    },
    async getUserPermissionFromApi() {
      let config = {
        params: {
          'filter[client.id][value]': this.client_id,
          'filter[user.id][value]': this.user_id,
        }
      }
      // Fetch user
      let response = await axios.get('jsonapi/user_permission/user_permission', config);

      // Normalize the entity. Get the first entity from the response since we only expect one.
      this.user_permission = normalize(response.data).get([
        'id',
        'roles',
      ])[0];

      // Set the selected roles
      if (this.user_permission && this.user_permission.roles) {
        for (let i = 0; i < this.user_permission.roles.length; i++) {
          this.roles.push(this.user_permission.roles[i]);
        }
      }

    },
    updateRoles(rolesSelected) {
      // Set new roles
      this.roles = rolesSelected
    },
    // Send the form to the API endpoint
    async submitForm() {

      this.isSaving = true;

      // this.patchUser();
      if (this.user_permission) {
        await this.patchUserPermission();
      }
      else {
        await this.createUserPermission();
      }

      this.isSaving = false;


      await router.push({name: 'client_users', params: {client_id: this.client_id}})
    },
    // patchUser() {
    //   let config = {
    //     headers: {
    //       "Accept": "application/vnd.api+json",
    //       "Content-Type": "application/vnd.api+json",
    //     }
    //   }
    //   let payload = {
    //     data: {
    //       type: 'user--user',
    //       id: this.user_id,
    //     }
    //   }
    //
    //   // Patch the resource with the payload
    //   axios.patch('jsonapi/user/user/'+this.user_id, payload, config)
    //       .then(() => {
    //         this.loading = false
    //         this.$toast.success("Gebruiker " + this.user.display_name + " is aangepast.");
    //       })
    //       .catch(error => {
    //         if (error.response && error.response.data) {
    //           // Show error message
    //           if (error.response.data.errors) {
    //             // Add error messages
    //             this.errorMessages = error.response.data.errors;
    //           }
    //         }
    //       })
    //       .finally(() => this.loading = false)
    // },
    patchUserPermission() {
      let config = {
        headers: {
          "Accept": "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        }
      }
      let payload = {
        data: {
          type: 'user_permission--user_permission',
          id: this.user_permission.id,
          attributes: {
            roles: [],
          }
        }
      }

      // Add user roles
      if (this.roles.length > 0) {
        for (let i = 0; i < this.roles.length; i++) {
          // Add role to payload
          payload.data.attributes.roles.push(this.roles[i]);
        }
      }

      // Patch the resource with the payload
      return axios.patch('jsonapi/user_permission/user_permission/'+this.user_permission.id, payload, config)
          .then(() => {
            this.loading = false
            this.$toast.success("Toegangsrechten voor gebruiker " + this.user.display_name + " is aangepast.");
          })
          .catch(error => {
            if (error.response && error.response.data) {
              // Show error message
              if (error.response.data.errors) {
                // Add error messages
                this.errorMessages = error.response.data.errors;
              }
            }
          })
          .finally(() => this.loading = false)
    },
    createUserPermission() {
      let config = {
        headers: {
          "Accept": "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        }
      }
      let payload = {
        data: {
          type: 'user_permission--user_permission',
          attributes: {
            roles: [],
          },
          relationships: {
            client: {
              data: {
                type: 'client--client',
                id: this.client_id,
              }
            },
            user: {
              data: {
                type: 'user--user',
                id: this.user_id,
              }
            }
          }
        }
      }

      // Add user roles
      if (this.roles.length > 0) {
        for (let i = 0; i < this.roles.length; i++) {
          // Add role to payload
          payload.data.attributes.roles.push(this.roles[i]);
        }
      }

      // Patch the resource with the payload
      return axios.post('jsonapi/user_permission/user_permission/', payload, config)
          .then(() => {
            this.loading = false
            this.$toast.success("Toegangsrechten voor gebruiker " + this.user.display_name + " is aangepast.");
          })
          .catch(error => {
            if (error.response && error.response.data) {
              // Show error message
              if (error.response.data.errors) {
                // Add error messages
                this.errorMessages = error.response.data.errors;
              }
            }
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>