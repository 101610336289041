<template>
  <div>
    <v-container fluid class="pa-md-8">
      <v-row>
        <v-col>
          Ga naar terug naar <router-link router :to="{ name: 'home'}">homepage</router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Form",
  mounted () {
    this.$rollbar.info('404 - Page not found')
  },
}
</script>

<style scoped>

</style>