import Vue from 'vue'
import numeral from 'numeral';

// Setup local
numeral.register('locale', 'nl', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    // ordinal : function (number) {
    //     return number === 1 ? 'er' : 'ème';
    // },
    currency: {
        symbol: '€'
    }
});

// switch between locales
numeral.locale('nl');

// Register number formats via Vue filters
Vue.filter('formatPrice', function(value) {
    if (value) {
        let price = value / 100
        return numeral(price).format("$0,0[.]00") // Show thousand seperator and optional after the decimal (only when value has a number behind the decimal)
    }
})

export default numeral