<template>
  <div>

    <page-title :page-title="'Inhoud van ' + folder_name"></page-title>

    <div class="text-center text-lg-left">
      <div class="pl-md-8 mb-3" @click="$router.back()">
        <v-btn small outlined color="secondary">
          <v-icon left>mdi-arrow-left</v-icon> Terug
        </v-btn>
      </div>
    </div>

    <v-data-table
        :headers="headers"
        :items="this.$store.state.forms"
        mobile-breakpoint="0"
        class="ma-md-8 mt-md-2 rounded-lg"
        :sort-by="['form_name']"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
        @click:row="rowClick"
    >
      <template v-slot:item.form_name="{ item }">
        <v-icon>mdi-text-box-outline</v-icon> {{ item.form_name}}
      </template>
      <template #item.actions>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

import PageTitle from "@/components/Pagetitle";
import store from "@/plugins/store";

export default {
  components: {
    PageTitle,
  },
  computed: {
    ...mapGetters({getFolderByID: "getFolderByID"}),
    appOnline() {
      return store.getters.appOnline;
    },
  },
  data () {
    return {
      folder_id: this.$route.params.folder_id,
      folder_name: 'Map',
      options: {
        itemsPerPage: -1
      },
      headers: [
        // { text: 'ID', value: 'form_id' },
        {
          text: 'Formulier naam',
          align: 'start',
          sortable: true,
          value: 'form_name',
        },
        { value: "actions", sortable: false, align: 'end' }
      ],
    }
  },
  mounted () {
    let folder = this.getFolderByID(this.folder_id);
    if (folder) {
      this.folder_name = folder.folder_name;
    }
    this.getDataFromApi()
  },
  methods: {
    ...mapActions(["getUserFolderPublishedForms"]),
    async getDataFromApi() {
      try {
        await this.getUserFolderPublishedForms({folder_id: this.folder_id});
      } catch (error) {
        console.log(error);
      }
    },
    rowClick(item) {
      // Force reload of app when going to form-fill page.
      // We do this to hopefully fix the iOS bug where the file upload and select box is not working.
      let doForceReload = '0'
      if (this.appOnline) {
        doForceReload = '1';
      }
      this.$router.push({ name: 'form_fill', params: {form_id: item.form_id, force_reload: doForceReload} })
    },
  },
}
</script>

<style scoped>

</style>