<template>

  <div>
    <v-bottom-sheet inset persistent v-model="installPromptShow">
      <v-card tile>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <h3>App installeren</h3>
              <p class="my-2">Deze app werkt beter wanneer hij ge&iuml;nstalleerd is.</p>
              <ol>
                <li>
                  Tik op <img class="ios-icon" height="25" src="@/assets/images/ios-share-icon.svg"/>
                </li>
                <li>
                  Tik op "Zet op beginscherm" <img  class="ios-icon" height="25" src="@/assets/images/ios-add-to-homescreen-icon.svg"/>
                </li>
              </ol>
            </v-list-item-content>

            <v-list-item-icon
                class="ml-0"
                :class="{ 'mr-3': $vuetify.breakpoint.mdAndUp }"
            >
              <v-btn
                  icon
                  @click="hideInstallPrompt"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>

      </v-card>
    </v-bottom-sheet>
  </div>

</template>
<script>
import userAgent from "@/mixins/userAgent";

export default {
  mixins: [
    userAgent
  ],
  data() {
    return {
      installPromptShow: false,
    }
  },
  mounted() {
    // Show install prompt
    this.showInstallPrompt()
  },
  methods: {
    getCurrentTimestamp() {
      return Math.floor(Date.now() / 1000)
    },
    showInstallPrompt() {
      // Check if more than a day has been passed since the last time we have shown the install prompt.
      let minHoursBetweenPrompts = (1 * 24); // 1 day x 24 hours
      let now = this.getCurrentTimestamp()
      let timestampLastPrompt = now - (minHoursBetweenPrompts * 60 * 60) // Convert hours to minutes to seconds

      // Don't show install prompt when install prompt was shown less than x hours ago
      if (this.$store.state.pwaInstallPromptLastShown < timestampLastPrompt) {
        // Show install prompt on iOS browsers if it's not installed yet.
        if (this.isMobile.iOS() && !this.isInstalled) {
          this.$rollbar.debug('Showing user their install prompt. User: ' + this.$store.getters.StateUser.email);
          // Show install prompt
          this.installPromptShow = true
          // Mark current timestamp as shown
          this.$store.commit('pwaInstallPromptIsShown');
        }
      }
    },
    hideInstallPrompt() {
      this.installPromptShow = false
    }
  },
}
</script>
<style lang="scss">
.ios-icon {
  display: inline-block;
  margin: 0 2px;
  vertical-align: middle;
}
</style>
