import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import { nl } from '@braid/vue-formulate-i18n';
import LabelWithRequired from "@/components/formulate/widgets/LabelWithRequired";
import DecimalComponent from "@/components/formulate/widgets/DecimalComponent";
import CustomAutocomplete from "@/components/formulate/widgets/AutocompleteComponent";
import SignatureComponent from "@/components/formulate/widgets/SignatureComponent";
import CkeditorComponent from "@/components/formulate/widgets/CkeditorComponent";
import SubformComponent from "@/components/formulate/widgets/SubformComponent";
import EformSearchComponent from "@/components/formulate/widgets/EformSearchComponent";
import axios from 'axios';
import EformMap from "@/components/formulate/widgets/EformMap";
import store from "@/plugins/store";
import {fileToBase64} from "@/plugins/fileUploadHelper";
import EformGpsComponent from "@/components/formulate/widgets/EformGpsComponent";
import EformTrackingComponent from "@/components/formulate/widgets/EformTrackingComponent";
import LinkedFormComponent from "@/components/formulate/widgets/LinkedFormComponent";
import EformInputBox from "../components/formulate/widgets/EformInputBox";
import EformCalculation from "../components/formulate/widgets/EformCalculation";
import EformStaticFile from "@/components/formulate/widgets/EformStaticFile";
import EformStateTransition from "../components/formulate/widgets/EformStateTransition.vue";
// import CustomInputFile from "../components/formulate/widgets/CustomInputFile";

Vue.component('LabelWithRequired', LabelWithRequired)
Vue.component('Decimal', DecimalComponent);
Vue.component('CustomAutocomplete', CustomAutocomplete);
Vue.component('Signature', SignatureComponent);
Vue.component('Ckeditor', CkeditorComponent);
Vue.component('EformMap', EformMap);
Vue.component('Subform', SubformComponent);
Vue.component('EformGps', EformGpsComponent);
Vue.component('EformTracking', EformTrackingComponent);
Vue.component('EformSearch', EformSearchComponent);
Vue.component('LinkedForm', LinkedFormComponent);
Vue.component('EformInputBox', EformInputBox);
Vue.component('EformCalculation', EformCalculation);
Vue.component('EformStaticFile', EformStaticFile);
Vue.component('EformStateTransition', EformStateTransition);
// Vue.component('CustomInputFile', CustomInputFile);


Vue.use(VueFormulate, {
    // uploader: axios,
    uploader: async function (file, progress, error, options) {
        try {
            let appOnline = store.getters.appOnline

            if (!appOnline || options.uploadUrl === 'filetobase64') {
                // App is offline, or the uploadUrl has been set to save the file as base64
                // Convert file to base64
                console.log('File upload started (to base64). Filename: ' + file.name)
                let converted_file = fileToBase64(file)
                console.log('File upload completed (to base64). Filename: ' + file.name)
                return converted_file;
            }
            else {
                // App is online. Upload file with axios to backend.
                console.log('File upload started (to server). Filename: ' + file.name)
                const formData = new FormData()
                formData.append('file', file)
                const axiosResult = await axios.post(options.uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: progressEvent => {
                        // Set progress to process callback
                        progress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                    }
                })
                console.log('File upload completed (to server). Filename: ' + file.name)
                return await axiosResult.data
            }
        }
        catch (err) {
            console.log(err)
            let filename = file.name ? file.name : 'unknown'
            error('Bestand kon niet geupload worden. Probeer het opnieuw. Filename: ' + filename)
        }
    },
    uploadUrl: '/api/file-upload/form',
    mimes: {
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    },
    locale: 'nl',
    locales: {
      nl: {
          maxFileSize() {
              return `Maximale bestandsgrootte is 20Mb.`
          }
      }
    },
    plugins: [ nl ],
    validationNameStrategy: ['validationName', 'label', 'type', 'name'],
    slotComponents: {
        // Use the string name of the globally registered component.
        label: 'LabelWithRequired'
    },
    classes: {
        wrapper: (context, classes) => {
            if (context.label !== false) {
                // Add class to indicate there is no label
                return classes.concat([
                    'has-label'
                ])
            }
        },
    },
    library: {
        radio: {
            classification: 'box',
            component: 'EformInputBox',
        },
        decimal: {
            classification: 'text',
            component: 'Decimal',
        },
        eformCalculation: {
            classification: 'text',
            component: 'EformCalculation',
            slotProps: {
                component: ['values', 'schema']
            }
        },
        eformMap: {
          classification: 'map',
          component: 'EformMap'
        },
        eformGps: {
            classification: 'map',
            component: 'EformGps',
            slotProps: {
                component: ['isAdminMode']
            }
        },
        eformTracking: {
            classification: 'map',
            component: 'EformTracking',
            slotProps: {
                component: ['isAdminMode']
            }
        },
        autocomplete: {
            classification: 'text',
            component: 'CustomAutocomplete'
        },
        signature: {
            classification: 'file',
            component: 'Signature'
        },
        ckeditor: {
            classification: 'text',
            component: 'Ckeditor',
            slotProps: {
                component: ['editorPreset']
            }
        },
        subform: {
            classification: 'subform',
            component: 'Subform',
            slotProps: {
                component: ['isAdminMode', 'formSettings']
            }
        },
        linkedForm: {
            classification: 'linkedForm',
            component: 'LinkedForm',
            slotProps: {
                component: ['isAdminMode', 'disabled']
            }
        },
        eformSearch: {
            classification: 'text',
            component: 'EformSearch'
        },
        eformStaticFile: {
            classification: 'file',
            component: 'EformStaticFile'
        },
        eformStateTransition: {
            classification: 'text',
            component: 'EformStateTransition',
        },
    },
    rules: {
        emailOrToken: function (context) {
            // Check if value is an token
            const tokenRegex = /\$[\s\S]+\$/g;
            const tokenFound = context.value.match(tokenRegex);
            if (tokenFound && tokenFound.length === 1) {
                // Make sure the input value is exactly the same as the token we found.
                // We use this as a dumb method to give errors on when input starts or end with a space.
                // Yes, my regex skills are not good enough..
                if (context.value === tokenFound[0]) {
                    return true;
                }
            }

            // Check if it's an emailadres
            const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
            const emailFound = context.value.match(emailRegex);
            if (emailFound) {
                return true;
            }

            return false;
        },
        filenameOrToken: function (context) {
            // Check if value is an token
            const tokenRegex = /\$[a-z0-9_]+\$/g;
            const tokenFound = context.value.match(tokenRegex);
            if (tokenFound && tokenFound.length === 1) {
                // Make sure the input value is exactly the same as the token we found.
                // We use this as a dumb method to give errors on when input starts or end with a space.
                // Yes, my regex skills are not good enough..
                if (context.value === tokenFound[0]) {
                    return true;
                }
            }

            // Check if it's alphanumeric
            const filenameRegex = /^[a-zA-Z0-9-_$]+$/
            const filenameFound = context.value.match(filenameRegex);
            if (filenameFound) {
                return true;
            }

            return false;
        },
        maxFileSize: function (context) {
            let files = context.value.files
            // Loop over all files
            if (files) {
                let length = files.length;
                for (let i = 0; i < length; i++) {
                    // Get file
                    let file = files[i].file
                    // Get filesize
                    let filesize = file.size
                    // Check if file is bigger than 20Mb
                    if (filesize > 20971520) {
                        return false;
                    }
                }
            }
            return true;
        }
    }
});