<template>
  <div>

    <v-data-table
        :headers="headers"
        :items="this.clients"
        mobile-breakpoint="0"
        class="mx-md-8 rounded-lg"
        :sort-by.sync="currentSort"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
        @click:row="rowClick"
    >
    </v-data-table>

  </div>
</template>

<script>
import axios from "@/plugins/axios";
import {mapGetters} from "vuex";

export default {
  data () {
    return {
      folderTableOptions: {
        itemsPerPage: -1,
      },
      currentSort: ['attributes.name'],
      options: {
        itemsPerPage: -1,
      },
      clients: [],
      headers: [
        {
          text: 'Klant naam',
          align: 'start',
          sortable: true,
          value: 'attributes.name',
        },
        {
          text: 'Credits',
          align: 'start',
          sortable: true,
          value: 'attributes.field_credits',
        },
      ],
    }
  },
  computed : {
    ...mapGetters({User: "StateUser"}),
    assignedClientUUIDs() {
      let clientUUIDs = []
      if (this.User.clients) {
        for (let i = 0; i < this.User.clients.length; i++) {
          clientUUIDs.push(this.User.clients[i].uuid)
        }
      }
      return clientUUIDs
    },
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    async getDataFromApi() {
      try {
        // Get list of assigned client UUID's
        let clientsAssigned = this.assignedClientUUIDs
        // Filter on all assigned clients the current user belongs to.
        let config = {
          params: {
            'filter[uuid-filter][path]': 'id',
            'filter[uuid-filter][operator]': 'IN',
            'filter[uuid-filter][value]': clientsAssigned,
          }
        }
        // Fetch clients
        let response = await axios.get('jsonapi/client/client', config);

        this.clients = response.data.data

      } catch (error) {
        console.log(error);
      }
    },
    rowClick(item) {
      this.$router.push({ name: 'client_view', params: {client_id: item.id} })
    },
  },
}
</script>

<style scoped>

</style>