<template>
  <div>

    <v-row class="mb-2">
      <v-col>
        <FormulateInput
            v-model="element.next_phase"
            :options="{1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6', 7: '7', 8: '8', 9: '9'}"
            type="select"
            validation="required"
            label="Nieuwe fase"
            help="Naar welke fase moet de registratie verplaatst worden als de registratie wordt verstuurd?"
        />
        <FormulateInput
            :disabled="usersLoading || users.length === 0"
            v-model="element.assign_to_user"
            :options="userOptions"
            type="select"
            validation="required"
            label="Gebruiker toewijzen"
            help="Naar welke gebruiker moet de registratie toegewezen worden als de registraties wordt verplaatst naar deze fase? Deze gebruiker ontvangt een notificatie en kan verder gaan om de registratie in te vullen. Alleen gebruikers die zijn toegewezen aan het formulier zijn hier beschikbaar."
        />
        <v-alert
            v-if="!usersLoading && users.length === 0"
            dense
            prominent
            text
            type="warning"
        >
          Er zijn nog geen gebruikers toegewezen aan dit formulier. U kunt hier alleen gebruikers selecteren die zijn toegewezen aan het formulier.
        </v-alert>

      </v-col>
    </v-row>

  </div>
</template>

<script>
import eformHelpers from "@/mixins/eformHelpers";
import axios from "axios";

export default {
  mixins: [
    eformHelpers,
  ],
  props: {
    form_id: String,
    element: Object,
    schema: Array,
  },
  mounted() {
    this.getAssignedUsers();
  },
  data() {
    return {
      usersLoading: false,
      users: [],
    }
  },
  computed: {
    userOptions() {
      let options = {
        '0': 'Niet doorsturen',
      };
      // Add users to options
      this.users.forEach(user => {
        options[user.user_id] = user.email;
      });
      return options;
    }
  },
  methods: {
    getAssignedUsers() {
      this.usersLoading = true;

      axios.get('api/form/'+this.form_id+'/assigned-users')
          .then(response => {
            this.users = response.data;
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.usersLoading = false)
    },
  }
}
</script>

<style lang="scss">

</style>
