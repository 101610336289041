export default {
    computed: {
        isMobile() {
            // Detect the (mobile) browsers
            let isMobile = {
                Android: function () {
                    return navigator.userAgent.match(/Android/i);
                },
                BlackBerry: function () {
                    return navigator.userAgent.match(/BlackBerry/i);
                },
                iOS: function () {
                    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
                },
                Opera: function () {
                    return navigator.userAgent.match(/Opera Mini/i);
                },
                Samsung: function () {
                    return navigator.userAgent.match(
                        /SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i,
                    );
                },
                Windows: function () {
                    return (
                        navigator.userAgent.match(/IEMobile/i) ||
                        navigator.userAgent.match(/WPDesktop/i)
                    );
                },
                any: function () {
                    return (
                        isMobile.Android() ||
                        isMobile.BlackBerry() ||
                        isMobile.iOS() ||
                        isMobile.Opera() ||
                        isMobile.Windows()
                    );
                },
            };
            return isMobile
        },
        isInstalled() {
            // For iOS
            if (window.navigator.standalone) return true

            // For Android
            if (window.matchMedia('(display-mode: standalone)').matches) return true

            // If neither is true, it's not installed
            return false
        }

    },
};