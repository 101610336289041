import userHelpers from "@/mixins/userHelpers";

export default {
    mixins: [
        userHelpers
    ],
    data () {
        return {
            user_roles: [],
        }
    },
    methods: {
        async getUserRoles() {
            let user_roles = [
                {
                    id: 'form_manager',
                    label: 'Form manager',
                    disabled: true,
                },
                {
                    id: 'form_view_registrations',
                    label: 'Registratie bekijken',
                    disabled: true,
                },
                {
                    id: 'financial',
                    label: 'Financieel',
                    disabled: true,
                },
                {
                    id: 'client_manager',
                    label: 'Klant beheerder',
                    disabled: true,
                },
                {
                    id: 'user_manager',
                    label: 'Gebruikers beheerder',
                    disabled: true,
                },
            ]

            // Loop through user roles
            for (let i = 0; i < user_roles.length; i++) {
                // Check if current logged-in user is allowed to assign this permission to other users
                if (this.userHasPermission('assign '+user_roles[i].id+' role')) {
                    user_roles[i].disabled = false
                }
            }

            this.user_roles = user_roles;
        },
        getUserRoleLabelById(id) {
            let role = this.user_roles.find(role => role.id === id);
            if (role) {
                return role.label;
            }
            return '';
        }
    }
};