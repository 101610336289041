<template>
  <div>
    <h1>{{ tracking_map_name }}</h1>
    <hr />
    <div>
      <v-alert
          class="my-2"
          v-if="geolocationHasError"
          dense
          prominent
          type="warning"
      >
        {{ geolocationHasError }}
      </v-alert>

      <div style="height: 4px;">
        <v-progress-linear
            indeterminate
            v-if="loading"
        ></v-progress-linear>
      </div>

      <div ref="map-root" style="width: 100%; height: 50vh;">
      </div>
    </div>
  </div>
</template>
<style>
.locate {
  text-align: right;
  top: 0.5em;
  right: 0.5em;
  display:flex;
  flex-flow: row-reverse;
  align-items:flex-end;
}
</style>
<script>
// importing the OpenLayers stylesheet is required for having
// good looking buttons!
import 'ol/ol.css'

import Vue from "vue";
import VueStatic from 'vue-static'
// import axios from "axios";
import View from 'ol/View'
import Map from 'ol/Map'
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import {fromLonLat} from 'ol/proj';
// import Feature from 'ol/Feature';
import {Circle as CircleStyle, Fill, Stroke, Style, Text,} from 'ol/style';
// import Cluster from 'ol/source/Cluster'
import {Cluster, OSM, Vector as VectorSource} from 'ol/source';
import RegularShape from "ol/style/RegularShape";
import {debounce} from 'lodash';
import featureStyle from "@/mixins/featureStyle";
import Feature from "ol/Feature";
import {Attribution, defaults as defaultControls} from 'ol/control';
import axios from "axios";
import LineString from "ol/geom/LineString";
import eformHelpers from "@/mixins/eformHelpers";


Vue.use(VueStatic);



export default {
  name: "TrackingMap",
  mixins: [
    eformHelpers,
    featureStyle
  ],
  data() {
    return {
      selectedFeatures: [], // This hold OpenLayers feature objects
      form_id: this.$route.params.form_id,
      registration_id: this.$route.params.registration_id,
      tracking_map_name: 'Tracking kaart',
      mapSettings: {
        "featuresMaxSelect":1,
        "mapLayers":[
        ],
        "center":{
          "lon":5.387287657315901,"lat":52.15523010839274
        },
        "zoomlevel":7,
        "featureStyles":[
          {"status":"open","color":"#3399CC"},
          {"status":"closed","color":"#e20000"}
        ],
        "featureActions":[]
      },
      loading: false,
      geolocationCenterOnLocation: false,
      geolocationHasError: false,
    }
  },
  computed: {
    datasourceID() {
      // Get datasource ID
      if (this.mapSettings.mapLayers && this.mapSettings.mapLayers.length > 0) {
        return this.mapSettings.mapLayers[0].datasource_id
      }
      return false
    },
    loadingStrategy() {
      // Get loadingStrategy
      if (this.mapSettings.mapLayers && this.mapSettings.mapLayers.length > 0) {
        return this.mapSettings.mapLayers[0].loadingStrategy
      }
      return 'preload'
    }
  },
  static() {
    return {
      source: null,
      vectorLayer: null,
      sourceCenterPin: null,
      vectorLayerCenterPin: null,
      clusterSource: null,
      clustersLayer: null,
      geolocation: null,
      olView: null,
      olMap: null,
      featureStyleCache: {}, // Feature styles
      clusterStyleCache: {}, // Cluster styles,
      // requestCancelToken: undefined
    };
  },
  watch: {
    'featureStatusFilter': {
      handler() {
        this.recreateMapLayers();
      }
    },
    'mapSettings.center': {
      deep: true,
      handler(value) {
        this.olView.setCenter(fromLonLat([value.lon, value.lat]))
      }
    },
    'mapSettings.zoomlevel': function(value) {
      this.olView.setZoom(value)
    },
    'mapSettings.mapLayers': function() {
      // Remove old layers and create new layers
      this.recreateMapLayers()
    },
    'mapSettings.featureStyles': function() {
      // Refresh map when mapSettings change
      this.refreshMap()
    },
  },
  mounted() {
    this.getDataFromApi()

    let createTextStyle = function (feature, fontsize, offsetY) {
      let font = fontsize+'px "Roboto", sans-serif'
      let textColor = new Fill({color: '#222'})
      if (feature.get('selected')) {
        font = 'bold '+fontsize+'px "Roboto", sans-serif'
        textColor = new Fill({color: '#1976d2'})
      }

      return new Text({
        text: getText(feature),
        font: font,
        // stroke: new Stroke({color: [0, 0, 0, 1], width: 3}),
        fill: textColor,
        offsetY: offsetY
      })
    };

    let getText = function (feature) {
      return feature.get('name');
    };

    function styleFunction(feature, resolution) {

      let strokeStyle = undefined
      if (feature.get('selected')) {
        strokeStyle = new Stroke({color: '#1976d2', width: 3})
      }

      // Calculate radius
      let radius = 6 / resolution
      if (radius < 5) {
        radius = 5
      }
      else if (radius > 16) {
        radius = 16
      }
      // Get color from status
      let fillColor = $vm.featureStyle($vm.mapSettings, feature)

      let style = {
        image: new CircleStyle({
          radius: radius,
          fill: new Fill({color: fillColor}),
          stroke: strokeStyle,
        }),
      }

      // Show feature text when zoomed in
      if (resolution < 2) {
        // Calculate fontsize
        let fontsize = radius*1.2
        if (fontsize < 10) {
          fontsize = 10
        }
        // Calculate offset
        let offsetY = radius + (fontsize/2) + 3
        // Add feature text
        style.text = createTextStyle(feature, fontsize, offsetY)
      }

      return new Style(style);
    }

    // Make Vue 'this' accessible inside other functions
    let $vm = this;

    const trackStyle = new Style({
      stroke: new Stroke({
        color: 'rgba(0,0,255,1.0)',
        width: 3,
        lineCap: 'round'
      })
    });

    this.trackFeature = new Feature({
      geometry: new LineString([])
    });

    // we'll need a vector layer to render it
    const trackLayer = new VectorLayer({
      source: new VectorSource({
        features: [this.trackFeature]
      }),
      style: trackStyle
    });

    // This hold all features
    this.source = new VectorSource();
    // a new vector layer is created with the feature
    this.vectorLayer = new VectorLayer({
      name: 'Features',
      source: this.source,
      minResolution: 0,
      maxResolution: 3,
      style: styleFunction,
    })

    // This hold the center pin when tracking the user his location
    this.sourceCenterPin = new VectorSource();
    // a new vector layer is created with the feature
    this.vectorLayerCenterPin = new VectorLayer({
      name: 'CenterPin',
      source: this.sourceCenterPin,
    })

    // Add cluster layer in between (is only used when the function setFeaturesClusters) is called)
    this.clusterSource = new Cluster({
      distance: 40,
      source: this.source
    });

    this.clustersLayer = new VectorLayer({
      name: 'Cluster',
      source: this.clusterSource,
      minResolution: 3,
      // maxResolution: 20000,
      style: function(feature) {
        let size = feature.get('features').length;
        let style = $vm.clusterStyleCache[size];
        if (!style) {
          style = new Style({
            image: new RegularShape({
              points: 4,
              radius: 20 / Math.SQRT2,
              radius2: 20,
              angle: 0,
              fill: new Fill({
                color: '#41b883'
              })
            }),
            text: new Text({
              text: size.toString(),
              font: '11px "Roboto", sans-serif',
              fill: new Fill({color: '#222'})
            })
          });
          $vm.clusterStyleCache[size] = style;
        }
        return style;
      }
    });


    this.olView = new View({
      zoom: this.mapSettings.zoomlevel,
      center: fromLonLat([this.mapSettings.center.lon, this.mapSettings.center.lat]),
      constrainResolution: true
    })

    const attribution = new Attribution({
      collapsible: true,
      collapsed: true
    });

    // this is where we create the OpenLayers map
    this.olMap = new Map({
      // the map will be created using the 'map-root' ref
      target: this.$refs['map-root'],
      layers: [
        // adding a background tiled layer
        new TileLayer({
          source: new OSM() // tiles are served by OpenStreetMap
        }),
        // the vector layer is added above the tiled OSM layer
        this.clustersLayer,
        this.vectorLayer,
        this.vectorLayerCenterPin,
      ],
      // Set map view
      view: this.olView,
      controls: defaultControls({attribution: false}).extend([attribution]),
    });

    this.olMap.addLayer(trackLayer);
  },
  methods: {
    async getDataFromApi() {
      this.loading = true
      await this.getFormDataFromApi()
      await this.getRegistrationDataFromApi()

      this.loading = false
    },
    getFormDataFromApi () {
      // Fetch results form the API
      return axios
          .get('api/form/'+this.form_id)
          .then(response => {
            this.form = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    getRegistrationDataFromApi () {
      // Fetch results form the API
      return axios
          .get('api/form/'+this.form_id+'/registrations/'+this.registration_id)
          .then(response => {
            this.registration = response.data
            this.tracking_map_name = "Tracking formulier: " + response.data.form_name;
            this.processRegistration();
          })
          .catch(error => {
            console.log(error)
          })

    },
    processRegistration() {
      let elements = this.getElementsFromSchemaFiltered(this.registration.schema, ['tracking']);

      if (elements && elements[0]) {
        let trackingValues = this.registration.values[elements[0].name];

        for (var i = 0; i < trackingValues.length; i++) {
          let coords = trackingValues[i];
          this.trackFeature.getGeometry().appendCoordinate(fromLonLat([coords.lon, coords.lat]));
        }

        this.olView.fit(this.trackFeature.getGeometry());
      }
    },
    featureColor(feature) {
      // Get color from status
      return this.featureStyle(this.mapSettings, feature)
    },
    refreshMap: debounce(function () {
      // Refresh map (needed when updating styles for example)
      this.source.changed()
    }, 100),
    recreateMapLayers: debounce(function () {
      // Remove map layers
      this.removeMapLayers()
      // Create new map layers
      this.createMapLayers();
    }, 1000),
    removeMapLayers() {
      // Remove features from layer
      this.source.clear();
    },
    async createMapLayers() {
      if (this.map_uuid) {
        this.deselectFeatures();
      }
    },

  },
}
</script>

<style scoped>

</style>