<template>
  <div :key="$route.params.formId">

    <template v-if="loading">
      <v-skeleton-loader type="heading"></v-skeleton-loader>
      <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
    </template>

    <template v-if="!loading">

      <v-sheet class="pa-4 rounded-lg">

        <v-alert
            type="error"
            v-bind:key="key"
            v-for="(errorMessage, key) in errorMessages"
        >
          {{ errorMessage.detail }}
        </v-alert>

        <FormulateForm #default="{ isLoading }" @submit="submitForm">
          <FormulateInput
              label="Databron naam"
              name="name"
              v-model="datasource.attributes.name"
              validation="required"
          />
          <FormulateInput
              v-if="datasource_type === 'map_features'"
              v-model="datasource.attributes.field_crs"
              :options="field_crs_label"
              type="radio"
              validation="required"
              label="Coordinaten systeem"
          />
          <datasource-upload-help :datasource_type="datasource_type" :field_crs="datasource.attributes.field_crs"></datasource-upload-help>
          <FormulateInput
              type="file"
              name="file"
              v-model="file"
              label="Bronbestand wijzigen"
              help="Upload een .csv bestand met de databron."
              validation="mime:text/csv"
              upload-url="/api/file-upload/datasource_source"
              :validation-messages="{
                              'mime': 'Er kunnen alleen CSV (.csv) bestanden worden geupload'
                            }"
          />
          <FormulateInput
              input-class="v-btn v-size--default success"
              type="submit"
              name="submit"
              :disabled="isLoading"
              :label="isLoading ? 'Laden...' : 'Databron opslaan'"
          />
        </FormulateForm>

      </v-sheet>

    </template>

  </div>

</template>

<script>
import axios from "axios";
import router from "@/plugins/routes";
import datasourceEntityHelpers from "@/mixins/datasourceEntityHelpers";
import datasourceUploadHelp from "@/components/DatasourceUploadHelp";

export default {
  components: {
    datasourceUploadHelp,
  },
  mixins: [
    datasourceEntityHelpers,
  ],
  props: {
    datasource_type: null,
    datasource_id: null,
  },
  mounted () {
    // Get form from API
    this.getDataFromApi()
  },
  data() {
    return {
      errorMessages: [],
      api_base_url: process.env.VUE_APP_API_BASEURL,
      datasource: {},
      loading: true,
      isSaving: false,
      file: null,
    }
  },
  methods: {
    getDataFromApi () {
      this.loading = true
      // Fetch results from the API
      axios
          .get('jsonapi/eform_datasource/'+this.datasource_type+'/'+this.datasource_id)
          .then(response => {
            this.datasource = response.data.data
            // this.updateForm(response.data);
            console.log(response)
            this.loading = false;
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => this.loading = false)
    },
    // Send the form to the API endpoint
    async submitForm(formValues) {
      // Get uploaded file
      // let file_uuid = formValues.file[0][0].uuid
      console.log(formValues)

      this.isLoading = true;


      let config = {
        headers: {
          "Accept": "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        }
      }
      let payload = {
        data: {
          type: 'eform_datasource--'+this.datasource_type,
          id: this.datasource_id,
          attributes: this.datasource.attributes,
        }
      }

      // Add file when a file is uploaded
      if (formValues.file) {
        payload.data.relationships = {
          file: {
            data: {
              type: 'file--file',
              id: formValues.file[0][0].uuid,
            }
          }
        }
      }

      // Patch the resource with the payload
      axios.patch('jsonapi/eform_datasource/'+this.datasource_type+'/'+this.datasource_id, payload, config)
          .then(response => {
            this.loading = false
            this.$toast.success("Databron " + this.datasource.attributes.name + " is aangepast.");
            router.push({ name: 'datasource_view', params: {datasource_id: response.data.data.id} })
          })
          .catch(error => {
            if (error.response && error.response.data) {
              // Show error message
              if (error.response.data.errors) {
                // Add error messages
                this.errorMessages = error.response.data.errors;
              }
            }
          })
          .finally(() => this.loading = false)
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      const answer = window.confirm('Wilt u deze pagina verlaten? Indien u wijzigingen heeft aangebracht gaan deze verloren.')
      if (answer) {
        next()
        return
      } else {
        next(false)
        return
      }
    }
    next()
  }
}
</script>

<style scoped>

</style>