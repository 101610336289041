<template>
  <div>
    <FormulateInput
        type="checkbox"
        v-model="defaultDateNow"
        :label="label"
        help="Deze kan niet samen met 'waardes onthouden' worden gebruikt"
    />
    <FormulateInput
        v-if="defaultDateNow !== true"
        v-model="element.rememberInput"
        type="checkbox"
        label="Waardes onthouden"
    />
  </div>
</template>

<script>
export default {
  name: 'EformElementEditDateDefaultNow',
  props: {
    element: Object,
  },
  data: () => ({
    defaultDateNow: false,
    label: "Nu als standaard datum/tijd",
  }),
  watch: {
    defaultDateNow() {
      this.element.defaultDateNow = this.defaultDateNow;
    }
  },
  mounted () {
    this.defaultDateNow = this.element.defaultDateNow;

    if (this.element.elementType === 'date') {
      this.label = "Nu als standaard datum"
    }
    else if (this.element.elementType === 'time') {
      this.label = "Nu als standaard tijd"
    }
    else if (this.element.elementType === 'datetime') {
      this.label = "Nu als standaard datum/tijd"
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
