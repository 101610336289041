<template>
  <div>

    <FormulateForm :debounce="100">
      <FormulateInput
          type="select"
          :label="loading ? 'Formulieren worden geladen...' : 'Gekoppelde formulier'"
          placeholder="Selecteer een formulier"
          :disabled="loading"
          v-model="element.formId"
          :options="linkedFormOptions"
          validation="required"
          error-behavior="live"
          help="Kies een formulier om te koppelen. U ziet alleen gepubliceerde formulieren die bij deze klant horen."
        />

    </FormulateForm>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'EformElementEditLinkedForm',
  props: {
    element: Object,
  },
  data: () => ({
    loading: false,
    linkedFormOptions: [],
  }),
  mounted () {
    // Get items
    this.getFormLinkOptions()
  },
  methods: {
    async getFormLinkOptions() {
      this.loading = true;
      let client_id = this.$store.getters.CurrentClient;
      let response = await axios.get('api/forms/published/'+client_id);
      let options = [];

      if (response.data) {
        for (var i = 0; i < response.data.length; i++) {
          options.push({ value: response.data[i].form.form_id, label: response.data[i].form.form_name});
        }
      }

      this.linkedFormOptions = options;
      this.loading = false;
    },
  }
}
</script>

<style lang="scss">
// Make the Vue Formulate repeatable group wider.
.formulate-input .formulate-input-element--group {
  max-width: 100% !important;
}
</style>
