<template>
  <v-sheet class="pa-4 rounded-lg">

    <v-row v-if="isLoading">
      <v-col cols="8">
        <v-sheet class="pa-4 rounded-lg">
          <v-skeleton-loader type="sentences, text@3"></v-skeleton-loader>
        </v-sheet>
      </v-col>
    </v-row>

    <v-card-text v-if="!isLoading">

      <v-alert
          type="error"
          v-bind:key="key"
          v-for="(errorMessage, key) in errorMessages"
          dismissible
      >
        {{ errorMessage }}
      </v-alert>

      <FormulateForm #default="{ isSaving }" @submit="submitForm">
        <FormulateInput
            type="text"
            label="Groepnaam"
            v-model="name"
            validation="required"
        />

        <FormulateInput
            input-class="v-btn v-size--default success"
            type="submit"
            name="submit"
            :disabled="isSaving"
            :label="isSaving ? 'Laden...' : 'Groep toevoegen aan klant'"
            class="mt-5"
        />
      </FormulateForm>

    </v-card-text>
  </v-sheet>
</template>

<script>
import axios from "@/plugins/axios";
import router from "@/plugins/routes";
import userEntityHelpers from "@/mixins/userEntityHelpers";
import loader from "@/mixins/loader";

export default {
  mixins: [
      userEntityHelpers,
      loader,
  ],
  props: {
    client_id: null,
  },
  data () {
    return {
      errorMessages: [],
      name: '',
    }
  },
  async mounted () {
    this.hideLoader()
  },
  methods: {
    // Send the form to the API endpoint
    async submitForm() {
      this.showSaving()

      let config = {
        headers: {
          "Accept": "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        }
      }
      let payload = {
        data: {
          type: 'user_group--user_group',
          attributes: {
            label: this.name,
          },
          relationships: {
            client: {
              data: {
                type: 'client--client',
                id: this.client_id
              }
            },
          }
        },
      }

      axios.post('jsonapi/user_group/user_group', payload, config)
          .then(() => {
            this.$toast.success("Groep '" + this.name + "' is aangemaakt.");
            // Redirect back to the overview
            router.push({ name: 'client_user_groups', params: {client_id: this.client_id} })
          })
          .catch(error => {
            if (error.response && error.response.data) {
              // Show error message
              if (error.response.data.errors) {
                // Add error messages
                this.errorMessages = error.response.data.errors;
              }
            }
          })
          .finally(() => this.hideSaving())

    }
  }
}
</script>

<style scoped>

</style>