export default {
    data: () => {
        return {
            isLoading: true,
            isSaving: false,
        }
    },
    methods: {
        showLoader () {
            this.isLoading = true;
        },
        hideLoader () {
            this.isLoading = false;
        },
        showSaving() {
            this.isSaving = true;
        },
        hideSaving() {
            this.isSaving = false;
        },
    }
}