<template>
  <v-dialog v-model="shown" max-width="950" scrollable @click:outside="hideModal">
    <v-card>
      <v-card-title>
        Betaalde functie {{ modules_label[moduleName] }}
      </v-card-title>
      <v-card-text class="background-color py-6">
        <p>U heeft een betaalde functie gevonden.</p>
      </v-card-text>
      <v-card-actions class="actions">
        <v-btn
            color="primary"
            depressed
            router :to="{ name: 'client_modules_buy', params: {client_id: client_uuid}, query: {module: moduleName} }"
        >
          Koop nu
        </v-btn>
        <v-btn
            color="secondary"
            outlined
            @click="hideModal"
        >
          Misschien later
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import clienEntityHelpers from "@/mixins/clienEntityHelpers";

export default {
  name: 'PremiumWall',
  props: {
    shown: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
    },
    client_uuid: String,
  },
  mixins: [
      clienEntityHelpers,
  ],
  data () {
    return {
      termsAndConditionsApproved: false,
      api_base_url: process.env.VUE_APP_API_BASEURL,
    }
  },
  mounted () {

  },
  computed: {
    moduleName() {
      return this.module
    }
  },
  methods: {
    hideModal() {
      this.$emit('close')
    },
  }
}
</script>

<style scoped>

</style>