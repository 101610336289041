<template>
  <v-app-bar class="px-md-4" app dense elevate-on-scroll v-if="pageTitleComputed">
<!--    <breadcrumbs></breadcrumbs>-->
    <v-spacer v-if="$vuetify.breakpoint.mobile"></v-spacer>
    <v-app-bar-title>{{ pageTitleComputed }}</v-app-bar-title>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>
<script>
// import breadcrumbs from "@/components/breadcrumbs";

export default {
  name: 'PageTitle',
  components: {
     // breadcrumbs,
  },
  props: {
    pageTitle: {
      type: String,
    },
  },
  computed: {
    currentRouteTitle() {
      document.title = this.$route.meta.title;
      if (this.$route.meta.titleHidden) {
        return;
      }
      return this.$route.meta.title;
    },
    pageTitleComputed() {
      if (this.pageTitle) {
        // Set page title from property if set.
        return this.pageTitle
      }
      else if (this.currentRouteTitle) {
        // Fallback to the page title of the current route.
        return this.currentRouteTitle
      }
      else {
        return '';
      }
    },
  },
}
</script>
<style lang="scss">
// Import vuetify colors
@import '~vuetify/src/styles/styles.sass';

.v-application {
  .v-app-bar {
    background: var(--v-background-base) !important;
  }
  .theme--light {
    &.v-app-bar--is-scrolled {
      &.v-app-bar {
        background-color: rgba(255, 255, 255, 0.95) !important;
        border-bottom: 1px solid map-get($material-theme, 'dividers') !important;

        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
          -webkit-backdrop-filter: saturate(180%) blur(20px);
          backdrop-filter: saturate(180%) blur(20px);
          background-color: rgba(255, 255, 255, 0.6) !important;
        }
      }
    }
  }
  .theme--dark {
    &.v-app-bar--is-scrolled {
      &.v-app-bar {
        background-color: rgba(0, 0, 0, 0.95) !important;
        border-bottom: 1px solid map-get($material-theme, 'dividers') !important;

        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
          -webkit-backdrop-filter: saturate(180%) blur(20px);
          backdrop-filter: saturate(180%) blur(20px);
          background-color: rgba(255, 255, 255, 0.6) !important;
        }
      }
    }
  }
  .v-tabs {
    border-bottom: 1px solid map-get($material-theme, 'dividers') !important;
  }
}
.v-app-bar-title__content {
  width: 100% !important;
}
</style>
