<template>
  <div>

    <v-btn
        type="submit"
        depressed
        color="primary"
        class="ma-md-8 mt-md-2 ma-4"
        router :to="{ name: 'client_create'}"
    >
      <v-icon left>mdi-plus</v-icon>
      Klant toevoegen
    </v-btn>

    <v-data-table
        :headers="headers"
        :items="this.clients"
        mobile-breakpoint="0"
        class="mx-md-8 rounded-lg"
        :sort-by.sync="currentSort"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
        @click:row="rowClick"
        hide-default-footer
        :server-items-length="-1"
    >
    </v-data-table>

    <drupal-json-api-pager
        :page-prev-link="pagePrevLink"
        :page-next-link="pageNextLink"
        @page-change="onPageChange"
    ></drupal-json-api-pager>

  </div>
</template>

<script>
import axios from "@/plugins/axios";
import DrupalJsonApiPager from "@/components/DrupalJsonApiPager";

export default {
  components: {
    DrupalJsonApiPager,
  },
  data () {
    return {
      pageNextLink: '',
      pagePrevLink: '',
      folderTableOptions: {
        itemsPerPage: -1,
      },
      currentSort: ['attributes.name'],
      options: {
        itemsPerPage: -1,
      },
      clients: [],
      headers: [
        {
          text: 'Klant naam',
          align: 'start',
          sortable: false,
          value: 'attributes.name',
        },
        {
          text: 'Credits',
          align: 'start',
          sortable: false,
          value: 'attributes.field_credits',
        },
      ],
    }
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    async getDataFromApi(link = 'jsonapi/client/client?sort=name') {
      try {
        // Fetch clients
        let response = await axios.get(link);
        // Set items
        this.clients = response.data.data

        // Set pager links
        this.pagePrevLink = response.data.links.prev ? response.data.links.prev.href : ''
        this.pageNextLink = response.data.links.next ? response.data.links.next.href : ''

      } catch (error) {
        console.log(error);
      }
    },
    rowClick(item) {
      this.$router.push({ name: 'client_view', params: {client_id: item.id} })
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    onPageChange(link) {
      this.getDataFromApi(link)
      this.scrollToTop()
    }
  },
}
</script>

<style scoped>

</style>