<template>
  <div>

    <v-row v-if="loading">
      <v-col cols="4">
        <v-sheet class="pa-4 rounded-lg">
          <v-skeleton-loader type="table-heading, divider, text@3"></v-skeleton-loader>
        </v-sheet>
        <v-sheet class="pa-4 rounded-lg mt-4">
          <v-skeleton-loader type="table-heading, divider, text@3"></v-skeleton-loader>
        </v-sheet>
      </v-col>
      <v-col cols="8">
        <v-sheet class="pa-4 rounded-lg">
          <v-skeleton-loader type="sentences, text@3, image"></v-skeleton-loader>
        </v-sheet>
      </v-col>
    </v-row>

    <template v-if="!loading">
      <v-row>
        <v-col>
          <v-sheet class="pa-4 rounded-lg">
            <v-row>
              <v-col>
                <h4>Klant naam</h4>
                <div class="value">{{ client.name }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h4>Modules ingeschakeld</h4>
                <div class="value">
                  <ul v-if="client.modules && client.modules.length">
                    <li :key="'modules'+index" v-for="(item, index) in client.modules">
                      {{ modules_label[item] }}
                    </li>
                  </ul>
                  <p class="mb-0" v-else>Geen</p>
                </div>
                <v-btn v-if="this.userHasPermission('eform edit client')" class="mt-1" outlined small color="primary" router :to="{ name: 'client_modules_buy', params: {client_id: client_id} }">Modules kopen</v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col>
          <v-sheet class="pa-4 mb-5 rounded-lg">
            <v-row>
              <v-col>
                <p class="mb-0">Credits</p>
                <h1 class="value">{{ client.field_credits }}</h1>
              </v-col>
              <v-col>
                <p class="mb-0">Weinig krediet email limiet</p>
                <h1 class="value">{{ client.field_low_credit_email_limit }}</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="mb-0">Aantal registraties</p>
                <h1 class="value">{{ client.registrations_count }}</h1>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet class="pa-4 rounded-lg">
            <client-chart-credit-usage :client_id="client_id"></client-chart-credit-usage>
          </v-sheet>
        </v-col>
      </v-row>
    </template>

  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import normalize from "json-api-normalize";
import clienEntityHelpers from "@/mixins/clienEntityHelpers";
import ClientChartCreditUsage from "@/components/ClientChartCreditUsage";
import userHelpers from "@/mixins/userHelpers";

export default {
  components: {ClientChartCreditUsage},
  mixins: [
    clienEntityHelpers,
    userHelpers,
  ],
  props: {
    client_id: null,
  },
  data() {
    return {
      api_base_url: process.env.VUE_APP_API_BASEURL,
      client: {},
      loading: true,
      isSaving: false,
    }
  },
  computed: {
    ...mapGetters({User: "StateUser", CurrentClient: "CurrentClient"}),
  },
  mounted () {
    // Get registration from API
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi () {
      this.loading = true
      // Fetch results from the API
      axios
          .get('jsonapi/client/client/'+this.client_id)
          .then(response => {
            // Add normalized entity
            this.client = normalize(response.data).get([
              'name',
              'modules',
              'field_credits',
              'field_low_credit_email_limit',
              'registrations_count',
            ])
            // this.updateForm(response.data);
            this.loading = false;
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
  }
}
</script>

<style scoped>

</style>