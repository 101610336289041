import Vue from 'vue'
import Rollbar from 'rollbar'

// Set the Rollbar instance
Vue.prototype.$rollbar = new Rollbar({
    accessToken: process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: process.env.NODE_ENV !== "development",
    payload: {
        environment: process.env.VUE_APP_ENVIRONMENT,
        client: {
            javascript: {
                source_map_enabled: true,
                code_version: process.env.VUE_APP_VERSION,
                guess_uncaught_frames: true,
            },
        },
    },
});

// Setup error handling
Vue.config.errorHandler = (err, vm) => {
    vm.$rollbar.error(err);
    throw err; // rethrow
};
