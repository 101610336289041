<template>
  <div
      :class="`formulate-input-element formulate-input-element--${context.type}`"
      :data-type="context.type"
  >
    <template v-if="file">
      <v-icon>mdi-file-outline</v-icon> <a :href="file.url" target="_blank">{{ file.name }}</a>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true
    },
    values: Object,
    schema: Array,
  },
  computed: {
    file() {
      if (this.context.attributes.file && this.context.attributes.file.length && this.context.attributes.file[0]) {
        return this.context.attributes.file[0]
      }
      return false
    }
  },
}
</script>

<style lang="scss" scoped>

</style>