import Vue from 'vue'
import moment from 'moment';
import VueMoment from 'vue-moment';

// Load Locales ('en' comes loaded by default)
require('moment/locale/nl');

// Choose moment Locale
moment.locale('nl');

Vue.use(VueMoment, { moment });

// Register date formats via Vue filters
Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD MMM YYYY HH:mm')
    }
})
Vue.filter('formatDateOnly', function(value) {
    if (value) {
        return moment(String(value)).format('DD MMM YYYY')
    }
})

export default moment