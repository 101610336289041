<template>
  <div>

    <v-btn
        type="submit"
        depressed
        color="primary"
        class="mb-6"
        v-if="userHasPermission('add client credits')"
        router :to="{ name: 'client_credits_buy'}"
    >
      <v-icon left>mdi-plus-circle-multiple</v-icon>
      Credits kopen
    </v-btn>

    <v-btn
        type="submit"
        depressed
        outlined
        color="secondary"
        class="mb-6 ml-4"
        v-if="userHasPermission('admin add client credits')"
        router :to="{ name: 'client_credits_admin_add'}"
    >
      <v-icon left>mdi-plus-circle-multiple</v-icon>
      Credits toevoegen
    </v-btn>

    <v-data-table
        :headers="headers"
        :items="this.credits"
        mobile-breakpoint="0"
        class="rounded-lg"
        :sort-by.sync="currentSort"
        :sort-desc="[true]"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
    >
      <template #item.created="{ item }">
        {{ item.created|formatDate }}
      </template>
      <template #item.credit_expire_date="{ item }">
        {{ item.credit_expire_date|formatDateOnly }}
      </template>
    </v-data-table>

  </div>
</template>

<script>
import axios from "@/plugins/axios";
import normalize from "json-api-normalize";
import userHelpers from "@/mixins/userHelpers";

export default {
  mixins: [
    userHelpers,
  ],
  props: {
    client_id: null,
  },
  data () {
    return {
      folderTableOptions: {
        itemsPerPage: -1,
      },
      currentSort: ['created'],
      options: {
        itemsPerPage: -1,
      },
      credits: [],
      headers: [
        {
          text: 'Naam',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Gekocht op',
          align: 'start',
          sortable: true,
          value: 'created',
        },
        {
          text: 'Totaal aantal credits',
          align: 'start',
          sortable: true,
          value: 'credits_total',
        },
        {
          text: 'Credits over',
          align: 'start',
          sortable: true,
          value: 'credits_left',
        },
        {
          text: 'Vervaldatum credits',
          align: 'start',
          sortable: true,
          value: 'credit_expire_date',
        },
      ],
    }
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    async getDataFromApi() {
      try {
        // Get all users that belong to this client
        let config = {
          params: {
            'filter[client-filter][path]': 'client_id.id',
            'filter[client-filter][operator]': '=',
            'filter[client-filter][value]': this.client_id,
          }
        }
        // let config = {}
        // Fetch clients
        let response = await axios.get('jsonapi/eform_credit/eform_credit', config);

        // Normalize the entities
        this.credits = normalize(response.data).get([
            'name',
            'created',
            'credits_total',
            'credits_left',
            'credit_expire_date',
        ])

        // Get the current user role
        let isAdmin = this.$store.getters.userIsAdmin;
        if (!isAdmin) {
          // If the credits have expired, set the credits_left to 0
          this.credits.forEach(credit => {
            if (new Date(credit.credit_expire_date) < new Date()) {
              credit.credits_left = 0
            }
          })
        }

      } catch (error) {
        console.log(error);
      }
    },
  },
}
</script>

<style scoped>

</style>