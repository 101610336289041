import axios from "axios";
import store from "@/plugins/store";

export default {
    data () {
        return {
            $_clientInfo: {
                credits: 9999,
                modules: [],
            }
        }
    },
    computed: {
        clientModuleWordEnabled() {
            return this.$data.$_clientInfo.modules.includes('word');
        },
        clientModuleMapElementEnabled() {
            return this.$data.$_clientInfo.modules.includes('map_element');
        },
        clientModuleLocationElementEnabled() {
            return this.$data.$_clientInfo.modules.includes('location');
        },
        clientModuleTrackingElementEnabled() {
            return this.$data.$_clientInfo.modules.includes('tracking');
        },
        clientCredits() {
            return this.$data.$_clientInfo.credits
        },
    },
    methods: {
        async loadClient(client_id) {
            const cacheID = 'request:api/client/'+client_id;
            // Fetch request from cache if it exists, this prevents executing this request 40+ times on big forms.
            let cache = await this.$store.getters['storeCache/cacheGet']('request', cacheID)

            return new Promise((resolve, reject) => {
                if (cache) {
                    // Use the cached request
                    this.$data.$_clientInfo = cache
                    resolve();
                }
                else {
                    // Request doesn't exist in cache. Fetch new request from the API.
                    axios
                        .get('api/client/'+client_id)
                        .then(response => {
                            this.$data.$_clientInfo = response.data
                            // Save result to cache
                            store.dispatch('storeCache/cacheSet', {
                                type: 'request',
                                cid: cacheID,
                                cache: response.data,
                            });
                            resolve();
                        })
                        .catch(error => {
                            console.log(error)
                            this.errored = true
                            reject();
                        })
                }
            });
        },
    }
};