<template>
  <div
      :class="`formulate-input-element formulate-input-element--subform`"
      :data-type="context.type"
  >

      <Form v-if="modal" @closeModal="closeModal" :form_in_modal="true" :form_id="linkedFormId" />
    <template>
      <v-btn
          :disabled="isAdminMode || disabled"
          color="primary"
          outlined
          @click="openModal"
          class="mt-2"
      >
        {{ registrationLabel }} toevoegen
      </v-btn>


    </template>

  </div>
</template>

<script>
import eformHelpers from "@/mixins/eformHelpers";
import Form from "@/views/forms/FormFill";

export default {
  name: "LinkedForm",
  components: {
    Form
  },
  mixins: [
    eformHelpers
  ],
  props: {
    isAdminMode: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      modal: false,
      editModalKey: null,
    };
  },
  watch: {
    registrations(values) {
      this.context.model = values;
    }
  },
  computed: {
    linkedFormId: function() {
      return this.context.attributes.formId;
    },
    subformFormulateName: function() {
      return 'addSubformRegistration-'+this.context.attributes.uuid
    },
    subform: function() {
      return this.context.attributes.subform
    },
    registrationLabel: function() {
      return this.context.attributes.registrationLabel ? this.context.attributes.registrationLabel : 'registratie';
    },
    values: {
      get() {
        if (Array.isArray(this.subform.values)) {
          // Convert array to object to prevent notice errors from Vue Formulate
          return Object.assign({}, this.subform.values);
        }
        else {
          return this.subform.values
        }
      },
      set(values) {
        this.subform.values = values;
      }
    },
    schema: {
      get() {
        return this.subform.schema;
      },
      set(values) {
        this.subform.schema = values;
      }
    },
    rules: {
      get() {
        return this.form.rules;
      },
      set(values) {
        this.subform.rules = values;
      }
    },
  },
  methods: {
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
      this.editModalKey = null;
    },
  }
}
</script>

<style lang="scss" scoped>
.formulate-input-element--subform {
  max-width: 100%;
}
.v-card__actions .formulate-input {
  margin-bottom: 0;
}
</style>