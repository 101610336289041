<template>
  <div>

    <v-btn
        type="submit"
        depressed
        color="primary"
        class="ma-md-8 mt-md-2 ma-4"
        :to="{name: 'form_create'}"
        v-if="userHasPermission('eform manage forms')"
    >
      <v-icon left>mdi-plus</v-icon>
      Formulier toevoegen
    </v-btn>

    <v-data-table
        :headers="folderTableHeaders"
        :items="this.$store.state.folders"
        mobile-breakpoint="0"
        class="ma-md-8 mt-md-2 rounded-lg"
        hide-default-footer
        :sort-by="['folder_name']"
        :options.sync="folderTableOptions"
        @click:row="folderRowClick"
    >
      <template slot="no-data">
        Er zijn geen mappen gevonden voor de huidige klant
      </template>
      <template v-slot:item.folder_name="{ item }">
        <v-icon>mdi-folder-outline</v-icon> {{ item.folder_name}}
      </template>
      <template #item.actions>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-data-table>

    <template v-if="this.forms.length">
      <v-subheader class="mx-md-8 font-weight-bold">Maploze formulieren</v-subheader>
      <v-data-table
          :headers="headers"
          :items="this.forms"
          mobile-breakpoint="0"
          class="mx-md-8 rounded-lg"
          :sort-by="['form_name']"
          :options.sync="options"
          :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
          @click:row="rowClick"
      >
        <template #item.form_name="{item}">
          <v-icon left>mdi-text-box-outline</v-icon>
          {{ item.form_name }}
          <v-chip v-if="item.published_at" x-small color="primary">Gepubliceerd</v-chip>
          <v-chip v-else x-small outlined color="secondary">Niet gepubliceerd</v-chip>
        </template>
        <template #item.actions>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-data-table>
    </template>

  </div>
</template>

<script>
import {mapActions} from "vuex";
// import axios from "@/plugins/axios";
// import _ from "lodash";
import userHelpers from "@/mixins/userHelpers";

export default {
  name: "ListFormsAdmin",
  mixins: [
    userHelpers,
  ],
  data () {
    return {
      folderTableOptions: {
        itemsPerPage: -1,
      },
      options: {
        itemsPerPage: -1,
      },
      forms: [],
      folderTableHeaders: [
        // { text: 'ID', value: 'form_id' },
        {
          text: 'Map',
          align: 'start',
          sortable: true,
          value: 'folder_name',
        },
        { value: "actions", sortable: false, align: 'end',},
      ],
      headers: [
        // { text: 'ID', value: 'form_id' },
        {
          text: 'Formulier naam',
          align: 'start',
          sortable: true,
          value: 'form_name',
        },
        { value: 'form_published', sortable: true, align: 'end'},
        { value: "actions", sortable: false, align: 'end' }
      ],
    }
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    ...mapActions(["getFolders"]),

    async getDataFromApi() {
      // let client_id = this.$store.getters.CurrentClient;

      try {
        await this.getFolders();

        // let response = await axios.get('api/forms/'+client_id);
        // let forms = response.data;
        // let formattedForms = [];
        //
        // for(var i = 0; i < forms.length; i++) {
        //   // Only add here if its not in other folders
        //   if (_.isEmpty(forms[i].form.folder_id) || _.isUndefined(this.$store.getters.getFolderByID(forms[i].form.folder_id))) {
        //
        //     formattedForms[i] = forms[i].form;
        //     if (Array.isArray(forms[i].form.values)) {
        //       formattedForms[i].values = Object.fromEntries(forms[i].form.values);
        //     }
        //   }
        // }
        // this.forms = formattedForms;
      } catch (error) {
        console.log(error);
      }
    },
    rowClick(item) {
      this.$router.push({ name: 'form_view', params: {form_id: item.form_id} })
    },
    folderRowClick(item) {
      this.$router.push({ name: 'folder_form_list_admin', params: {folder_id: item.folder_id}});
    }
  },
}
</script>

<style scoped>

</style>