<template>
  <div>
    Welkom bij de e-form app!
  </div>
</template>

<script>
import store from "@/plugins/store";

export default {
  name: "Home",
  mounted () {
    // Redirect authenticated user to their own pages
    if (store.getters.isAuthenticated) {
      // Redirect user to their own user dashboard
      this.$router.push({name: store.getters.userDashboardRouteName});
    }
    else {
      // Redirect to login
      this.$router.push({name: "login"});
    }
  },
}
</script>

<style scoped>

</style>