import _, {cloneDeep, has} from "lodash";
import store from "@/plugins/store";
import moment from "moment/moment";
import {uuid} from "vue-uuid";
import eformConditionals from "@/mixins/eformConditionals";
import eformHelpers from "@/mixins/eformHelpers";
import clientHelpers from "@/mixins/clientHelpers";
import Vue from "vue";

export default {
    mixins: [
        eformConditionals,
        eformHelpers,
        clientHelpers,
    ],
    props: {
        /**
         * Fill token is used to continue with a saved (unsend) registration.
         */
        fill_token: {
            type: String,
            required: false,
        },
        /**
         * Registration ID is used to edit a registration. For it is now only used in the workflow form.
         */
        registration_id: {
            type: String,
            required: false,
        },
        form_in_modal: {
            type: Boolean,
            default: false,
        },
        force_reload: {
            type: String,
            default: '0',
        }
    },
    data () {
        return {
            formKey: uuid.v4(),
            loadingForm: true,
            myForm: null,
            renderForm: true,
            loadingSavedRegistration: false,
            savingModal: false,
            savingComplete: false,
            showError: false,
            unsavedChanges: true,
        }
    },
    computed: {
        registrationSearchEnabled() {
            return Object.prototype.hasOwnProperty.call(this.myForm, 'searchEnabled') && this.myForm.searchEnabled === true;
        },
        loading() {
            if (this.loadingForm || this.loadingSavedRegistration) {
                return true;
            }
            return false;
        },
        disableSubmit() {
            if (this.loading || this.appOnline === false || this.clientCredits < 1) {
                return true;
            }
            else {
                return false;
            }
        },
        values: {
            get() {
                // Get the form values
                let values = this.myForm.values
                // Get all subform metadata
                let subformMetadata = this.getAllSubformMetadataValues();
                // Add the subform metadata to the values
                values = {
                    ...values,
                    ...subformMetadata,
                }
                return values;
            },
            async set(values) {
                this.myForm.values = values;
            }
        },
        schema: {
            get() {
                if (!has(this.myForm, 'schema')) {
                    return [];
                }
                return this.myForm.schema;
            }
        },
        rules: {
            get() {
                return this.myForm.rules;
            }
        },
        formName() {
            if (this.myForm) {
                return this.myForm.form_name ? this.myForm.form_name : ''
            }
            else {
                return 'Laden..'
            }
        },
        // Use this to send some extra data/settings to the form element.
        formSettings() {
            if (this.myForm && this.myForm.bad_internet_mode) {
                return {
                    bad_internet_mode: this.myForm.bad_internet_mode,
                }
            }
            else {
                return {}
            }
        },
        formType() {
            if (this.myForm) {
                return this.myForm.form_type ? this.myForm.form_type : ''
            }
            return '';
        }
    },
    methods: {
        async initializeForm(form) {
            // Get form from API
            if (this.appOnline) {
                await this.getDataFromApi()
                await this.loadClient(this.myForm.client_id)
            }
            else {
                // Make a copy of the form and set form to this.myForm
                this.myForm = cloneDeep(form)
                this.loadingForm = false;
            }

            // Load assigned workflow registration (edit an existing registration)
            if (this.registration_id) {
                this.loadingForm = true;
                // Load registration
                let registration = await this.loadAssignedRegistration(this.registration_id);
                this.loadingForm = false;
                // Set the 'registration_phase' from the registration
                this.myForm.registration_phase = registration.registration_phase;
                // Add registration date, we need this on the backend to check if we are not overwriting an old registration onto a new registration.
                this.myForm.registration_date = registration.registration_date;
                // Add registration serial number, we need this on the backend
                this.myForm.registration_serial_number = registration.registration_serial_number;
                // Set the registration ID
                this.myForm._id = registration._id;
                // Set form values
                this.setFormValues(registration.values)
            }
            // Set default values
            else if (this.fill_token) {
                // We need to hide to form before setting the default values, otherwise the file and image values won't show.
                this.hideForm()
                // Set default values
                this.$nextTick(() => {
                    // Load the unsynced registration
                    let registration = this.getRegistrationByFillToken(this.fill_token)
                    let registration_values = _.cloneDeep(registration.values)
                    // Set default values
                    this.setFormValues(registration_values)
                    // Use same fill token
                    this.myForm.fill_token = this.fill_token
                    // Reshow the form
                    this.$nextTick(() => this.showForm())
                })
            }
            else {
                // New registration, create a new fill token
                this.myForm.fill_token = uuid.v4();
            }

        },
        setFormValues(values, schema = null) {
            let $vm = this
            this.loadingSavedRegistration = true;
            if (_.isNull(schema)) {
                schema = this.schema;
            }
            let myValues = _.clone(values);
            // Convert file values from multiple to single arrays
            this.fileValuesConvertMultipleToSingle(schema, myValues)

            // Convert subform file values from multiple to single arrays
            let subformElements = this.getElementsFromSchemaFiltered(schema, ['subform']);
            subformElements.forEach(function (subformElement){
                let subformSchema = subformElement.subform.schema
                let subformValues = subformElement.subform.values
                // Convert subform file values from multiple to single arrays
                $vm.fileValuesConvertMultipleToSingle(subformSchema, subformValues)
            });

            if (this.$formulate.registry.has('fillForm')) {
                this.$formulate.reset('fillForm');
            }

            this.values = myValues;

            this.renderForm = true;
            this.loadingSavedRegistration = false;
        },
        hideForm() {
            this.renderForm = false
        },
        showForm() {
            this.renderForm = true
        },
        setDisabled(item) {
            let appOnline = store.getters.appOnline;
            // Disable file widget when app is offline
            if (item.type === 'file' && appOnline === false) {
                return true
            }
        },
        isVisuallyShown(item) {
            if (item.visuallyHidden) {
                return false;
            }
            return true;
        },
        updateForm(form) {
            // Set default values
            this.myForm = this.formSetDefaultValues(form);
            // Unset the '_id', since this is the ID of the form. We don't want to send that to the server when creating a registration.
            delete this.myForm._id;
        },
        formSetDefaultValues(form) {
            if (Array.isArray(form.values)) {
                form.values = Object.fromEntries(form.values);
            }

            for (let i in form.schema) {
                if (form.schema[i].elementType === 'subform') {
                    let subformDefaults = this.formSetDefaultValues(form.schema[i].subform, true);
                    subformDefaults.defaultValues = subformDefaults.values;
                    form.schema[i].subform = subformDefaults;
                    form.defaultValues = form.values;
                    continue;
                }

                if (form.schema[i].rememberInput) {
                    // Get previously saved input from vuex store
                    let oldFieldValue = this.$store.getters.getFieldValue(form.schema[i].uuid);
                    if (oldFieldValue !== undefined) {
                        form.values[form.schema[i].name] = oldFieldValue;
                    }
                }

                let dateTypeArray = ['date', 'time', 'datetime'];
                if (dateTypeArray.includes(form.schema[i].elementType) && form.schema[i].defaultDateNow) {
                    switch (form.schema[i].elementType) {
                        case 'date':
                            form.values[form.schema[i].name] = moment().format("YYYY-MM-DD");
                            break;
                        case 'time':
                            form.values[form.schema[i].name] = moment().format("HH:mm");

                            break;
                        case 'datetime':
                            form.values[form.schema[i].name] = moment().format("yyyy-MM-DDTHH:mm");

                            break;
                    }
                }
            }
            return form;
        },
        async submitFormOnline(formValues) {
            this.savingModal = true

            // Bugfix: Convert the Vue Formulate complex file uploads into simple data.
            for (const [key, element] of Object.entries(this.myForm.schema)) {
                if (element.elementType === 'subform') {
                    // Remove all form values in the schema of the subforms (we don't need this over here).
                    // And more important, it can have complex file-upload data in it, so that needs be removed before submitting.
                    delete this.myForm.schema[key].subform.values
                }
            }

            // Make a copy of the form
            let formCopy = cloneDeep(this.myForm);
            if (this.formType === 'workflow') {
                // Get the dataname of the visible state transition element (we need to do this in the frontend, because unfortunately the back-nd doesn't know what element is currently visible)
                formCopy.registration_visible_state_transition = this.getVisibleStateTransitionDatanameFromSchema();
            }
            // Override values with the formValues to prevent complex file data.
            formCopy.values = formValues;

            // Get basis registration info
            let registration_info = this.getBasicRegistrationInfo(formCopy)

            try {
                // Commit the fill to Vuex store, and try to upload it
                let commit_success = await this.commitFormToStore(formCopy);
                if (!commit_success) {
                    console.error('Error committing eform registration to store');
                    Vue.prototype.$rollbar.error('Error committing eform registration to store', registration_info);
                    this.$toast.error('Er is iets misgegaan met het versturen van de registratie.', { timeout: false });

                    this.savingModal = false
                }
                else {
                    // Allow user to leave page without a confirm box
                    this.unsavedChanges = false
                    // Check form action
                    if (this.myForm.form_settings && this.myForm.form_settings.form_complete_action && this.myForm.form_settings.form_complete_action === 'fillFormAgain') {
                        this.fillFormAgain()
                    }
                    else {
                        // Execute normal form complete action
                        this.savingModal = false
                        this.savingComplete = true

                        Vue.prototype.$rollbar.info('Eform registration completed sending to server.', registration_info);
                    }
                }
            } catch (error) {
                // Registration already exists on the server
                if (error.response && error.response.status && error.response.status === 409) {
                    // Remove the registration from the unsynced registrations since this registration will never be able to be sent properly.
                    try {
                        store.commit('registrationCompleted', {
                            form: formCopy
                        });
                    } catch (error) {
                        let error_text = 'Error removing duplicate registration';
                        console.error(error_text, error);
                        // Merge the error with the registration info
                        error.registration_info = registration_info;
                        Vue.prototype.$rollbar.error(error_text, error);
                        this.$toast.error('Er is iets misgegaan met het opruimen de dubbele opgeslagen registratie.', { timeout: false });
                    }

                    let warning_text = 'Deze registratie is al eerder ingestuurd en kon daarom niet opnieuw worden verstuurd. U kunt alsnog op versturen klikken maar dan kan het zijn dat er een dubbele registraties gemaakt wordt.';
                    Vue.prototype.$rollbar.warning(warning_text, registration_info);
                    this.$toast.warning(warning_text, { timeout: false });

                    // Change the fill_token so user can fill the form again should they so desire
                    this.myForm.fill_token = uuid.v4();
                }
                else if (error.response && error.response.status && error.response.status === 403) {
                    let error_text = "Formulier kon niet worden verstuurd. Probleem met de toegangsrechten.";
                    Vue.prototype.$rollbar.error(error_text, registration_info);
                    this.$toast.error(error_text, { timeout: false });
                }
                else if (error.response && error.response.status && error.response.status === 402) {
                    let error_text = "Er zijn niet voldoende credits beschikbaar om het formulier te versturen. Vul uw credits aan en probeer het opnieuw.";
                    Vue.prototype.$rollbar.error(error_text, registration_info);
                    this.$toast.error(error_text, { timeout: false });
                }
                else if (error.response && error.response.status && error.response.status === 412) {
                    let error_text = "Het formulier wordt verwerkt. Probeer het nogmaals over 1 tot 2 minuten.";
                    Vue.prototype.$rollbar.error(error_text, registration_info);
                    this.$toast.error(error_text, { timeout: false });
                }
                else {
                    let error_text = "Formulier kon niet worden verstuurd. Probeer het later opnieuw. Uw registratie opgeslagen onder het kopje 'Opgeslagen registraties'. Foutmelding: " + error.response.data.message;
                    Vue.prototype.$rollbar.error(error_text, registration_info);
                    console.log(error)
                    this.$toast.error(error_text, { timeout: false });
                }
                this.savingModal = false
            }
        },
        /**
         * Get the visible state transition element dataname from the conditional schema
         */
        getVisibleStateTransitionDatanameFromSchema() {
            let element = this.conditionalSchema.filter(item => item.elementType === 'stateTransition');
            if (element.length > 0) {
                return element[0]['name'];
            }
            return null;
        },
        getBasicRegistrationInfo(registration) {
            return {
                registration_id: registration._id ?? '',
                fill_token: registration.fill_token ?? '',
                client_id: registration.client_id ?? '',
                form_name: registration.form_name ?? '',
                form_id: registration.form_id ?? '',
                form_type: registration.form_type ?? '',
            }
        },
        subformOpened() {
            this.subformModalIsOpen = true;
        },
        subformClosed() {
            this.subformModalIsOpen = false;
            // Trigger autosave
            this.autoSaveForm();
        },
        goToOverview() {
            if (this.form_in_modal) {
                // Reset and close modal?
                this.$emit('closeModal');
            }
            else {
                // Go to forms overview
                this.$router.push({name: "folder_list"});
            }
        },
        cancelFormFill() {
            if (this.confirmClose()) {
                this.goToOverview();
            }
        },
        fillFormAgain() {
            if (!this.form_in_modal) {
                // Refresh page. Dont use this.$router.go() because it does not work on iOS.
                window.location.reload();
            }
        },
        confirmClose() {
            if (this.unsavedChanges) {
                return window.confirm('Wilt u deze pagina verlaten? Indien u wijzigingen heeft aangebracht gaan deze verloren.');
            }
            return true;
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.confirmClose()) {
            this.$toast.clear();
            next();
        }
        else {
            next(false);
        }
    },
};