<template>
  <div>

    <v-btn
        type="submit"
        depressed
        color="primary"
        class="mb-6"
        router :to="{ name: 'client_user_add'}"
    >
      <v-icon left>mdi-plus</v-icon>
      Gebruiker toevoegen
    </v-btn>

    <v-data-table
        :headers="headers"
        :items="this.users"
        mobile-breakpoint="0"
        class="rounded-lg"
        :loading="tableLoading"
        :sort-by.sync="currentSort"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
        @click:row="rowClick"
    >
      <template #item.roles="{item}">
        <ul v-if="item.roles">
          <li :key="'role'+index" v-for="(role, index) in item.roles">
            {{ getUserRoleLabelById(role) }}
          </li>
        </ul>
      </template>
      <template #item.access="{item}">
        <template v-if="!item.access">
          Nooit
        </template>
        <template v-else>
          {{ item.access|formatDate }}
        </template>
      </template>
      <template #item.actions="{item}">
        <v-btn outlined small color="secondary" @click.stop="resendUserWelcomeMail(item)">
          <v-icon
              depressed
              role="button"
              left
          >mdi-email-fast-outline</v-icon>
          Welkomsmail versturen
        </v-btn>
        <v-btn class="ml-2" outlined small color="red" @click.stop="removeUserFromClientConfirm(item)" v-if="userHasPermission('eform client remove users')">
          <v-icon
              depressed
              role="button"
              left
          >mdi-trash-can</v-icon>
          Verwijderen
        </v-btn>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import axios from "@/plugins/axios";
import userEntityHelpers from "@/mixins/userEntityHelpers";

export default {
  props: {
    client_id: null,
  },
  mixins: [
    userEntityHelpers
  ],
  data () {
    return {
      tableLoading: true,
      errored: false,
      folderTableOptions: {
        itemsPerPage: -1,
      },
      currentSort: ['display_name'],
      options: {
        itemsPerPage: -1,
      },
      users: [],
      headers: [
        {
          text: 'Gebruiker',
          align: 'start',
          sortable: true,
          value: 'username',
        },
        {
          text: 'Rollen',
          align: 'start',
          sortable: true,
          value: 'roles',
        },
        {
          text: 'Laatste toegang',
          align: 'start',
          sortable: true,
          value: 'access',
        },
        { value: "actions", sortable: false, align: 'end' }
      ],
    }
  },
  async mounted () {
    await this.getUserRoles()
    await this.getDataFromApi()
  },
  methods: {
    async getDataFromApi() {
      this.tableLoading = true;

      axios.get('/api/clients/'+this.client_id+'/users')
          .then(response => {
            this.users = response.data;
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.tableLoading = false)
    },
    rowClick(item) {
      // Get bundle
      this.$router.push({ name: 'client_user_edit', params: {client_id: this.client_id, user_id: item.uuid} })
    },
    async removeUserFromClientConfirm(user) {
      if (
          confirm('Weet je zeker dat je de gebruiker "'+user.username+'" wilt verwijderen?')
      ) {
        await this.removeUserFromClient(user);
      }
    },
    async removeUserFromClient(user) {
      this.loading = true
      // Fetch results form the API
      try {
        let response = await axios.patch('/api/clients/'+this.client_id+'/remove-user/'+user.uuid, {});

        if (!response.data && (!response.status || response.status >= 300)) {
          throw new Error(response.toString());
        }
        // Show status message
        this.$toast.success('Gebruiker "'+user.username+'" is verwijderd van deze klant.');
        // Fetch new list
        await this.getDataFromApi();

      } catch (error) {
        console.log(error);
        this.$toast.error("Gebruiker kon niet worden verwijderd.");

      } finally {
        this.loading = false
      }
    },
    async resendUserWelcomeMail(user) {
      this.loading = true
      // Fetch results form the API
      try {
        let response = await axios.get('/api/clients/'+this.client_id+'/user/'+user.uuid+'/resend-welcome-mail', {});

        if (!response.data && (!response.status || response.status >= 300)) {
          throw new Error(response.toString());
        }
        // Show status message
        this.$toast.success('Welkoms mail is verstuurd naar "'+user.username);
        // Fetch new list
        await this.getDataFromApi();

      } catch (error) {
        console.log(error);
        this.$toast.error("Welkomsmail kon niet verstuurd worden.");

      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>