<template>
  <div style="width: 200px">


    <p class="my-0 caption">Ligt tussen</p>


    <v-menu
        v-model="menuMin"
        offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn-toggle
            class="mt-1 ml-1"
            rounded
        >
          <v-btn
              small
              depressed
              v-bind="attrs"
              v-on="on">
            Begin {{ dataMin|formatDateOnly }}
          </v-btn>
          <v-btn small @click="onClear('dataMin')">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-btn-toggle>
      </template>

      <v-date-picker
          v-model="dataMin"
          no-title
          scrollable
      >
      </v-date-picker>
    </v-menu>


    <v-menu
        v-model="menuMax"
        offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn-toggle
            class="mt-1 ml-1"
            rounded
        >
          <v-btn
              small
              depressed
              v-bind="attrs"
              v-on="on">
            Eind {{ dataMax|formatDateOnly }}
          </v-btn>
          <v-btn small @click="onClear('dataMax')">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-btn-toggle>

      </template>

      <v-date-picker
          v-model="dataMax"
          no-title
          scrollable
      >
      </v-date-picker>
    </v-menu>


  </div>
</template>


<script>
export default {
  name: 'EformTableFilterDate',
  data () {
    return {
      menuValue: false,
      menuMin: false,
      menuMax: false,
      dataValue: '',
      dataMin: '',
      dataMax: '',
    }
  },
  props: {
    filterKey: String,
    value: Object,
  },
  mounted() {
    this.dataValue = this.value.value
    this.dataMin = this.value.min
    this.dataMax = this.value.max
  },
  watch: {
    dataMin() {
      this.menuMinSave();
    },
    dataMax() {
      this.menuMaxSave();
    },
  },
  methods: {
    menuMinSave() {
      this.emitValueChange()
    },
    menuMaxSave() {
      this.emitValueChange()
    },
    onClear(name) {
      this[name] = '';
      this.emitValueChange()
    },
    emitValueChange() {
      this.$emit('value-change', this.filterKey, {
        value: this.dataValue,
        min: this.dataMin,
        max: this.dataMax
      });
    }
  }
}
</script>

<style lang="scss">
  //.eform-registration-text-field {
  //  background: red;
  //
  //  .v-input__slot {
  //    min-height: 35px !important;
  //  }
  //  .v-input input {
  //    margin-top: 12px;
  //  }
  //  .v-text-field input {
  //    line-height: 20px;
  //  }
  //}
</style>
