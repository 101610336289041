<template>
  <div>
    <h2>Weet u zeker dat u dit formulier wilt verwijderen?</h2>
    <p>Het formulier &eacute;n alle registraties worden permanent verwijderd.</p>
    <v-btn
        :loading="loading"
        :disabled="loading"
        depressed
        color="error"
        class="my-1 white--text"
        @click="deleteForm"
    >
      Verwijder formulier
    </v-btn>
    <v-btn
        depressed
        outlined
        color="secondary"
        class="ml-3 my-1 white--text"
        router :to="{ name: 'form_view'}"
        exact
    >
      Annuleren
    </v-btn>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FormRemove",
  data() {
    return {
      loading: false,
      form_id: this.$route.params.form_id,
    }
  },
  methods: {
    async deleteForm() {
      this.loading = true
      // Fetch results form the API
      axios
          .delete('api/form/'+this.form_id)
          .then(response => {
            this.$toast.success("Formulier is verwijderd.");
            this.registration = response.data
            this.loading = false

            this.$router.push({name: 'form_list_admin'});
          })
          .catch(error => {
            console.log(error)
            this.$toast.error("Formulier kon niet worden verwijderd.");
          })
          .finally(() => this.loading = false)

    }
  }
}
</script>