<template>
  <div :key="$route.params.client_id">

    <v-app-bar app dense height="48" color="white" elevate-on-scroll>
      <v-spacer v-if="$vuetify.breakpoint.mobile"></v-spacer>
      <v-app-bar-title>{{ clientName }}</v-app-bar-title>
      <v-spacer v-if="$vuetify.breakpoint.mobile"></v-spacer>
      <template v-slot:extension>
        <v-tabs centered grow show-arrows v-model="activeTab">
          <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route" exact>
            <v-icon left>{{ tab.icon }}</v-icon>
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <div class="pa-8">
      <div v-if="!isLoading">
        <router-view :client_id="client_id"></router-view>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import userHelpers from "@/mixins/userHelpers";
import normalize from "json-api-normalize";
import loader from "@/mixins/loader";

export default {
  props: {
    client_id: null,
  },
  mixins: [
    userHelpers,
    loader,
  ],
  data() {
    return {
      client: {},
      activeTab: 1,
    };
  },
  computed: {
    tabs() {
      const tabs = []

      if (this.userHasPermission('eform view client')) {
        tabs.push({ id: 1, name: "Bekijk", icon: "mdi-eye", iconColor: 'green', route: {name: 'client_view'} });
      }

      if (this.userHasPermission('eform edit client')) {
        tabs.push({ id: 2, name: "Aanpassen", icon: "mdi-pencil", iconColor: 'deep-purple', route: {name: 'client_edit'} });
      }

      if (this.userHasPermission('eform client add users')) {
        tabs.push({ id: 3, name: "Gebruikers", icon: "mdi-account-group", iconColor: 'deep-purple', route: {name: 'client_users'} });
      }

      if (this.userHasPermission('view user group')) {
        tabs.push({ id: 4, name: "Groepen", icon: "mdi-account-multiple", iconColor: 'deep-purple', route: {name: 'client_user_groups'} });
      }

      if (this.userHasPermission('view credit entities')) {
        tabs.push({id: 5,  name: "Credits", icon: "mdi-plus-circle-multiple", iconColor: 'deep-purple', route: {name: 'client_credits'} });
      }

      if (this.userHasPermission('delete client entities')) {
        tabs.push({ id: 6, name: "Verwijderen", icon: "mdi-trash-can", iconColor: 'deep-purple', route: {name: 'client_remove'} });
      }

      return tabs;
    },
    clientName() {
      if (this.client) {
        return this.client.name ? this.client.name : ''
      }
      else {
        return 'Laden..'
      }
    }
  },
  mounted () {
    // Get form from API
    this.getDataFromApi()
  },
  watch: {
    client_id: function() {
      // It's possible for a user to switch between clients without reloading the page (via deeplinking).
      // So we need to reload the client data when the 'client_id' changes.
      this.getDataFromApi()
    }
  },
  methods: {
    getDataFromApi () {
      this.showLoader()
      // Fetch results form the API
      axios
          .get('jsonapi/client/client/'+this.client_id)
          .then(response => {
            // Add normalized client
            this.client = normalize(response.data).get([
              'name',
            ])
            this.hideLoader()
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.hideLoader())
    },
  }
};
</script>
