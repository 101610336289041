<template>
  <div>

    <v-row v-if="loading">
      <v-col cols="4">
        <v-sheet class="pa-4 rounded-lg">
          <v-skeleton-loader type="table-heading, divider, text@3"></v-skeleton-loader>
        </v-sheet>
        <v-sheet class="pa-4 rounded-lg mt-4">
          <v-skeleton-loader type="table-heading, divider, text@3"></v-skeleton-loader>
        </v-sheet>
      </v-col>
      <v-col cols="8">
        <v-sheet class="pa-4 rounded-lg">
          <v-skeleton-loader type="sentences, text@3, image"></v-skeleton-loader>
        </v-sheet>
      </v-col>
    </v-row>


    <template v-if="!loading">
      <v-row>
        <v-col>
          <v-sheet class="pa-4 rounded-lg">
            <v-row>
              <v-col>
                <h4>Databron naam</h4>
                <div class="value">{{ datasource.name }}</div>
              </v-col>
            </v-row>
            <v-row v-if="datasource_type === 'map_features'">
              <v-col>
                <h4>Coordinaten systeem</h4>
                <div class="value">{{ field_crs_label[datasource.field_crs] }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h4>Bronbestand</h4>
                <div class="value">
                  <v-icon>mdi-file-outline</v-icon>
                  <a :href="api_base_url+datasource.file.uri.url">{{ datasource.file.filename }}</a>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col>
          <v-sheet class="pa-4 rounded-lg">
            <h3>Databron importeren</h3>
            <v-btn
                depressed
                color="primary"
                @click="showImportModal">
              Import starten
            </v-btn>
          </v-sheet>
          <v-sheet class="pa-4 rounded-lg mt-6">
            <h3>Databron exporteren</h3>
            <v-btn
                depressed
                color="primary"
                @click="showExportModal"
            >
              Export starten
            </v-btn>
          </v-sheet>
        </v-col>
      </v-row>
    </template>


    <v-dialog v-model="importModal" max-width="800" persistent scrollable>
      <v-card>
        <v-card-text class="pt-6">
          <template v-if="!importIsFinished">
            <v-alert
                class="mb-4"
                dense
                prominent
                text
                type="warning"
                v-if="!isImporting"
            >
              Let op: Deze actie verwijdert &aacute;lle bestaande items in deze databron en importeert ze opnieuw.
            </v-alert>
            <drupal-batch
                :batch-create-url="'api/datasource/'+datasource_id+'/import'"
                button-label="Verwijder bestaande items en importeer opnieuw"
                @batch-started="importBatchStarted"
                @batch-finished="importBatchFinished"
            ></drupal-batch>
          </template>
          <p v-if="importIsFinished">Import is voltooid. U kunt dit venster nu sluiten.</p>
        </v-card-text>
        <v-card-actions class="background-color">
          <v-btn
              depressed
              :color="isImporting ? 'error' : 'secondary'"
              :outlined="!isImporting"
              @click="closeImportModal"
          >
            <template v-if="isImporting">
              Stop import
            </template>
            <template v-else-if="importIsFinished">
              Sluit venster
            </template>
            <template v-else>
              Annuleren
            </template>
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>


    <v-dialog v-model="exportModal" max-width="800" persistent scrollable>
      <v-card>
        <v-card-text class="pt-6">
          <template v-if="!exportIsFinished">
            <p v-if="!isExporting">Deze actie exporteert alle items, inclusief alle mutaties die gedaan zijn via registraties.</p>
            <drupal-batch
                :batch-create-url="'api/datasource/'+datasource_id+'/export'"
                button-label="Exporteer databron"
                @batch-started="exportBatchStarted"
                @batch-created="exportBatchCreated"
                @batch-finished="exportBatchFinished"
            ></drupal-batch>
          </template>
          <p v-if="exportIsFinished">Export is voltooid. <v-btn depressed color="primary" :href="exportDownloadUrl"><v-icon left>mdi-download</v-icon> Download CSV bestand</v-btn></p>
        </v-card-text>
        <v-card-actions class="background-color">
          <v-btn
              depressed
              :color="isExporting ? 'error' : 'secondary'"
              :outlined="!isExporting"
              @click="closeExportModal"
          >
            <template v-if="isExporting">
              Stop export
            </template>
            <template v-else-if="exportIsFinished">
              Sluit venster
            </template>
            <template v-else>
              Annuleren
            </template>
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import normalize from "json-api-normalize";
import drupalBatch from "@/components/DrupalBatch";
import datasourceEntityHelpers from "@/mixins/datasourceEntityHelpers";

export default {
  components: {
    drupalBatch
  },
  mixins: [
    datasourceEntityHelpers,
  ],
  props: {
    datasource_type: null,
    datasource_id: null,
  },
  data() {
    return {
      api_base_url: process.env.VUE_APP_API_BASEURL,
      datasource: {},
      loading: true,
      isSaving: false,
      importModal: false,
      isImporting: false,
      importIsFinished: false,
      exportModal: false,
      isExporting: false,
      exportIsFinished: false,
      exportDownloadUrl: '',
    }
  },
  computed: {
    ...mapGetters({User: "StateUser", CurrentClient: "CurrentClient"}),
  },
  mounted () {
    // Get registration from API
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi () {
      this.loading = true
      // Fetch results from the API
      axios
          .get('jsonapi/eform_datasource/'+this.datasource_type+'/'+this.datasource_id)
          .then(response => {
            // Add normalized datasource
            this.datasource = normalize(response.data).get([
              'name',
              'field_crs',
              'file.filename',
              'file.uri'
            ])
            // this.updateForm(response.data);
            this.loading = false;
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    showImportModal() {
      this.importModal = true
      this.importIsFinished = false
    },
    closeImportModal() {
      this.importModal = false
    },
    importBatchStarted() {
      this.isImporting = true
    },
    importBatchFinished() {
      this.isImporting = false
      this.importIsFinished = true
    },
    showExportModal() {
      this.exportModal = true
      this.exportIsFinished = false
    },
    closeExportModal() {
      this.exportModal = false
    },
    exportBatchStarted() {
      this.isExporting = true
      this.exportDownloadUrl = ''
    },
    exportBatchCreated(data) {
      if (data.data) {
        this.exportDownloadUrl = data.data.download_url
      }
    },
    exportBatchFinished() {
      this.isExporting = false
      this.exportIsFinished = true
    },
    // updateForm(form) {
    //   // Update the form
    //   this.$emit('updateForm', form)
    // },
  }
}
</script>

<style scoped>

</style>