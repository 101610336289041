<template>
  <div>

    <template v-if="isLoading">
      <v-skeleton-loader type="heading"></v-skeleton-loader>
      <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
    </template>

    <template v-if="!isLoading">

      <v-sheet class="pa-4 rounded-lg">

        <v-alert
            type="error"
            v-bind:key="key"
            v-for="(errorMessage, key) in error_messages"
        >
          {{ errorMessage.detail }}
        </v-alert>

        <FormulateForm #default="{ isSaving }" @submit="submitForm">
          <FormulateInput
              label="Klant naam"
              name="name"
              v-model="client.attributes.name"
              validation="required"
          />
          <FormulateInput
              v-if="userHasRole('admin') || userHasRole('eform_employee')"
              v-model="client.attributes.modules"
              :options="modules_label"
              type="checkbox"
              label="Modules"
          />
          <FormulateInput
              type="number"
              v-model="client.attributes.field_low_credit_email_limit"
              label="Weinig krediet email limiet"
              help="Het aantal credits wanneer er een 'weinig credits over' email wordt verstuurd"
              validation="required|number"
              min="0"
          />
          <FormulateInput
              type="textarea"
              v-model="client.attributes.user_register_intro_text.value"
              label="Gebruikersregistratie introductie tekst"
              help="Deze tekst wordt getoond wanneer een gebruiker zich registreert voor deze klant."
          />

          <template v-if="userHasRole('admin') || userHasRole('eform_employee')">
            <h4>Algemene voorwaarden gebruikers</h4>
            <v-list
                outlined
                dense
                max-width="400"
                v-if="termsAndConditionsUsers.length"
            >
              <v-list-item-group>
                <v-list-item :key="'terms-and-condition-users-'+index" v-for="(item, index) in termsAndConditionsUsers">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.display_name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn x-small depressed color="error" @click="termsAndConditionsRemoveUser(item.id)">Verwijder</v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <div v-if="termsAndConditionsUsers.length === 0">Geen gebruikers geselecteerd.</div>
            <v-btn outlined small color="secondary" @click="openTermsAndConditionsUserModal" class="mt-2 mb-6">Gebruiker toevoegen</v-btn>
          </template>

          <FormulateInput
              input-class="v-btn v-size--default success"
              type="submit"
              name="submit"
              :disabled="isSaving"
              :label="isSaving ? 'Laden...' : 'Klant opslaan'"
          />
        </FormulateForm>

      </v-sheet>

    </template>

    <v-dialog v-model="terms_and_conditions_users_modal" max-width="950" scrollable>
      <v-card>
        <v-card-title>
          Gebruiker toevoegen
        </v-card-title>
        <v-card-text class="background-color py-6 pa-4">

          <v-data-table
              :headers="terms_and_conditions_users_add_header"
              :items="client_users"
              mobile-breakpoint="0"
              :loading="terms_and_conditions_users_add_loading"
              sort-by="display_name"
              :options="{itemsPerPage: -1}"
          >
            <template #item.actions="{item}">
              <v-btn class="ml-2" outlined small color="primary" @click.stop="termsAndConditionsAddUser(item)">
                <v-icon
                    depressed
                    role="button"
                    left
                >mdi-plus</v-icon>
                Gebruiker toevoegen
              </v-btn>
            </template>
          </v-data-table>

        </v-card-text>
        <v-card-actions class="actions">
          <v-btn
              color="secondary"
              outlined
              @click="terms_and_conditions_users_modal = false"
              class="my-3"
          >
            Sluit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>

</template>

<script>
import axios from "axios";
import router from "@/plugins/routes";
import clienEntityHelpers from "@/mixins/clienEntityHelpers";
import {mapGetters} from "vuex";
import userHelpers from "@/mixins/userHelpers";
import _ from "lodash";
import loader from "@/mixins/loader";
import normalize from "json-api-normalize";

export default {
  mixins: [
    clienEntityHelpers,
    userHelpers,
    loader,
  ],
  props: {
    client_id: null,
  },
  async mounted () {
    // Get all client users
    await this.getClientUsersFromApi()
    // Get form from API
    await this.getDataFromApi()
  },
  data() {
    return {
      error_messages: [],
      api_base_url: process.env.VUE_APP_API_BASEURL,
      client: {},
      client_users: [],
      file: null,
      terms_and_conditions_users_modal: false,
      terms_and_conditions_users_add_header: [
        {
          text: 'Gebruiker',
          align: 'start',
          sortable: true,
          value: 'display_name',
        },
        { value: "actions", sortable: false, align: 'end' }
      ],
      terms_and_conditions_users_add_items: [],
      terms_and_conditions_users_add_loading: false,
    }
  },
  computed : {
    ...mapGetters({User: "StateUser"}),
    termsAndConditionsUsers() {
      let users = []
      if (!_.isEmpty(this.client.relationships.terms_and_conditions_users.data)) {
        // Loop over the users
        for (const user of this.client.relationships.terms_and_conditions_users.data) {
          // Find the loaded user
          let user_loaded = this.client_users.find(item => item.id === user.id)
          if (user_loaded) {
            // Add users
            users.push(user_loaded)
          }
        }
      }
      return users
    },
  },
  methods: {
    getDataFromApi () {
      this.showLoader()
      // Fetch results from the API
      axios
          .get('jsonapi/client/client/'+this.client_id)
          .then(response => {
            this.client = response.data.data
            // Set default value for 'user_register_intro_text' if it's empty
            if (_.isEmpty(this.client.attributes.user_register_intro_text)) {
              this.client.attributes.user_register_intro_text = {
                value: this.client.attributes.user_register_intro_text
              }
            }
            console.log(response)
            this.hideLoader()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => this.hideLoader())
    },
    async getClientUsersFromApi() {
      try {
        // Get all users that belong to this client
        let config = {
          params: {
            'filter[client-filter][path]': 'field_client.id',
            'filter[client-filter][operator]': '=',
            'filter[client-filter][value]': this.client_id,
          }
        }
        // Fetch clients
        let response = await axios.get('jsonapi/user/user', config);

        this.terms_and_conditions_users_add_loading = false

        // Normalize the entities
        this.client_users = normalize(response.data).get([
          'display_name',
          'id',
        ])

      } catch (error) {
        console.log(error);
      }
    },
    async termsAndConditionsAddUser(user) {
      // Make sure it doesn't already exists in the list
      let userExists = this.client.relationships.terms_and_conditions_users.data.filter(function( obj ) {
        return obj.id === user.id;
      });
      // Add user when it doesn't exist yet
      if (userExists.length === 0) {
        // Add user
        this.client.relationships.terms_and_conditions_users.data.push({
          id: user.id,
          type: "user--user",
        })
      }
      // Close modal
      this.terms_and_conditions_users_modal = false
    },
    openTermsAndConditionsUserModal() {
      this.terms_and_conditions_users_modal = true
    },
    termsAndConditionsRemoveUser(uuid) {
      // Find user in this.client.relationships.terms_and_conditions_users.data
      this.client.relationships.terms_and_conditions_users.data = this.client.relationships.terms_and_conditions_users.data.filter(function( obj ) {
        return obj.id !== uuid;
      });
    },
    // Send the form to the API endpoint
    async submitForm(formValues) {
      // Get uploaded file
      console.log(formValues)

      this.showSaving();

      let config = {
        headers: {
          "Accept": "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        }
      }
      let payload = {
        data: {
          type: 'client--client',
          id: this.client_id,
          attributes: this.client.attributes,
          relationships: this.client.relationships,
        }
      }
      // Set text format for field 'user_register_intro_text'
      payload.data.attributes.user_register_intro_text.format = 'filtered_html'

      // Patch the resource with the payload
      axios.patch('jsonapi/client/client/'+this.client_id, payload, config)
          .then(response => {
            this.hideSaving()
            this.$toast.success("Klant " + this.client.attributes.name + " is aangepast.");
            router.push({ name: 'client_view', params: {client_id: response.data.data.id} })
          })
          .catch(error => {
            if (error.response && error.response.data) {
              // Show error message
              if (error.response.data.errors) {
                // Add error messages
                this.error_messages = error.response.data.errors;
              }
            }
          })
          .finally(() => this.hideSaving())
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      const answer = window.confirm('Wilt u deze pagina verlaten? Indien u wijzigingen heeft aangebracht gaan deze verloren.')
      if (answer) {
        next()
        return
      } else {
        next(false)
        return
      }
    }
    next()
  }
}
</script>

<style scoped>

</style>