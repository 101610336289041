import store from "@/plugins/store";

const getDefaultState = () => {
    return {
        caches: {
            datasource: {
                expire: 604800, // 1 week = 604800 seconds
                items: {}, // Hold the cache items
            },
            request: {
                expire: 60, // 60 seconds
                items: {}, // Hold the cache items
            },
        }
    }
}

export const storeCache = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        cacheGet: (state) => async (type, cid) => {
            // Remove expired caches
            await store.dispatch('storeCache/cacheDeleteExpired')
            if (!state.caches[type].items[cid]) {
                // Cache not found
                return false
            }
            // Return cache entry
            return JSON.parse(state.caches[type].items[cid].data)
        }
    },
    actions: {
        cacheSet({ commit }, {type, cid, cache}) {
            // Save cache
            commit("set", {type, cid, cache});
        },
        cacheDeleteExpired({ commit }) {
            commit("deleteExpired");
        },
        cacheDeleteAll({ commit }) {
            commit("deleteAll");
        },
    },
    mutations: {
        set(state, {type, cid, cache}) {
            // Make sure cache type exists
            if (state.caches[type] === undefined) {
                console.log('Set cache item failed. Cache type "'+type+'" does not exists.')
                return
            }
            // Add timestamp
            const timestamp = Math.floor(Date.now() / 1000)
            // Create cache entry
            let cache_entry = {
                created: timestamp,
                data: JSON.stringify(cache) // Convert object to string to prevent CPU/memory limits when working with big objects.
            }
            // Add cache entry
            state.caches[type].items[cid] = cache_entry
        },
        deleteExpired(state) {
            // Loop over all cache tables
            for (let [type] of Object.entries(state.caches)) {
                // Get the expire date
                const expireTimestamp = Math.floor(Date.now() / 1000) - state.caches[type].expire
                // Loop over all cache entries and remove them when they are older than 1 week
                for (const [cid, value] of Object.entries(state.caches[type].items)) {
                    if (!('created' in value) || value.created < expireTimestamp) {
                        delete state.caches[type].items[cid];
                    }
                }
            }
        },
        deleteAll(state) {
            Object.assign(state, getDefaultState())
            // // Loop over all cache tables and clear all caches
            // for (let [type] of Object.entries(state.caches)) {
            //    state.caches[type].items = {};
            // }
        }
    }
};