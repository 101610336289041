<template>
  <div
      :class="`formulate-input-element formulate-input-element--${context.class}`"
      :data-type="context.type"
  >
    <ckeditor v-model="editorData" :config="editorConfigComputed"></ckeditor>
  </div>
</template>

<script>
import CKEditor from 'ckeditor4-vue';

export default {
  components: {
    ckeditor: CKEditor.component
  },
  props: {
    context: {
      type: Object,
      required: true
    },
    editorPreset: {
      type: String,
      required: false,
    },
  },
  computed: {
    editorConfigComputed() {
      // Set the editor preset from prop
      if (this.editorPreset) {
        return this.editorConfigPresets[this.editorPreset]
      }
      // Default to the default config settings
      return this.editorConfigPresets.default
    }
  },
  data() {
    return {
      editorData: this.context.model,
      editorConfigPresets: {
        default: {
          versionCheck: false, // Don't show security warning banner
          extraPlugins: 'autogrow',
          removePlugins: 'elementspath',
          autoGrow_minHeight: 200,
          autoGrow_maxHeight: 600,
          forcePasteAsPlainText: true,
          // The configuration of the editor.
          // fullPage: true,
          // Disable content filtering because if you use full page mode, you probably
          // want to  freely enter any HTML content in source mode without any limitations.
          allowedContent: true,
          format_tags: 'p;h1;h2;h3',
          toolbar: [
            [ '-', 'Format', 'Bold', 'Italic', '-', 'Link', 'Unlink', '-', 'NumberedList', 'BulletedList', 'HorizontalRule','-','Undo', 'Redo', '-', 'Source' ],
          ]
        },
        simple: {
          versionCheck: false, // Don't show security warning banner
          extraPlugins: 'autogrow',
          removePlugins: 'elementspath',
          autoGrow_minHeight: 100,
          autoGrow_maxHeight: 400,
          forcePasteAsPlainText: true,
          toolbar: [
            [ 'Bold', 'Italic', '-', 'Link', 'Unlink', '-', 'NumberedList', 'BulletedList', '-','Undo', 'Redo' ],
          ]
        },
        registrationLabel: {
          versionCheck: false, // Don't show security warning banner
          extraPlugins: 'autogrow',
          removePlugins: 'elementspath',
          autoGrow_minHeight: 100,
          autoGrow_maxHeight: 400,
          forcePasteAsPlainText: true,
          toolbar: [
            [ 'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', '-','Undo', 'Redo' ],
          ]
        },
      },
    };
  },
  watch: {
    editorData () {
      // Save Ckeditor value to the Vue Formulate values as String
      this.context.model = this.editorData;
    }
  },
}
</script>

<style lang="scss" scoped>
.formulate-input-element {
  max-width: none;
}
</style>