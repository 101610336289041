<template>
  <div>
    <v-alert
        class="my-2"
        v-if="geolocationHasError"
        dense
        prominent
        text
        type="warning"
    >
      {{ geolocationHasError }}
    </v-alert>

    <div style="height: 4px;">
      <v-progress-linear
          indeterminate
          v-if="loading"
      ></v-progress-linear>
    </div>

    <div ref="map-root" :style="mapStyle">
      <div v-if="showLocateButton" id="gps_map_locate" class="ol-control ol-unselectable locate">
        <button :disabled="selectedLocationManually" title="Ga naar mijn locatie" onclick="return false;"><svg style="width:14px;height:14px" viewBox="0 0 14 14">
          <template v-if="geolocationCenterOnLocation">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-arrow" class="svg-inline--fa fa-location-arrow fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M444.52 3.52L28.74 195.42c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 51.17 70.36 67.17 92.75 19.19l191.9-415.78c15.99-38.39-25.59-79.97-63.97-63.97z"></path></svg>
          </template>
          <template v-else>
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="location-arrow" class="svg-inline--fa fa-location-arrow fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M461.9 0c-5.73 0-11.59 1.1-17.39 3.52L28.74 195.41c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 30.01 24.21 47.93 48.74 47.93 17.3 0 34.75-8.9 44.01-28.74l191.9-415.78C522.06 34.89 494.14 0 461.9 0zM271.81 464.07V240.19h-47.97l-175.48.71c-.27-.37-.47-1.35.48-1.93L462.05 48.26c.61.41 1.28 1.07 1.69 1.68L271.81 464.07z"></path></svg>
          </template>
        </svg></button>
      </div>
    </div>
  </div>
</template>

<style>
.locate {
  text-align: right;
  top: 0.5em;
  right: 0.5em;
  display:flex;
  flex-flow: row-reverse;
  align-items:flex-end;
}
</style>

<script>
// importing the OpenLayers stylesheet is required for having
// good looking buttons!
import 'ol/ol.css'

import Vue from "vue";
import VueStatic from 'vue-static'
import View from 'ol/View'
import Map from 'ol/Map'
import Geolocation from 'ol/Geolocation';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
// import VectorSource from 'ol/source/Vector'
// import OSM from 'ol/source/OSM'
// import Style from "ol/style/Style";
// import Fill from "ol/style/Fill";
// import Stroke from "ol/style/Stroke";
// import Point from 'ol/geom/Point';
// import Circle from 'ol/style/Circle'
import {fromLonLat} from 'ol/proj';
// import Feature from 'ol/Feature';
import {Circle as CircleStyle, Fill, Stroke, Style, Text,} from 'ol/style';
// import Cluster from 'ol/source/Cluster'
import {Cluster, OSM, Vector as VectorSource} from 'ol/source';
import RegularShape from "ol/style/RegularShape";
import featureStyle from "@/mixins/featureStyle";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import {Attribution, defaults as defaultControls} from 'ol/control';
import LineString from "ol/geom/LineString";
import Control from "ol/control/Control";
import _ from "lodash";


Vue.use(VueStatic);

// See example from:
// https://dev.to/camptocamp-geo/integrating-an-openlayers-map-in-vue-js-a-step-by-step-guide-2n1p


export default {
  name: 'MapContainer',
  components: {},
  mixins: [
    featureStyle
  ],
  props: {
    mapStyle: {
      type: String,
      required: false,
      default: 'width: 100%; height: 25vh;',
    },
    mapSettings: {
      type: Object,
    },
    selectedFeatures: {
      type: Array,
      required: false
    },
    trackingEnabled: {
      type: Boolean,
      default: false,
    },
    trackedLocations: {
      required: false,
    },
    showLocateButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      selectedLocationManually: false,
      geolocationCenterOnLocation: false,
      geolocationHasError: false,
      trackFeatureBufferInterval: null,
      accuracyBuffer: [],
    }
  },
  computed: {
  },
  static() {
    return {
      setManualLocation: true,
      source: null,
      vectorLayer: null,
      trackFeature: null,
      trackFeatureBuffer: null,
      sourceCenterPin: null,
      vectorLayerCenterPin: null,
      clusterSource: null,
      clustersLayer: null,
      geolocation: null,
      olView: null,
      olMap: null,
      featureStyleCache: {}, // Feature styles
      clusterStyleCache: {}, // Cluster styles,
      // requestCancelToken: undefined
    };
  },
  watch: {
    'mapSettings.center': {
      deep: true,
      handler(value) {
        this.olView.setCenter(fromLonLat([value.lon, value.lat]))
      }
    },
    'mapSettings.zoomlevel': function(value) {
      this.olView.setZoom(value)
    },
    'mapSettings.featureStyles': function() {
      // Refresh map when mapSettings change
      this.refreshMap()
    },
  },
  mounted() {
    // Override default setting if it's given in the element settings (older form elements dont have this and default to yes!)
    if (this.mapSettings.setManualLocation !== undefined) {
      this.setManualLocation = this.mapSettings.setManualLocation;
    }

    let createTextStyle = function (feature, fontsize, offsetY) {
      let font = fontsize+'px "Roboto", sans-serif'
      let textColor = new Fill({color: '#222'})
      if (feature.get('selected')) {
        font = 'bold '+fontsize+'px "Roboto", sans-serif'
        textColor = new Fill({color: '#1976d2'})
      }

      return new Text({
        text: getText(feature),
        font: font,
        // stroke: new Stroke({color: [0, 0, 0, 1], width: 3}),
        fill: textColor,
        offsetY: offsetY
      })
    };

    let getText = function (feature) {
      return feature.get('name');
    };

    function styleFunction(feature, resolution) {

      let strokeStyle = undefined
      if (feature.get('selected')) {
        strokeStyle = new Stroke({color: '#1976d2', width: 3})
      }

      // Calculate radius
      let radius = 6 / resolution
      if (radius < 5) {
        radius = 5
      }
      else if (radius > 16) {
        radius = 16
      }
      // Get color from status
      let fillColor = $vm.featureStyle($vm.mapSettings, feature)

      let style = {
        image: new CircleStyle({
          radius: radius,
          fill: new Fill({color: fillColor}),
          stroke: strokeStyle,
        }),
      }

      // Show feature text when zoomed in
      if (resolution < 2) {
        // Calculate fontsize
        let fontsize = radius*1.2
        if (fontsize < 10) {
          fontsize = 10
        }
        // Calculate offset
        let offsetY = radius + (fontsize/2) + 3
        // Add feature text
        style.text = createTextStyle(feature, fontsize, offsetY)
      }

      return new Style(style);
    }

    // Make Vue 'this' accessible inside other functions
    let $vm = this;

    const trackStyle = new Style({
      stroke: new Stroke({
        color: 'rgba(0,0,255,1.0)',
        width: 3,
        lineCap: 'round'
      })
    });

    const trackStyleBuffer = new Style({
      stroke: new Stroke({
        color: 'rgba(0,0,255,0.5)',
        width: 3,
        lineCap: 'round'
      })
    });

    this.trackFeature = new Feature({
      geometry: new LineString([])
    });
    this.trackFeature.setStyle(trackStyle)

    this.trackFeatureBuffer = new Feature({
      geometry: new LineString([])
    });
    this.trackFeatureBuffer.setStyle(trackStyleBuffer)

    this.trackFeatureBufferInterval = setInterval(() => {
      // Add the buffer to the permanent values
      this.addBufferToGeometry()
    }, 15000)

    // we'll need a vector layer to render it
    const trackLayer = new VectorLayer({
      source: new VectorSource({
        features: [this.trackFeature, this.trackFeatureBuffer]
      }),
    });

    // This hold all features
    this.source = new VectorSource();
    // a new vector layer is created with the feature
    this.vectorLayer = new VectorLayer({
      name: 'Features',
      source: this.source,
      minResolution: 0,
      maxResolution: 3,
      style: styleFunction,
    })

    // This hold the center pin when tracking the user his location
    this.sourceCenterPin = new VectorSource();
    // a new vector layer is created with the feature
    this.vectorLayerCenterPin = new VectorLayer({
      name: 'CenterPin',
      source: this.sourceCenterPin,
    })

    // Add cluster layer in between (is only used when the function setFeaturesClusters) is called)
    this.clusterSource = new Cluster({
      distance: 40,
      source: this.source
    });

    this.clustersLayer = new VectorLayer({
      name: 'Cluster',
      source: this.clusterSource,
      minResolution: 3,
      // maxResolution: 20000,
      style: function(feature) {
        let size = feature.get('features').length;
        let style = $vm.clusterStyleCache[size];
        if (!style) {
          style = new Style({
            image: new RegularShape({
              points: 4,
              radius: 20 / Math.SQRT2,
              radius2: 20,
              angle: 0,
              fill: new Fill({
                color: '#41b883'
              })
            }),
            text: new Text({
              text: size.toString(),
              font: '11px "Roboto", sans-serif',
              fill: new Fill({color: '#222'})
            })
          });
          $vm.clusterStyleCache[size] = style;
        }
        return style;
      }
    });


    this.olView = new View({
      zoom: this.mapSettings.zoomlevel,
      center: fromLonLat([this.mapSettings.center.lon, this.mapSettings.center.lat]),
      constrainResolution: true
    })

    const attribution = new Attribution({
      collapsible: true,
      collapsed: true
    });

    // this is where we create the OpenLayers map
    this.olMap = new Map({
      // the map will be created using the 'map-root' ref
      target: this.$refs['map-root'],
      layers: [
        // adding a background tiled layer
        new TileLayer({
          source: new OSM() // tiles are served by OpenStreetMap
        }),
        // the vector layer is added above the tiled OSM layer
        this.clustersLayer,
        this.vectorLayer,
        this.vectorLayerCenterPin,
      ],
      // Set map view
      view: this.olView,
      controls: defaultControls({attribution: false}).extend([attribution]),
    });

    this.olMap.addLayer(trackLayer);

    // On feature click
    if (this.setManualLocation === true) {
      this.olMap.on('singleclick', function (e) {
        let featuresClicked = $vm.olMap.getFeaturesAtPixel(e.pixel);
        // Check we have a manually selected location and if a feature is clicked that's not the accuracy
        if ($vm.selectedLocationManually && Array.isArray(featuresClicked) && featuresClicked.length >= 1 && featuresClicked[0].getId() !== 'accuracy') {
          // Unselect and restart tracking
          $vm.selectedLocationManually = false;
          $vm.sourceCenterPin.clear() // Remove previous center pins
          $vm.getLocation();
          $vm.$emit('changedLocation', {coordinate: $vm.geoLocation.getPosition(), accuracy: $vm.geoLocation.getAccuracy()});
        }
        else {
          // Create feature and pause tracking
          $vm.selectedLocationManually = true;
          $vm.removeEventListeners()
          $vm.sourceCenterPin.clear() // Remove previous center pins

          const positionFeature = new Feature();
          positionFeature.setId('position');
          positionFeature.setStyle(
              new Style({
                image: new CircleStyle({
                  radius: 6,
                  fill: new Fill({
                    color: '#3399CC',
                  }),
                  stroke: new Stroke({
                    color: '#fff',
                    width: 2,
                  }),
                }),
              })
          );
          positionFeature.setGeometry(new Point(e.coordinate));

          $vm.sourceCenterPin.addFeature(positionFeature); // Add center pin
          $vm.$emit('changedLocation', e.coordinate);

          $vm.olView.setCenter(e.coordinate);
          // $vm.olView.setZoom(18);
        }

      });
    }

    this.olMap.on('pointerdrag', function () {
      // Disable center on location
      $vm.geolocationCenterOnLocation = false
    });

    // Added to fix issue where trackedLocation isn't shown when loading in a previously saved form
    if (_.isArray(this.trackedLocations) && !_.isEmpty(this.trackedLocations)) {
      for (let i = 0; i < this.trackedLocations.length; i++) {
        let coords = this.trackedLocations[i];
        this.trackFeature.getGeometry().appendCoordinate(fromLonLat([coords.lon, coords.lat]));
      }

      this.olView.fit(this.trackFeature.getGeometry());
    }

    if (this.mapSettings.tracking) {
      this.geolocationCenterOnLocation = true;
    }

    if (this.showLocateButton) {
      // Bind event to button
      const locate = document.getElementById('gps_map_locate');
      locate.addEventListener('click', this.mapLocate);
      this.olMap.addControl(new Control({
        element: locate,
      }));
    }

    // Automatically go to location on mounted
    this.getLocation();
  },
  methods: {
    getLocation() {
      // Toggle button
      this.geolocationCenterOnLocation = true
      // Bind geolocation when not set yet.
      if (!this.geoLocation) {
        // Sets up the location
        this.geoLocation = new Geolocation({
          trackingOptions: {
            enableHighAccuracy: true,
          },
          projection: this.olView.getProjection(),
          tracking: true,
        });
      }
      else {
        let centerPin = this.sourceCenterPin.getFeatureById('position');
        if (centerPin === null) {
          this.setLocationPin();
        }
        else {
          this.centerOnLocation();
        }
      }

      this.geoLocation.addEventListener('error', this.errorLocation);
      // If we dont have a position yet we set the event listener, else we'll just set the location
      // this.geoLocation.addEventListener('change:position', this.setLocation)
      // this.geoLocation.addEventListener('change:accuracy', this.setAccuracy)
      this.geoLocation.addEventListener('change', this.setLocationPin);
      return this;
    },
    setLocationPin() {
      let coordinates = this.geoLocation.getPosition();
      if (coordinates) {
        let positionFeature = this.sourceCenterPin.getFeatureById('position');
        if (positionFeature === null) {
          positionFeature = new Feature();
          positionFeature.setId('position');
          positionFeature.setStyle(
              new Style({
                image: new CircleStyle({
                  radius: 6,
                  fill: new Fill({
                    color: '#3399CC',
                  }),
                  stroke: new Stroke({
                    color: '#fff',
                    width: 2,
                  }),
                }),
              })
          );

          positionFeature.setGeometry(this.geoLocation.getPosition() ? new Point(this.geoLocation.getPosition()) : null);
          this.sourceCenterPin.addFeature(positionFeature);
        }
        else {
          positionFeature.setGeometry(this.geoLocation.getPosition() ? new Point(this.geoLocation.getPosition()) : null);
        }
        this.setAccuracy();

        if (this.trackingEnabled) {
          // Add coordinates to buffer
          this.trackFeatureBuffer.getGeometry().appendCoordinate(coordinates);
          // Add accuracy to buffer
          this.accuracyBuffer.push(this.geoLocation.getAccuracy());
        }

        // Center camera on the geolocation when flag is true
        if (this.geolocationCenterOnLocation) {
          this.centerOnLocation()
        }

        // this.markerLocation =
        if (this.mapSettings.tracking) {
          // Keep location
        }
        else {
          this.removeEventListeners() // @TODO: Remove this when Rutger wants automatic geolocation again.
        }
      }
    },
    addBufferToGeometry() {
      // Get simplified geometry
      let simplifiedGeometry = this.trackFeatureBuffer.getGeometry().simplify(2);
      let simplifiedCoordinates = simplifiedGeometry.getCoordinates();
      // Loop over all points and add them
      if (simplifiedCoordinates.length > 1) {

        for (let i = 0; i < simplifiedCoordinates.length; i++) {
          let coords = simplifiedCoordinates[i];
          this.trackFeature.getGeometry().appendCoordinate(coords);
          // Get the average accuracy
          let average_accuracy = 0;
          if (this.accuracyBuffer.length) {
             average_accuracy = this.accuracyBuffer.reduce((a, b) => a + b) / this.accuracyBuffer.length;
          }
          // Emit all changed positions
          this.$emit('changedLocation', {coordinate: coords, accuracy: Math.round(average_accuracy)});
        }

        // Clear the buffer
        this.trackFeatureBuffer.setGeometry(new LineString([]));
        // Clear the accuracy buffer
        this.accuracyBuffer = []
        // (re)add the new starting position for the buffer linestring
        let lastCoordinate = simplifiedCoordinates[simplifiedCoordinates.length - 1];
        this.trackFeatureBuffer.getGeometry().appendCoordinate(lastCoordinate);

      }
    },
    mapLocate() {
      if (this.selectedLocationManually) {
        this.centerOnLocation();
      }
      else {
        this.getLocation();
      }
    },
    setAccuracy() {
      let accuracyFeature = this.sourceCenterPin.getFeatureById('accuracy');
      if (accuracyFeature === null) {
        accuracyFeature = new Feature();
        accuracyFeature.setId('accuracy');
        accuracyFeature.setGeometry(this.geoLocation.getAccuracyGeometry());
        this.sourceCenterPin.addFeature(accuracyFeature); // Add center pin
      }

      accuracyFeature.setGeometry(this.geoLocation.getAccuracyGeometry());
    },
    centerOnLocation() {
      let coordinates;
      let extent;
      if (this.selectedLocationManually && this.sourceCenterPin.getFeatureById('position') !== null) {
        let feature = this.sourceCenterPin.getFeatureById('position');

          coordinates = feature.getGeometry().getCoordinates();
          extent = feature.getGeometry().extent;
      }
      else {
        coordinates = this.geoLocation.getPosition();
        extent = this.geoLocation.getAccuracyGeometry();
      }

      if (this.olView && coordinates) {
        this.olView.setCenter(coordinates);
        if (extent) {
          this.olView.fit(extent);
        }
        else {
          this.olView.setZoom(18);
        }
      }
    },
    errorLocation() {
      this.geolocationHasError = "Locatie kon niet worden bepaald. Check of locatie in uw browser voor deze website is toegestaan.";
    },
    removeEventListeners() {
      this.geoLocation.removeEventListener('error', this.errorLocation);
      this.geoLocation.removeEventListener('change', this.setLocationPin);
    }

  },
  beforeDestroy() {
    // Remove geolocation listener when component gets destroyed.
    this.removeEventListeners()
    // Remove other OL references
    this.source.clear()
    this.vectorLayer.setSource(null)
    this.sourceCenterPin.clear()
    this.vectorLayerCenterPin.setSource(null)
    this.clusterSource = null
    this.clustersLayer.setSource(null)
    this.geolocation = null
    this.olView = null
    this.featureStyleCache = {} // Feature styles
    this.clusterStyleCache = {} // Cluster styles,
    // Remove OL map to avoid memory leaks
    this.olMap.setTarget(null)
    this.olMap = null
    // Clear interval
    clearInterval(this.trackFeatureBufferInterval);
  },
}
</script>
