<template>
  <div>
    <h2>Weet u zeker dat u deze databron wilt verwijderen?</h2>
    <p>De databron wordt permanent verwijderd.</p>
    <v-btn
        :loading="loading"
        :disabled="loading"
        depressed
        color="error"
        class="my-1 white--text"
        @click="deleteDatasource"
    >
      Verwijder databron
    </v-btn>
    <v-btn
        depressed
        outlined
        color="secondary"
        class="ml-3 my-1 white--text"
        router :to="{ name: 'datasource_view', params: {datasource_id: datasource_id} }"
        exact
    >
      Annuleren
    </v-btn>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    datasource_type: null,
    datasource_id: null,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async deleteDatasource() {
      this.loading = true
      // Delete the resource
      axios
          .delete('jsonapi/eform_datasource/'+this.datasource_type+'/'+this.datasource_id)
          .then(() => {
            this.$toast.success("Databron is verwijderd.");
            this.loading = false
            this.$router.push({name: 'datasource_list'});
          })
          .catch(error => {
            console.log(error)
            this.$toast.error("Databron kon niet worden verwijderd.");
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>