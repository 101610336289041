<template>
  <div>
    <h2>Weet u zeker dat u deze klant wilt verwijderen?</h2>
    <p>De klant wordt permanent verwijderd.</p>
    <drupal-batch
        :batch-create-url="'api/client/'+client_id+'/delete'"
        button-label="Verwijder klant"
        button-color="error"
        @batch-started="batchStarted"
        @batch-finished="batchFinished"
    ></drupal-batch>

    <v-btn
        depressed
        outlined
        color="secondary"
        class="my-3 white--text"
        router :to="{ name: 'client_view', params: {client_id: client_id} }"
        exact
    >
      Annuleren
    </v-btn>
  </div>
</template>

<script>
import drupalBatch from "@/components/DrupalBatch";
import store from "@/plugins/store";

export default {
  components: {
    drupalBatch
  },
  props: {
    client_id: null,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    batchStarted() {
      this.loading = true
    },
    batchFinished() {
      this.loading = false
      // Fetch new user info after deleting the client
      store.dispatch('UserInfo')
      // Go back to client overview
      this.$router.push({name: 'client_list'});
    }
  }
}
</script>