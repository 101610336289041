<template>
  <v-sheet class="ma-8 mt-2 pa-4 rounded-lg">
    <v-card-text>

      <v-alert
          type="error"
          v-bind:key="key"
          v-for="(errorMessage, key) in errorMessages"
      >
        {{ errorMessage.detail }}
      </v-alert>

      <FormulateForm #default="{ isLoading }" @submit="submitForm">
        <FormulateInput
            label="Klant naam"
            name="name"
            v-model="name"
            validation="required"
        />

        <FormulateInput
            input-class="v-btn v-size--default success"
            type="submit"
            name="submit"
            :disabled="isLoading"
            :label="isLoading ? 'Laden...' : 'Klant aanmaken'"
        />
      </FormulateForm>

    </v-card-text>
  </v-sheet>
</template>

<script>
import axios from "@/plugins/axios";
import router from "@/plugins/routes";
import store from "@/plugins/store";

export default {
  data () {
    return {
      errorMessages: [],
      isLoading: false,
      name: '',
      type: '',
    }
  },
  methods: {
    // Send the form to the API endpoint
    async submitForm() {

      this.isLoading = true;

      let config = {
        headers: {
          "Accept": "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        }
      }
      let payload = {
        data: {
          type: 'client--client',
          attributes: {
            name: this.name,
          },
        },
      }

      axios.post('jsonapi/client/client', payload, config)
      .then(response => {
        this.loading = false
        this.$toast.success("Klant " + this.name + " is aangemaakt.");
        // Fetch new user info after creating the client
        store.dispatch('UserInfo')
        // Redirect user to the newly created client
        router.push({ name: 'client_view', params: {client_id: response.data.data.id} })
      })
      .catch(error => {
        if (error.response && error.response.data) {
          // Show error message
          if (error.response.data.errors) {
            // Add error messages
            this.errorMessages = error.response.data.errors;
          }
        }
        this.errored = true
      })
      .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>