<template>
  <div
      :class="`formulate-input-element formulate-input-element--${context.type} state-transition-wrapper`"
      :data-type="context.type"
  >

      <v-alert
          color="warning"
          dark
          dense
          text
          class="ma-3"
      >
        Als deze registratie wordt verstuurd, wordt hij naar <strong>fase {{ context.attributes.next_phase }}</strong> verplaatst.
      </v-alert>

  </div>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true
    },
  },
  mounted() {
    this.context.model = {
      next_phase: this.context.attributes.next_phase,
      assign_to_user:  this.context.attributes.assign_to_user,
    }
  },
  computed: {
    model() {
      return this.context.model
    },
  },
}
</script>

<style lang="scss" scoped>

</style>