<template>
  <v-sheet class="ma-8 mt-2 pa-4 rounded-lg">
    <v-card-text>
      <FormulateForm #default="{ isLoading }" @submit="submitForm">
        <FormulateInput
            label="Map naam"
            name="folder_name"
            v-model="folder_name"
            validation="required"
            :validation-messages="{
          required: 'Map naam is verplicht.'
        }"
        />

        <FormulateInput
            v-if="userHasPermission('eform view hidden folders')"
            v-model="hidden"
            type="checkbox"
            label="Map verbergen in beheer omgeving"
            help="Als dit veld aangevinkt is, dan zijn de beheeracties (bijvoorbeeld formulier aanpassen, registraties bekijken) in deze map alleen zichtbaar voor gebruikers met de rol 'Klant beheerder'. De map blijft wel zichtbaar voor iedereen om formulieren in te vullen."
        />
        <FormulateInput
            input-class="v-btn v-size--default primary"
            type="submit"
            name="submit"
            :disabled="isLoading"
            :label="isLoading ? 'Laden...' : 'Map aanmaken'"
        />
      </FormulateForm>
    </v-card-text>
  </v-sheet>
</template>

<script>
import userHelpers from "@/mixins/userHelpers";

export default {
  mixins:[
    userHelpers
  ],
  data () {
    return {
      template: '',
      folder_name: '',
      hidden: false,
      isLoading: false,
    }
  },
  methods: {
    // Send the form to the API endpoint
    async submitForm() {
      try {
        await this.$store.dispatch('createFolder', {folder_name: this.folder_name, hidden: this.hidden});

        this.$toast.success("Map " + this.folder_name + " is aangemaakt.");

        this.$router.back();
      } catch (error) {
        this.$toast.error("De map kon niet worden aangemaakt");
        console.log(error);
      }
    }
  }
}
</script>

<style scoped>

</style>