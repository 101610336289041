<template>
  <v-expansion-panels focusable>
    <v-expansion-panel v-if="!loading">
      <v-expansion-panel-header class="spacer">
        <v-simple-checkbox class="px-2 shrink" v-model="weeklyRegistrationMail.enabled" disabled></v-simple-checkbox>
        <span class="px-2">
          Periodieke registratie emails
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="myForm">
        <p class="text-subtitle-2 text--secondary mt-2 mb-6">
          U heeft de optie om periodiek (wekelijks of maandelijks) een mail te ontvangen met daarin de registraties. Maandelijkse mails worden op de eerste van de maand verstuurd. Voor de wekelijkse mails kunt u zelf een dag kiezen en de registraties zullen dan 's ochtends vroeg in uw mail staan.
        </p>

        <FormulateForm
            v-model="weeklyRegistrationMail"
            @submit="submitForm"
            @failed-validation="onValidationErrors">
          <FormulateInput
              type="checkbox"
              name="enabled"
              label="Periodieke registratie mails versturen"/>
          <FormulateInput
              type="radio"
              name="period"
              value="weekly"
              :options="periodSelectOptions"
            />
          <FormulateInput
              v-show="!weeklyRegistrationMail.period || weeklyRegistrationMail.period === 'weekly'"
              type="select"
              name="day"
              :options="weekDaySelectOptions"
              label="De dag waarop de csv met registraties verstuurd wordt"
              value="6"
          />
          <FormulateInput
              label="Aan e-mailadres"
              name="recipients"
              type="group"
              :repeatable="true"
              add-label="+ Ontvanger toevoegen"
          >
            <FormulateInput
                type="text"
                name="email"
                label="E-mail"
                validation="^required|email"
                :element-class="['no-label']"
                :debounce="100"
            />
          </FormulateInput>

          <FormulateInput
              type="submit"
              input-class="v-btn v-size--default success"
              :disabled="isSaving"
              :label="isSaving ? 'Laden...' : 'Instellingen opslaan'"
          />

        </FormulateForm>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import axios from "axios";

export default {
  name: "PeriodicRegistrationMails",
  props: {
    form_id: String,
  },
  mounted() {
    this.getDataFromApi();
  },
  data() {
    return {
      myForm: {},
      loading: false,
      isSaving: false,
      weekDaySelectOptions: [
        { value: 7, 'label': 'Zondag' },
        { value: 1, 'label': 'Maandag' },
        { value: 2, 'label': 'Dinsdag' },
        { value: 3, 'label': 'Woensdag' },
        { value: 4, 'label': 'Donderdag' },
        { value: 5, 'label': 'Vrijdag' },
        { value: 6, 'label': 'Zaterdag' },
      ],
      periodSelectOptions: [
        { value: 'weekly', label: 'Wekelijks'},
        { value: 'monthly', label: 'Maandelijks'},
      ],
    }
  },
  computed: {
    weeklyRegistrationMail: {
      get() {
        if (this.myForm && this.myForm.settings && this.myForm.settings.weeklyRegistrationMail) {
          return this.myForm.settings.weeklyRegistrationMail;
        }
        return {};
      },
      set(values) {
        if (!this.myForm.settings) {
          this.myForm.settings = {};
        }
        this.$set(this.myForm.settings, 'weeklyRegistrationMail', values);
        this.myForm.settings.weeklyRegistrationMail = values;
      }
    },
  },
  methods: {
    periodIsMonthly() {
      console.log('periodIsMonthly');
      console.log(this.weeklyRegistrationMail);
      return false;
      // return (this.weeklyRegistrationMail.period === 'monthly');
    },
    async getDataFromApi () {
      this.loading = true
      // Fetch results form the API
      axios
          .get('api/form/'+this.form_id)
          .then(response => {
            this.myForm = response.data
            this.loading = false;
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => this.loading = false)
    },
    // Send the form to the API endpoint
    async submitForm() {
      let $vm = this
      this.isSaving = true;

      // Save form
      axios.patch('api/form/'+this.myForm.form_id, this.myForm)
          .then(response => {
            this.myForm = response.data
            $vm.$toast.success("Email instellingen opgeslagen");
          })
          .catch(function (error) {
            $vm.$toast.error("Email instellingen konden niet worden aangepast.");
            // handle error
            console.log(error);
          })
          .finally(function() {
            $vm.isSaving = false
          })
    },
    async onValidationErrors() {
      this.$toast.error("Controleer het formulier. Sommige velden zijn niet of verkeerd ingevuld.");
    },
  },
}
</script>

<style scoped>

</style>