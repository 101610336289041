<template>
  <div>
    <v-container fluid class="pa-md-8 pt-md-2">
      <v-row>
        <v-col>
          <v-card
              color="primary" dark router :to="{name: 'folder_list'}" min-height="130" class="dashboard-block">
            <v-card-title>
              <v-icon left>mdi-pencil-outline</v-icon> Formulieren invullen
            </v-card-title>
            <v-card-subtitle>Ga naar het overzicht van alle formulieren die u kunt invullen.</v-card-subtitle>
          </v-card>
        </v-col>
        <v-col>
          <v-card v-if="userCanNavigateToPage('form_list_admin')" router :to="{name: 'form_list_admin'}" min-height="130" class="dashboard-block">
            <v-card-title>
              <v-icon left>mdi-format-list-bulleted</v-icon> Formulieren beheren
            </v-card-title>
            <v-card-subtitle>
              <template v-if="userHasPermission('eform manage form edit')">
                Bewerk bestaande formulieren of bekijk de registraties.
              </template>
              <template v-else>
                Bekijk het formulier en registraties.
              </template>
            </v-card-subtitle>
          </v-card>
          <v-card
              v-if="userHasPermission('eform manage forms')"
              router :to="{name: 'form_create'}"
              class="mt-5 dashboard-block"
              min-height="130"
          >
            <v-card-title>
              <v-icon left>mdi-plus</v-icon> Formulier aanmaken
            </v-card-title>
            <v-card-subtitle>Cre&euml;er nieuwe formulieren.</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <pwa-install-prompt></pwa-install-prompt>

  </div>
</template>

<script>
import userHelpers from "@/mixins/userHelpers";
import PwaInstallPrompt from "@/components/PwaInstallPrompt";

export default {
  name: "Dashboard",
  components: {
    PwaInstallPrompt,
  },
  mixins: [
      userHelpers,
  ],
}
</script>

<style scoped lang="scss">
.dashboard-block {
  .v-card__title {
    word-break: normal;
  }
}
</style>