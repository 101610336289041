import Vue from 'vue';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
    position: "top-center",
    timeout: 4000,
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true,
    hideProgressBar: true,
}

Vue.use(Toast, options);

export default Toast