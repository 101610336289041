<template>
  <v-card>

    <v-card-title>Rollen</v-card-title>
    <v-card-text>

      <v-checkbox disabled class="my-0 py-0" input-value="true">
        <template v-slot:label>
          <div>
            Formulier invuller
          </div>
        </template>
      </v-checkbox>

      <div v-for="(item,i) in user_roles" :key="i">
        <v-checkbox v-model="rolesSelected" :value="item.id" :disabled="item.disabled" v-show="!item.disabled" class="my-0 py-0">
          <template v-slot:label>
            <div>
              {{ item.label }}
              <v-tooltip bottom color="secondary" style="z-index: 10;">

                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-information-outline</v-icon>
                </template>

                <template v-if="item.id === 'client_manager'">
                  <strong>Formulieren</strong>
                  <ul>
                    <li>Formulieren aanmaken/bewerken/bekijken/verwijderen</li>
                    <li>Gebruikers toegang geven tot formulier</li>
                    <li>Registraties bekijken</li>
                  </ul>
                  <strong>Mappen</strong>
                  <ul>
                    <li>Mappen aanmaken/bewerken/verwijderen</li>
                    <li>Mappen verbergen</li>
                  </ul>
                  <strong>Databronnen</strong>
                  <ul>
                    <li>Databronnen aanmaken/bewerken/bekijken/verwijderen</li>
                  </ul>
                  <strong>Klanten</strong>
                  <ul>
                    <li>Klant bekijken en aanpassen</li>
                    <li>Gebruikers aanmaken, bewerken en verwijderen</li>
                    <li>Gebruikers rollen geven</li>
                    <li>Groepen aanmaken, bewerken en verwijderen</li>
                    <li>Gebruikers toewijzen aan groepen</li>
                    <li>Credits inzien/kopen</li>
                  </ul>
                </template>
                <template v-else-if="item.id === 'financial'">
                  <strong>Klanten</strong>
                  <ul>
                    <li>Klant bekijken</li>
                    <li>Credits inzien/kopen</li>
                  </ul>
                </template>
                <template v-else-if="item.id === 'form_manager'">
                  <strong>Formulieren</strong>
                  <ul>
                    <li>Formulieren aanmaken/bewerken/bekijken/verwijderen</li>
                    <li>Gebruikers toegang geven tot formulier</li>
                    <li>Registraties bekijken</li>
                  </ul>
                  <strong>Mappen</strong>
                  <ul>
                    <li>Mappen aanmaken/bewerken/verwijderen (behalve verborgen mappen)</li>
                  </ul>
                  <strong>Databronnen</strong>
                  <ul>
                    <li>Databronnen aanmaken/bewerken/bekijken/verwijderen</li>
                  </ul>
                  <strong>Klanten</strong>
                  <ul>
                    <li>Gebruikers aanmaken, bewerken en verwijderen</li>
                    <li>Gebruikers rollen geven</li>
                    <li>Groepen aanmaken, bewerken en verwijderen</li>
                    <li>Gebruikers toewijzen aan groepen</li>
                  </ul>
                </template>
                <template v-else-if="item.id === 'form_view_registrations'">
                  <strong>Formulieren</strong>
                  <ul>
                    <li>Formulier bekijken</li>
                    <li>Registraties bekijken</li>
                  </ul>
                </template>
                <template v-else-if="item.id === 'user_manager'">
                  <strong>Klanten</strong>
                  <ul>
                    <li>Gebruikers aanmaken, bewerken en verwijderen</li>
                    <li>Gebruikers rollen geven</li>
                    <li>Groepen aanmaken, bewerken en verwijderen</li>
                    <li>Gebruikers toewijzen aan groepen</li>
                  </ul>
                </template>

              </v-tooltip>
            </div>
          </template>
        </v-checkbox>
      </div>

    </v-card-text>
  </v-card>
</template>
<script>

export default {
  props: {
    roles: [],
    user_roles: [],
  },
  data () {
    return {
      rolesSelected: [],
    }
  },
  mounted () {
    this.rolesSelected = this.roles
  },
  watch: {
    rolesSelected() {
      this.$emit('roles-changed', this.rolesSelected)
    },
  },
}
</script>
<style lang="scss">

</style>