<template>
  <div
      :class="`formulate-input-element formulate-input-element--${context.type}`"
      :data-type="context.type"
  >

    <gps-tracking-map :show-locate-button="true" :map-settings="context.attributes.mapSettings" @changedLocation="changedLocation($event)"></gps-tracking-map>


    <div class="warning--text" v-if="isAdminMode">
      Element is uitgeschakeld bij formulier beheren. Locatie wordt niet opgeslagen in het formulier.
    </div>
    <div class="error--text" v-else-if="locationEmpty()">
      Locatie is nog niet gevonden
    </div>
    <div v-else-if="locationAccurate()" class="green--text">
      Locatie gevonden tot {{ Math.round(location.accuracy) }}m nauwkeurig
    </div>
    <div class="error--text" v-else>
      Gevonden locatie niet nauwkeurig genoeg! Locatie moet tot {{context.attributes.mapSettings.minAccuracy }}m nauwkeurig zijn (Nu {{ Math.round(location.accuracy) }}m)
    </div>
  </div>
</template>

<script>
import GpsTrackingMap from "@/components/maps/GpsTrackingMap";
import featureStyle from "@/mixins/featureStyle";
import {toLonLat} from "ol/proj";

export default {
  components: {
    GpsTrackingMap
  },
  mixins: [
    featureStyle
  ],
  props: {
    isAdminMode: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      location: {
        lat: 0,
        lon: 0,
        accuracy: -1,
      },
    }
  },
  mounted() {
    // Sets it to undefined in case of an old element. Now we can just resave it to fix the default value issue
    if (this.isAdminMode) {
      this.context.model = undefined;
    }
  },
  computed: {
  },
  watch: {
    location(values) {
      if (!this.isAdminMode && !this.locationEmpty() && values.accuracy <= this.context.attributes.mapSettings.minAccuracy) {
        this.context.model = values;
      }
      else {
        this.context.model = undefined;
      }
    }
  },
  methods: {
    changedLocation(loc) {
      if (Array.isArray(loc)) {
        let coords = toLonLat(loc);
        this.location = {
          lat: coords[0],
          lon: coords[1],
          accuracy: 0,
        }
      }
      else if (loc && loc.getPosition()) {
        let coords = toLonLat(loc.getPosition());
        this.location = {
          lat: coords[0],
          lon: coords[1],
          coords: toLonLat(loc.getPosition()),
          accuracy: loc.getAccuracy(),
        }
      }
      else {
        this.location = {
          lat: 0,
          lon: 0,
          accuracy: 0,
        }
      }

    },
    featureColor(feature) {
      // Get color from status
      return this.featureStyle(this.context.attributes.mapSettings, feature)
    },
    locationEmpty() {
      return (this.location.lat === 0 && this.location.lon === 0);
    },
    locationAccurate() {
      return (!this.locationEmpty() && this.location.accuracy <= this.context.attributes.mapSettings.minAccuracy);
    }
  }
}
</script>

<style lang="scss" scoped>
.formulate-input-element--eformMap {
  max-width: 100%;
}
.feature-details-table {
  width: 100%;

  td {
    border: 1px solid #eee;
    padding: 5px;
  }
}
</style>