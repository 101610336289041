import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters({ClientInfo: "StateClientInfo"}),
    },
    methods: {
        configureLoggingUser() {
            // Get user ID and username from the store
            let user_email = this.$store.getters.getUserEmail ?? null;
            let user_id = this.$store.getters.getUserId ?? null;
            // Early return if we don't have user data
            if (!user_email || !user_id) {
                return;
            }
            // Add user data to rollbar so we can identify the user when an error occurs.
            this.$rollbar.configure({
                payload: {
                    person: {
                        id: user_id, // required
                        username: user_email
                    }
                }
            });
        },
    }
};