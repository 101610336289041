<template>

  <v-card class="elevation-1 login-wrapper">
    <v-card-title>U wordt nu uitgelogd..</v-card-title>
  </v-card>

</template>

<script>
export default {
  name: "Logout",
  mounted () {
    let $vue = this
    // Logout user
    $vue.logout()
  },
  methods: {
    async logout() {
      await this.$store.dispatch('LogOut')
      this.$router.push({name: 'login'})
    },
  }
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  max-width: 400px;
  margin: 10vh auto;
}
</style>