<template>
  <div>
    <v-sheet class="pa-4 rounded-lg">

      <v-row v-if="isLoading">
        <v-col cols="8">
          <v-sheet class="pa-4 rounded-lg">
            <v-skeleton-loader type="sentences, text@3, image"></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>

      <v-card-text v-if="!isLoading">

        <p v-html="registrationIntro"></p>

        <v-alert
            type="error"
            v-bind:key="key"
            v-for="(errorMessage, key) in errorMessages"
            dismissible
        >
          {{ errorMessage }}
        </v-alert>

        <v-row>
          <v-col>
            <h4>Klant naam</h4>
            <div class="value">{{ client.name }}</div>
          </v-col>
        </v-row>

        <v-row v-if="form_id && formExists">
          <v-col>
            <h4>Formulier naam</h4>
            <div class="value">{{ form.form_name }}</div>
          </v-col>
        </v-row>

        <FormulateForm #default="{ isSaving }" @submit="submitForm" class="mt-8">

          <FormulateInput
              type="email"
              label="E-mailadres"
              v-model="email"
              validation="required|email"
          />

          <FormulateInput
              input-class="v-btn v-size--default success"
              type="submit"
              name="submit"
              :disabled="isSaving"
              :label="isSaving ? 'Laden...' : 'Registeren'"
              class="mt-5"
          />
        </FormulateForm>

      </v-card-text>

    </v-sheet>

    <v-dialog v-model="registrationCompleted" max-width="800" persistent scrollable>
      <v-card>
        <v-card-title>Registratie voltooid</v-card-title>
        <v-card-text>
          {{ registrationCompletedStatusText }}
        </v-card-text>
        <v-card-actions class="background-color">
          <v-btn
              depressed
              color="secondary"
              router :to="{ name: 'home'}"
              exact
          >
            Terug naar home
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "@/plugins/axios";
import normalize from "json-api-normalize";
import _ from "lodash";
// import router from "@/plugins/routes";

export default {
  props: {
    client_uuid: null,
    form_id: null,
  },
  data () {
    return {
      errorMessages: [],
      isLoadingClient: true,
      isLoadingForm: false,
      isSaving: false,
      email: '',
      client: {},
      form: {},
      registrationCompleted: false,
      registrationCompletedStatusText: '',
    }
  },
  computed: {
    isLoading() {
      return this.isLoadingClient || this.isLoadingForm
    },
    clientExists() {
      return Object.keys(this.client).length > 0
    },
    formExists() {
      return Object.keys(this.form).length > 0
    },
    registrationIntro() {
      if (_.has(this.client.user_register_intro_text, 'value') && !_.isEmpty(this.client.user_register_intro_text.value)) {
        // Replace newlines with <br> tags
        return this.client.user_register_intro_text.value.replace(/(?:\r\n|\r|\n)/g, '<br>');
      }
      return ''
    },
  },
  async mounted () {
    // Load client and form
    await this.loadClient()
    if (this.form_id) {
      await this.loadForm()
    }
  },
  methods: {
    async loadClient() {
      // Fetch results form the API
      return axios
          .get('jsonapi/client/client/'+this.client_uuid)
          .then(response => {
            // Add normalized client
            this.client = normalize(response.data).get([
              'name',
              'user_register_intro_text',
            ])
            this.isLoadingClient = false;
          })
          .catch(error => {
            console.log(error)
            // Redirect to 404
            this.$toast.error("Registratie link is niet geldig. De klant bestaat niet.");
            this.$router.push({ name: '404'});
          })
          .finally(() => this.isLoadingClient = false)
    },
    async loadForm () {
      // Fetch results form the API
      return axios
          .get('api/form/' + this.form_id + '/anonymous')
          .then(response => {
            this.form = response.data;

            // Show 404 error when the form does not belong to the client
            if (this.client_uuid !== this.form.client_uuid) {
              // Redirect to 404
              this.$toast.error("Registratie link is niet geldig. Het formulier bestaat niet of hoort niet bij deze klant.");
              this.$router.push({ name: '404'});
            }

            this.isLoadingForm = false;
          })
          .catch(error => {
            console.log(error)
            // Redirect to 404
            this.$toast.error("Registratie link is niet geldig. Het formulier bestaat niet of hoort niet bij deze klant.");
            this.$router.push({ name: '404'});
          })
          .finally(() => this.isLoadingForm = false)
    },
    // Send the form to the API endpoint
    async submitForm() {
      this.isSaving = true;

      let payload = {
        email: this.email,
      }
      if (this.client_uuid) {
        payload.client_uuid = this.client_uuid
      }
      if (this.form_id) {
        payload.form_id = this.form_id
      }

      axios.post('api/user/register/', payload)
          .then(response => {
            this.isSaving = false
            this.registrationCompletedStatusText = response.data;
            this.registrationCompleted = true
            // router.push({ name: 'client_users', params: {client_uuid: this.client_uuid} })
          })
          .catch(error => {
            if (error.response && error.response.data) {
              // Add error messages
              this.errorMessages.push(error.response.data);
            }
            this.errored = true
          })
          .finally(() => this.isSaving = false)
    }
  }
}
</script>

<style scoped>

</style>