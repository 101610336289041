<template>
 <div>

   <v-btn
       v-if="!batch_processing"
       depressed
       :color="buttonColor"
       @click="batchCreate">
     {{ buttonLabel }}
   </v-btn>

   <v-progress-linear
       v-if="batch_processing"
       :value="progress_percentage"
       :indeterminate="!processed_items"
       height="25">
     <strong>{{ progress_percentage }}% ({{ processed_items }}/{{ total_items }})</strong>
   </v-progress-linear>

 </div>
</template>
<script>
import axios from "axios";

export default {
  props: {
    batchCreateUrl: {
      type: String,
    },
    buttonLabel: {
      type: String,
      default: 'Import starten'
    },
    buttonColor: {
      type: String,
      default: 'primary'
    }
  },
  data() {
    return {
      loading: true,
      batch_name: '',
      total_items: 0,
      processed_items: 0,
      batch_processing: false,
    }
  },
  computed: {
    progress_percentage() {
      if (this.total_items > 0) {
        let progressPercentage = (this.processed_items / this.total_items) * 100;
        return (Math.ceil(progressPercentage))
      }
      else {
        return 0;
      }
    }
  },
  methods: {
    batchCreate() {
      // Emit started event
      this.$emit('batch-started');
      // Start the first batch process
      this.batch_processing = true
      this.processed_items = 0
      // Fetch results form the API
      axios
          .get(this.batchCreateUrl)
          .then(response => {
            if (response.data) {
              // Emit created event
              let created_data = {};
              if (response.data.created_data) {
                created_data = response.data.created_data;
              }
              this.$emit('batch-created', {data: created_data});
              // Set queue data
              this.batch_name = response.data.batch_name
              this.total_items = response.data.total_items
              this.batchProcess()
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => this.loading = false)

    },
    batchProcess() {
      // Fetch results form the API
      axios
          .get('api/batch/process/'+this.batch_name)
          .then(response => {
            if (response.data) {
              // Set queue data
              this.processed_items = this.total_items - response.data.items_in_queue
              // Start a new batch processed when there are still items left in the queue
              if (response.data.items_in_queue > 0) {
                // Start the first batch process
                this.batchProcess()
              }
              else {
                // Batch complete
                this.batch_processing = false
                // Emit finished event
                this.$emit('batch-finished');
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
    }
  }
}
</script>
<style lang="scss">

</style>
