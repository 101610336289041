<template>
  <v-sheet class="ma-8 mt-2 pa-4 rounded-lg">
    <v-card-text>

      <v-alert
          type="error"
          v-bind:key="key"
          v-for="(errorMessage, key) in errorMessages"
      >
        {{ errorMessage.detail }}
      </v-alert>

      <FormulateForm #default="{ isLoading }" @submit="submitForm">
        <FormulateInput
            label="Naam"
            name="name"
            v-model="name"
            validation="required"
        />
        <FormulateInput
            label="Bedrijf"
            name="contact_company"
            v-model="contact_company"
            validation="required"
        />
        <FormulateInput
            label="T.a.v."
            name="contact_addressed_to"
            v-model="contact_addressed_to"
            validation="required"
        />
        <FormulateInput
            type="textarea"
            label="Straat + huisnummer"
            name="contact_street"
            v-model="contact_street"
            validation="required"
        />
        <FormulateInput
            type="textarea"
            label="Postcode"
            name="contact_postal_code"
            v-model="contact_postal_code"
            validation="required"
        />
        <FormulateInput
            type="textarea"
            label="Plaats"
            name="contact_city"
            v-model="contact_city"
            validation="required"
        />
        <FormulateInput
            type="email"
            label="E-mailadres"
            name="contact_email"
            v-model="contact_email"
            validation="required|email"
        />
        <FormulateInput
            label="BTW-nummer"
            name="contact_tax_number"
            v-model="contact_tax_number"
        />
        <FormulateInput
            type="number"
            v-model="credits_total"
            label="Totaal aantal credits"
            help="Het totale aantal gekochte credits."
            validation="required|number"
            min="0"
        />
        <FormulateInput
            type="date"
            label="Vervaldatum credits"
            help="Overgebleven credits zijn na deze datum niet meer te gebruiken."
            name="credit_expire_date"
            v-model="credit_expire_date"
        />

        <FormulateInput
            input-class="v-btn v-size--default success"
            type="submit"
            name="submit"
            :disabled="isLoading"
            :label="isLoading ? 'Laden...' : 'Credits toevoegen'"
        />
      </FormulateForm>

    </v-card-text>
  </v-sheet>
</template>

<script>
import axios from "@/plugins/axios";
import router from "@/plugins/routes";
import moment from "moment";

export default {
  props: {
    client_id: null,
  },
  data () {
    return {
      errorMessages: [],
      isLoading: false,
      name: '',
      contact_company: '',
      contact_addressed_to: '',
      contact_street: '',
      contact_postal_code: '',
      contact_city: '',
      contact_email: '',
      contact_tax_number: '',
      credits_total: 0,
      credit_expire_date: moment().add(2, 'years').format("YYYY-MM-DD"),
    }
  },
  mounted() {
    // Check if logged-in user is admin
    let isAdmin = this.$store.getters.userIsAdmin;

    if (isAdmin) {
      this.name = 'Credits handmatig toegevoegd door E-formapp';
      this.contact_addressed_to = 'E-formapp';
      this.contact_company = 'E-formapp';
      this.contact_street = 'Veerdijk 40G';
      this.contact_postal_code = '1531 MS';
      this.contact_email = 'info@e-formapp.nl';
      this.contact_city = 'Wormer';
    }
  },
  methods: {
    // Send the form to the API endpoint
    async submitForm() {

      this.isLoading = true;

      let config = {
        headers: {
          "Accept": "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        }
      }
      let payload = {
        data: {
          type: 'client--client',
          attributes: {
            name: this.name,
            contact_company: this.contact_company,
            contact_addressed_to: this.contact_addressed_to,
            contact_address: this.contact_street + '\n' + this.contact_postal_code + ' ' + this.contact_city,
            contact_email: this.contact_email,
            contact_tax_number: this.contact_tax_number,
            credits_total: this.credits_total,
            credit_expire_date: this.credit_expire_date,
          },
          relationships: {
            client_id: {
              data: {
                type: 'client--client',
                id: this.client_id
              }
            },
          }
        },
      }

      axios.post('jsonapi/eform_credit/eform_credit', payload, config)
          .then(() => {
            this.loading = false
            this.$toast.success("Credits zijn toegevoegd aan klant.");
            router.push({ name: 'client_credits', params: {client_id: this.client_id} })
          })
          .catch(error => {
            if (error.response && error.response.data) {
              // Show error message
              if (error.response.data.errors) {
                // Add error messages
                this.errorMessages = error.response.data.errors;
              }
            }
            this.errored = true
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>