<template>
  <div>

    <v-app-bar app dense height="48" color="white" elevate-on-scroll>
      <v-app-bar-title>{{ form_name }}</v-app-bar-title>
    </v-app-bar>

    <p class="pl-4">Databron: {{ datasource_name }}</p>

    <div>
      <v-alert
          class="my-2"
          v-if="geolocationHasError"
          dense
          prominent
          text
          type="warning"
      >
        {{ geolocationHasError }}
      </v-alert>

      <div style="height: 4px;">
        <v-progress-linear
            indeterminate
            v-if="loading"
        ></v-progress-linear>
      </div>

      <div ref="map-root" style="width: 100%; height: 50vh;">
        <div id="map_locate" class="ol-control ol-unselectable locate">
          <button title="Ga naar mijn locatie" onclick="return false;"><svg style="width:14px;height:14px" viewBox="0 0 14 14">
            <template v-if="geolocationCenterOnLocation">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-arrow" class="svg-inline--fa fa-location-arrow fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M444.52 3.52L28.74 195.42c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 51.17 70.36 67.17 92.75 19.19l191.9-415.78c15.99-38.39-25.59-79.97-63.97-63.97z"></path></svg>
            </template>
            <template v-else>
              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="location-arrow" class="svg-inline--fa fa-location-arrow fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M461.9 0c-5.73 0-11.59 1.1-17.39 3.52L28.74 195.41c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 30.01 24.21 47.93 48.74 47.93 17.3 0 34.75-8.9 44.01-28.74l191.9-415.78C522.06 34.89 494.14 0 461.9 0zM271.81 464.07V240.19h-47.97l-175.48.71c-.27-.37-.47-1.35.48-1.93L462.05 48.26c.61.41 1.28 1.07 1.69 1.68L271.81 464.07z"></path></svg>
            </template>
          </svg></button>
        </div>
      </div>
      <FormulateInput
          :options="featureStatusOptions"
          type="checkbox"
          label="Filter op status"
          name="featuresStatusFilterSelect"
          v-model="featureStatusFilter"
      />
      <v-list two-line v-if="selectedFeatures.length">
        <template v-for="(feature, index) in selectedFeatures">

          <v-list-item
              :key="feature.value"
          >

            <v-list-item-content>
              <v-list-item-title>
                <v-icon v-bind:style="{ color: featureColor(feature) }">mdi-circle</v-icon> <strong>{{ feature.get('name') }}</strong>
                <v-btn class="ml-2" color="error" depressed small v-on:click="deselectFeature(feature)">Deselecteren</v-btn>
              </v-list-item-title>

              <v-card outlined class="mt-1">

                <table cellpadding="0" cellspacing="0" class="feature-details-table">
                  <tr>
                    <td>Status</td>
                    <td>
                      <template v-if="feature.get('status')">
                        {{ feature.get('status') }}
                      </template>
                      <template v-else>
                        (leeg)
                      </template>
                    </td>
                  </tr>
                  <template v-if="feature.getProperties().data">
                    <tr :key="label" v-for="(value, label) in feature.getProperties().data">
                      <td>{{ label }}</td>
                      <td>{{ value }}</td>
                    </tr>
                  </template>
                </table>
              </v-card>

            </v-list-item-content>

          </v-list-item>

          <div class="mb-4" v-if="index !== selectedFeatures.length - 1" :key="feature.value"></div>

        </template>
      </v-list>

    </div>
  </div>
</template>
<style>
.locate {
  text-align: right;
  top: 0.5em;
  right: 0.5em;
  display:flex;
  flex-flow: row-reverse;
  align-items:flex-end;
}
</style>
<script>
// importing the OpenLayers stylesheet is required for having
// good looking buttons!
import 'ol/ol.css'

import Vue from "vue";
import VueStatic from 'vue-static'
// import axios from "axios";
import View from 'ol/View'
import Map from 'ol/Map'
import Geolocation from 'ol/Geolocation';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Control from 'ol/control/Control';
import GeoJSON from 'ol/format/GeoJSON'
import {fromLonLat} from 'ol/proj';
// import Feature from 'ol/Feature';
import {Circle as CircleStyle, Fill, Stroke, Style, Text,} from 'ol/style';
// import Cluster from 'ol/source/Cluster'
import {Cluster, OSM, Vector as VectorSource} from 'ol/source';
import RegularShape from "ol/style/RegularShape";
import {debounce} from 'lodash';
import featureStyle from "@/mixins/featureStyle";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import {Attribution, defaults as defaultControls} from 'ol/control';
import axios from "axios";


Vue.use(VueStatic);



export default {
  name: "GuestMap",
  mixins: [
    featureStyle
  ],
  data() {
    return {
      selectedFeatures: [], // This hold OpenLayers feature objects
      map_uuid: this.$route.params.map_uuid,
      form_id: this.$route.params.form_id,
      element_name: this.$route.params.element_name,
      form_name: 'Laden..',
      datasource_name: '...',
      featureStatusOptions: [],
      featureStatusFilter: '',
      mapSettings: {
        "featuresMaxSelect":1,
        "mapLayers":[
        ],
        "center":{
          "lon":5.387287657315901,"lat":52.15523010839274
        },
        "zoomlevel":7,
        "featureStyles":[
          {"status":"open","color":"#3399CC"},
          {"status":"closed","color":"#e20000"}
        ],
        "featureActions":[]
      },
      loading: false,
      geolocationCenterOnLocation: false,
      geolocationHasError: false,
    }
  },
  computed: {
    datasourceID() {
      // Get datasource ID
      if (this.mapSettings.mapLayers && this.mapSettings.mapLayers.length > 0) {
        return this.mapSettings.mapLayers[0].datasource_id
      }
      return false
    },
    loadingStrategy() {
      // Get loadingStrategy
      if (this.mapSettings.mapLayers && this.mapSettings.mapLayers.length > 0) {
        return this.mapSettings.mapLayers[0].loadingStrategy
      }
      return 'preload'
    }
  },
  static() {
    return {
      source: null,
      vectorLayer: null,
      sourceCenterPin: null,
      vectorLayerCenterPin: null,
      clusterSource: null,
      clustersLayer: null,
      geolocation: null,
      olView: null,
      olMap: null,
      featureStyleCache: {}, // Feature styles
      clusterStyleCache: {}, // Cluster styles,
      // requestCancelToken: undefined
    };
  },
  watch: {
    'featureStatusFilter': {
      handler() {
          this.recreateMapLayers();
          this.saveFilters();
        }
    },
    'mapSettings.center': {
      deep: true,
      handler(value) {
        this.olView.setCenter(fromLonLat([value.lon, value.lat]))
      }
    },
    'mapSettings.zoomlevel': function(value) {
      this.olView.setZoom(value)
    },
    'mapSettings.featureStyles': function() {
      // Refresh map when mapSettings change
      this.refreshMap()
    },
  },
  created() {
    // Load saved filters
    let savedFilters = localStorage.getItem('savedFilters');
    if (savedFilters) {
      this.featureStatusFilter = JSON.parse(savedFilters);
    }
  },
  mounted() {

    let createTextStyle = function (feature, fontsize, offsetY) {
      let font = fontsize+'px "Roboto", sans-serif'
      let textColor = new Fill({color: '#222'})
      if (feature.get('selected')) {
        font = 'bold '+fontsize+'px "Roboto", sans-serif'
        textColor = new Fill({color: '#1976d2'})
      }

      return new Text({
        text: getText(feature),
        font: font,
        // stroke: new Stroke({color: [0, 0, 0, 1], width: 3}),
        fill: textColor,
        offsetY: offsetY
      })
    };

    let getText = function (feature) {
      return feature.get('name');
    };

    function styleFunction(feature, resolution) {

      let strokeStyle = undefined
      if (feature.get('selected')) {
        strokeStyle = new Stroke({color: '#1976d2', width: 3})
      }

      // Calculate radius
      let radius = 6 / resolution
      if (radius < 5) {
        radius = 5
      }
      else if (radius > 16) {
        radius = 16
      }
      // Get color from status
      let fillColor = $vm.featureStyle($vm.mapSettings, feature)

      let style = {
        image: new CircleStyle({
          radius: radius,
          fill: new Fill({color: fillColor}),
          stroke: strokeStyle,
        }),
      }

      // Show feature text when zoomed in
      if (resolution < 2) {
        // Calculate fontsize
        let fontsize = radius*1.2
        if (fontsize < 10) {
          fontsize = 10
        }
        // Calculate offset
        let offsetY = radius + (fontsize/2) + 3
        // Add feature text
        style.text = createTextStyle(feature, fontsize, offsetY)
      }

      return new Style(style);
    }

    // Make Vue 'this' accessible inside other functions
    let $vm = this;

    // This hold all features
    this.source = new VectorSource();
    // a new vector layer is created with the feature
    this.vectorLayer = new VectorLayer({
      name: 'Features',
      source: this.source,
      minResolution: 0,
      maxResolution: 3,
      style: styleFunction,
    })

    // This hold the center pin when tracking the user his location
    this.sourceCenterPin = new VectorSource();
    // a new vector layer is created with the feature
    this.vectorLayerCenterPin = new VectorLayer({
      name: 'CenterPin',
      source: this.sourceCenterPin,
    })

    // Add cluster layer in between (is only used when the function setFeaturesClusters) is called)
    this.clusterSource = new Cluster({
      distance: 40,
      source: this.source
    });

    this.clustersLayer = new VectorLayer({
      name: 'Cluster',
      source: this.clusterSource,
      minResolution: 3,
      // maxResolution: 20000,
      style: function(feature) {
        let size = feature.get('features').length;
        let style = $vm.clusterStyleCache[size];
        if (!style) {
          style = new Style({
            image: new RegularShape({
              points: 4,
              radius: 20 / Math.SQRT2,
              radius2: 20,
              angle: 0,
              fill: new Fill({
                color: '#41b883'
              })
            }),
            text: new Text({
              text: size.toString(),
              font: '11px "Roboto", sans-serif',
              fill: new Fill({color: '#222'})
            })
          });
          $vm.clusterStyleCache[size] = style;
        }
        return style;
      }
    });


    this.olView = new View({
      zoom: this.mapSettings.zoomlevel,
      center: fromLonLat([this.mapSettings.center.lon, this.mapSettings.center.lat]),
      constrainResolution: true
    })

    const attribution = new Attribution({
      collapsible: true,
      collapsed: true
    });

    // this is where we create the OpenLayers map
    this.olMap = new Map({
      // the map will be created using the 'map-root' ref
      target: this.$refs['map-root'],
      layers: [
        // adding a background tiled layer
        new TileLayer({
          source: new OSM() // tiles are served by OpenStreetMap
        }),
        // the vector layer is added above the tiled OSM layer
        this.clustersLayer,
        this.vectorLayer,
        this.vectorLayerCenterPin,
      ],
      // Set map view
      view: this.olView,
      controls: defaultControls({attribution: false}).extend([attribution]),
    });


    // Fetch map features from API
    this.createMapLayers()

    // Bind event to button
    const locate = document.getElementById('map_locate');
    locate.addEventListener('click', this.getLocation);
    this.olMap.addControl(new Control({
      element: locate,
    }));

    this.olMap.on('pointerdrag', function () {
      // Disable center on location
      $vm.geolocationCenterOnLocation = false
    });

    // On feature click
    this.olMap.on('singleclick', function (e) {
      $vm.olMap.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
        // Only make features clickable on the 'Features' layer
        if (layer.get('name') === 'Features') {
          let selIndex = $vm.selectedFeatures.indexOf(feature);
          if (selIndex < 0) {
              $vm.selectFeature(feature)
          } else {
            $vm.deselectFeature(feature)
          }
        }

      });
    });
  },
  methods: {
    selectFeature(feature) {
      this.deselectFeatures();
      feature.set('selected', true);
      this.selectedFeatures.push(feature)
    },
    deselectFeature(feature) {
      // Find feature in the list of selected features
      let length = this.selectedFeatures.length
      for (let i = 0; i < length; i++) {
        // Check if this is the feature we want to delete
        if (this.selectedFeatures[i].getId() === feature.getId()) {
          this.selectedFeatures[i].set('selected', undefined);
          this.selectedFeatures.splice(i, 1);
          return
        }
      }
    },
    deselectFeatures() {
      let length = this.selectedFeatures.length
      for (let i = 0; i < length; i++) {
        this.selectedFeatures[i].set('selected', undefined);
        this.selectedFeatures.splice(i, 1);
      }
    },
    featureColor(feature) {
      // Get color from status
      return this.featureStyle(this.mapSettings, feature)
    },
    getLocation() {
      // Toggle button
      this.geolocationCenterOnLocation = true
      // Bind geolocation when not set yet.
      if (!this.geoLocation) {
        // Sets up the location
        this.geoLocation = new Geolocation({
          trackingOptions: {
            enableHighAccuracy: true,
          },
          projection: this.olView.getProjection(),
          tracking: true,
        });
      }
      else {
        // Geolocation was already enabled. Go to current location of the map marker.
        this.centerOnLocation()
      }
      this.geoLocation.addEventListener('error', this.errorLocation);
      // If we dont have a position yet we set the event listener, else we'll just set the location
      this.geoLocation.addEventListener('change:position', this.setLocation)
      return this;
    },
    setLocation() {
      let coordinates = this.geoLocation.getPosition();
      if (coordinates) {
        const positionFeature = new Feature();
        positionFeature.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 6,
                fill: new Fill({
                  color: '#3399CC',
                }),
                stroke: new Stroke({
                  color: '#fff',
                  width: 2,
                }),
              }),
            })
        );
        positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
        this.sourceCenterPin.clear() // Remove previous center pins
        this.sourceCenterPin.addFeature(positionFeature); // Add center pin
        // Center camera on the geolocation when flag is true
        if (this.geolocationCenterOnLocation) {
          this.centerOnLocation()
        }

        this.removeEventListeners() // @TODO: Remove this when Rutger wants automatic geolocation again.

      }
    },
    centerOnLocation() {
      let coordinates = this.geoLocation.getPosition();
      if (coordinates) {
        this.olView.setCenter(coordinates);
        this.olView.setZoom(18);
      }
    },
    errorLocation() {
      this.geolocationHasError = "Locatie kon niet worden bepaald. Check of locatie in uw browser voor deze website is toegestaan.";
    },
    refreshMap: debounce(function () {
      // Refresh map (needed when updating styles for example)
      this.source.changed()
    }, 100),
    recreateMapLayers: debounce(function () {
      // Remove map layers
      this.removeMapLayers()
      // Create new map layers
      this.createMapLayers();
    }, 1000),
    saveFilters: debounce(function () {
      // Save filters to local storage
      localStorage.setItem('savedFilters', JSON.stringify(this.featureStatusFilter));
    }, 1000),
    // this will parse the input data and add it to the map
    addFeaturesToSource(geojson) {
      const source = this.source
      // Parse features from GeoJSON
      const features = new GeoJSON({
        featureProjection: 'EPSG:3857',
      }).readFeatures(geojson)
      // Add new features
      source.addFeatures(features);
    },
    removeMapLayers() {
      // Remove features from layer
      this.source.clear();
    },
    setFilterOptions(options) {
      let newOptions = [];
      let statusFilter = [];
      options.forEach(function(option) {
        if (option.length > 0) {
          statusFilter.push(option);
          newOptions.push(option);
        }
        else {
          newOptions.push({value: '_none', label: 'Geen status'});
        }
      })
      this.featureStatusOptions = newOptions;
    },
    async createMapLayers() {
      let mapUrl = '';

      let status_filter = 'all';
      if (this.featureStatusFilter && this.featureStatusFilter.length >= 1) {
        status_filter = this.featureStatusFilter;
      }
      if (this.map_uuid) {
        mapUrl = 'api/guest/datasource/'+this.map_uuid+'/'+status_filter;
      }
      else if (this.form_id && this.element_name) {
        mapUrl = 'api/guest/map/'+this.form_id+'/'+this.element_name+'/'+status_filter;
      }

      this.deselectFeatures();

      this.loading = true
      // Fetch results form the API
      axios
          .get(mapUrl)
          .then(response => {
            this.form_name = response.data.form_name
            this.datasource_name = response.data.datasource_name
            if (response.data.status_values) {
              this.setFilterOptions(response.data.status_values);
            }
            if (response.data.mapSettings) {
              this.mapSettings = response.data.mapSettings;
            }

            if (this.featureStatusFilter && this.featureStatusFilter.length >= 1) {
              // Parse GeoJSON and replace source
              this.addFeaturesToSource(response.data.features)
            }
          })
          .catch(error => {
            if (error && error.response && error.response.status === 404) {
              return this.$router.push({ name: '404'});
            }

            console.log(error.response);
          })
          .finally(() =>
              this.loading = false
          )
    },
    removeEventListeners() {
      this.geoLocation.removeEventListener('error', this.errorLocation);
      this.geoLocation.removeEventListener('change:position', this.setLocation);
    }

  },
}
</script>

<style scoped>

</style>