<template>
  <div>

    <v-icon dense>mdi-{{ elementTypeIcons[token.elementType] }}</v-icon>
    {{ token.label }}
    <v-chip
        small
        class="background-color"
        v-if="token.token"
        v-clipboard:copy="'$'+token.token+'$'"
        v-clipboard:success="onCopySuccess"
        v-clipboard:error="onCopyError"
    >
      <template v-pre>$</template>{{ token.token }}<template v-pre>$</template>
      <v-icon class="ml-1" small>mdi-clipboard-multiple-outline</v-icon>
    </v-chip>

    <span class="d-inline-block ml-2" v-if="token.description">{{ token.description }}</span>

  </div>
</template>
<script>
import eformHelpers from "@/mixins/eformHelpers";

export default {
  name: 'EformToken',
  mixins: [
    eformHelpers
  ],
  props: {
    token: Object,
  },
  methods: {
    onCopySuccess() {
      let $vm = this;
      $vm.$toast.success("Gekopieerd");
    },
    onCopyError() {
      let $vm = this;
      $vm.$toast.error("Kon niet kopieren.");
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
