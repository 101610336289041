<template>
  <div>

    <v-row>
      <v-col>
        <v-select
            v-model="chartYearSelect"
            :items="chartYearOptions"
            outlined
            label="Jaar"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
            v-model="chartMonthSelect"
            :items="chartMonthOptions"
            outlined
            clearable
            label="Maand"
            style="z-index: 12;"
        ></v-select>
      </v-col>
    </v-row>

    <h2>
      Credit verbruik
      <template v-if="chartMonthSelect !== null">{{ chartMonthOptions[chartMonthSelect].text }}</template>
      {{ chartYearSelect }}
    </h2>
    Totaal {{ totalCredits }}
    <apexchart v-if="series" type="bar" height="300" :options="chartOptions" :series="series"></apexchart>

  </div>
</template>
<script>
import axios from "axios";
import VueApexCharts from 'vue-apexcharts';
import {isEmpty} from "lodash";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    client_id: null,
  },
  data() {
    return {
      chartMonthOptions: [
        {value: 0, text: 'Januari'},
        {value: 1, text: 'Februari'},
        {value: 2, text: 'Maart'},
        {value: 3, text: 'April'},
        {value: 4, text: 'Mei'},
        {value: 5, text: 'Juni'},
        {value: 6, text: 'Juli'},
        {value: 7, text: 'Augustus'},
        {value: 8, text: 'September'},
        {value: 9, text: 'Oktober'},
        {value: 10, text: 'November'},
        {value: 11, text: 'December'},
      ],
      chartYearSelect: new Date().getFullYear(),
      chartMonthSelect: new Date().getMonth(),
      chartOptions: {
        colors:[this.$vuetify.theme.currentTheme.primary],
        plotOptions: {
          bar: {
            columnWidth: '50%',
          }
        },
        chart: {
          defaultLocale: 'nl',
          locales: [{
            name: 'nl',
            options: {
              months: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
              shortMonths: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
              days: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
              shortDays: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
              toolbar: {
                download: 'Download SVG',
                selection: 'Selectie',
                selectionZoom: 'Selectie zoom',
                zoomIn: 'Inzoomen',
                zoomOut: 'Uitzoomen',
                pan: 'Pannen',
                reset: 'Reset zoom',
              }
            }
          }],
          toolbar: {
            tools: {
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            export: {
              csv: {
                headerCategory: 'Categorie',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toLocaleDateString()
                }
              },
            },
            autoSelected: 'pan'
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          type: "datetime",
          // min: null,
          // max: null,
        }
      },
      series: [],
    }
  },
  computed: {
    chartYearOptions() {
      let max = new Date().getFullYear()
      let min = max - 9
      let years = []

      for (let i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },
    totalCredits() {
      let totalCredits = 0;

      if (!isEmpty(this.series) && !isEmpty(this.series[0].data)) {
        for (let day of this.series[0].data) {
          totalCredits += day.y;
        }
      }

      return totalCredits;
    },
  },
  mounted () {
    // Get chart data
    this.getChartCreditData();
  },
  watch: {
    chartYearSelect() {
      this.getChartCreditData()
    },
    chartMonthSelect() {
      this.getChartCreditData()
    }
  },
  methods: {
    xAxisMinDateYear(year) {
      let startOfYear = new Date(year, 0, 1);
      return startOfYear.getTime()
    },
    xAxisMaxDateYear(year) {
      let endOfYear = new Date(year, 11, 31);
      return endOfYear.getTime()
    },
    xAxisMinDateYearMonth(year, month) {
      let startOfYearMonth = new Date(year, month, 1);
      return startOfYearMonth.getTime()
    },
    xAxisMaxDateYearMonth(year, month) {
      let endOfYearMonth = new Date(year, month, 31);
      return endOfYearMonth.getTime()
    },
    getChartCreditData () {
      // Set date filter
      let params = {}
      params.year = this.chartYearSelect
      if (this.chartMonthSelect !== null) {
        params.month = this.chartMonthSelect + 1
      }
      const client_ids = this.client_id ?? 'all';
      // Fetch results from the API
      axios
          .get('api/clients/'+client_ids+'/chart-credit-usages', { params: params })
          .then(response => {

            // // Set xAxis min/max
            // if (this.chartYearSelect && this.chartMonthSelect) {
            //   // Update the chartOptions and make sure watchers are triggered
            //   this.chartOptions = {...this.chartOptions, ...{
            //       xaxis: {
            //         min: this.xAxisMinDateYearMonth(this.chartYearSelect, this.chartMonthSelect),
            //         max: this.xAxisMaxDateYearMonth(this.chartYearSelect, this.chartMonthSelect)
            //       }
            //     }}
            // }
            // else if (this.chartYearSelect) {
            //   // Update the chartOptions and make sure watchers are triggered
            //   this.chartOptions = {...this.chartOptions, ...{
            //       xaxis: {
            //         min: this.xAxisMinDateYear(this.chartYearSelect),
            //         max: this.xAxisMaxDateYear(this.chartYearSelect)
            //       }
            //     }}
            // }

            // Set chart data
            this.series = [{
              name: "Verbruikte credits",
              data: response.data
            }]

          })
          .catch(error => {
            console.log(error)
          })
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
