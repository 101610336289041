import { render, staticRenderFns } from "./ClientListAssigned.vue?vue&type=template&id=4875b716&scoped=true&"
import script from "./ClientListAssigned.vue?vue&type=script&lang=js&"
export * from "./ClientListAssigned.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4875b716",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable})
