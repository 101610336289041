<template>
  <v-sheet class="pa-4 rounded-lg">

    <v-row v-if="isLoading">
      <v-col cols="8">
        <v-sheet class="pa-4 rounded-lg">
          <v-skeleton-loader type="sentences, text@3, image"></v-skeleton-loader>
        </v-sheet>
      </v-col>
    </v-row>

    <v-card-text v-if="!isLoading">

      <v-alert
          type="error"
          v-bind:key="key"
          v-for="(errorMessage, key) in errorMessages"
          dismissible
      >
        {{ errorMessage }}
      </v-alert>

      <FormulateForm @submit="submitForm">
        <FormulateInput
            type="email"
            label="E-mailadres"
            v-model="email"
            validation="required|email"
        />

        <client-user-input-roles :roles="roles" :user_roles="user_roles" @roles-changed="updateRoles"></client-user-input-roles>

        <v-checkbox hide-details class="mb-0" v-model="requireTermsAndConditions" v-if="userHasRole('admin')">
          <template v-slot:label>
            <div>
              Account moet algemene voorwaarden accepteren.
            </div>
          </template>
        </v-checkbox>

        <FormulateInput
            input-class="v-btn v-size--default success"
            type="submit"
            name="submit"
            :disabled="isSaving"
            :label="isSaving ? 'Laden...' : 'Gebruiker toevoegen aan klant'"
            class="mt-5"
        />
      </FormulateForm>

    </v-card-text>
  </v-sheet>
</template>

<script>
import axios from "@/plugins/axios";
import router from "@/plugins/routes";
import userEntityHelpers from "@/mixins/userEntityHelpers";
import ClientUserInputRoles from "@/views/clients/components/ClientUserInputRoles";

export default {
  components: {
    ClientUserInputRoles,
  },
  mixins: [
      userEntityHelpers
  ],
  props: {
    client_id: null,
  },
  data () {
    return {
      errorMessages: [],
      isLoading: true,
      isSaving: false,
      email: '',
      roles: [],
      requireTermsAndConditions: false,
    }
  },
  async mounted () {
    // Load user roles
    await this.getUserRoles()
    this.isLoading = false
  },
  methods: {
    updateRoles(rolesSelected) {
      // Set new roles
      this.roles = rolesSelected
    },
    // Send the form to the API endpoint
    async submitForm() {

      this.isSaving = true;

      let payload = {
        roles: this.roles,
        email: this.email,
        require_terms: this.requireTermsAndConditions,
      }

      axios.post('api/clients/'+this.client_id+'/add-user', payload)
          .then((response) => {
            this.isSaving = false
            if (response.status === 201) {
              this.$toast.success(response.data);
            }
            else {
              this.$toast.error(response.data);
            }
            router.push({ name: 'client_users', params: {client_id: this.client_id} })
          })
          .catch(error => {
            if (error.response && error.response.data) {
              // Add error messages
              this.errorMessages.push(error.response.data);
            }
            this.errored = true
          })
          .finally(() => this.isSaving = false)
    }
  }
}
</script>

<style scoped>

</style>